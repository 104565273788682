import { Component, Input, OnInit } from '@angular/core';
// import { Geolocation } from '@capacitor/geolocation';
// import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { GetPlatformService } from 'src/app/_services/get-platform.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
declare var google;
declare var navigator;
@Component({
	selector: 'app-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit {

	@Input() currency: any = '$';
	@Input() orderSubTotal: any = 0;
	@Input() orderShipping: any = 0;
	@Input() orderTotal: any = 0;
	@Input() orderDiscount: any = 0;
	@Input() orderVAT: any = 0;
	@Input() vatPercent: any = 0;
	@Input() fromList?: any;
	@Input() supplierIndex?: any;
	@Input() couponCode?: any;
	@Input() orderSummeryData?: any;


	constructor(public getPlatformService: GetPlatformService,
		private nativeGeocoder: NativeGeocoder,
		private modalService: ModalService,
		public commonService: CommonService) {
	}

	ngOnInit() {

	}


}
