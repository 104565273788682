import { Component, OnInit, Input, Inject } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../_services/common.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidator } from '../../_common/customvalidators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-terms-conditions',
	templateUrl: './terms-conditions.component.html',
	styleUrls: ['./terms-conditions.component.scss'],
})
export class termsConditionsComponent implements OnInit {

	@Input() isModel;
	isAgree = false;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		public commonService: CommonService,
		private modalService: ModalService,
		private modalCtrl: ModalController,
		@Inject(DOCUMENT) private document: Document,
	) { }

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
	}

	async onAgree() {
		await this.modalCtrl.dismiss({data: true});
	}

	async close() {
        await this.modalCtrl.dismiss({data: false});
    }

}
