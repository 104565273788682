import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputScrollDisable]',
})
export class PreventNumberInputScrollDirective {
  constructor() {}

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    // Prevent the default scroll behavior when the input field is focused
    event.preventDefault();
  }
}
