import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class EditorService {
	tinyConfig: any = {
		selector: '#tinyeditor',
		height: 400,
		menubar: true,
		// language: 'en',
		directionality: 'ltr',
		plugins: ["link",
			"lists",
			"table",
			"code",],
		toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat'
	};
	tinyConfigAr: any = {
		selector: '#tinyeditorAr',
		height: 400,
		menubar: true,
		// language: 'ar',
		directionality: 'rtl',
		plugins: ["link",
			"lists",
			"table",
			"code"],
		toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat'
	}
	constructor() { }
}