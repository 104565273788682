import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidator } from '../../_common/customvalidators';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { CommonService } from '../../_services/common.service';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { ToastService } from '../../_services/toast.service';
import { filter } from 'rxjs/operators'

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
	@ViewChild('emailInput', { static: false }) emailInput!: IonInput;

	public subscription = new Subscription();
	isLoading = false;

	public desktopView: boolean = false;
	message: string = "";
	approvalText: string = "";

	public validation_messages = {
		email: [
			{ type: 'required', message: 'ForgotPasswordComponent.emailRequired' },
			{ type: 'invalidEmailPhone', message: 'ForgotPasswordComponent.invalidEmail' }
		]
	}

	forgotPassFromGroup: FormGroup;

	constructor(
		private platform: Platform,
		private formBuilder: FormBuilder,
		private router: Router,
		public apiService: ApiService,
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
	) {
		this.desktopView = this.platform.is('desktop');
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((route: NavigationEnd) => {
			if (route.url == '/forgot-password') {
				setTimeout(() => {
					if (this.emailInput) {
						this.emailInput.setFocus();
					}
				}, 600);
			}
		})
	}

	ngOnInit() {
		this.forgotPassFromGroup = this.initializeforgotPassFromGroup();
		setTimeout(() => {
			if (this.emailInput) {
				this.emailInput.setFocus();
			}
		}, 600);
	}

	send() {
		for (const i in this.forgotPassFromGroup.controls) {
			this.forgotPassFromGroup.controls[i].markAsDirty();
			this.forgotPassFromGroup.controls[i].updateValueAndValidity();
			this.forgotPassFromGroup.controls[i].markAsTouched();
		}
		if (this.forgotPassFromGroup.invalid) {
			return
		}

		this.generateOtp();
	}

	generateOtp() {
		const params = {
			"user_name": this.forgotPassFromGroup.controls.email.value,
			"purpose_type": "FORGOT_PASSWORD",
		}

		const navigationExtras: NavigationExtras = {
			queryParams: {
				from: 'forgot_password',
			},
		};
		this.isLoading = true;
		this.subscription.add(
			this.dataService.post(URLS.generateOtp, params).subscribe(data => {
				if (data['code'] == 200) {
					if (this.forgotPassFromGroup.controls.email.value) {
						localStorage.setItem('user_name', this.forgotPassFromGroup.controls.email.value);
					}
					this.forgotPassFromGroup.reset();
					this.router.navigate(['/two-factor-authentication'], navigationExtras);
					if (data['data']) {
						if (data['data']['uuid']) {
							localStorage.setItem('uuid', data['data']['uuid']);
						}
						if (data['data']['mobile_no']) {
							localStorage.setItem('mobile_no', data['data']['mobile_no']);
						}
					}
					// this.toastService.displayToast(data['message'], null, 'success');
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	//move to relevant page
	goToPage(page) {
		this.router.navigate([page]);
	}

	initializeforgotPassFromGroup() {
		return this.formBuilder.group({
			email: new FormControl(null, {
				validators: Validators.compose([Validators.required, CustomValidator.emailPhoneValidator]),
			})
		})
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}
}
