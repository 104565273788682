import { ApiService } from './../../_services/api-data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../_services/common.service';
import { URLS } from '../../_config/api.config';
import { ToastService } from '../../_services/toast.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { DOCUMENT, DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

@Component({
    selector: 'app-coupon-details',
    templateUrl: './coupon-details.component.html',
    styleUrls: ['./coupon-details.component.scss'],
})
export class CouponDetailsComponent implements OnInit {
    dataId: any;
    public couponForm: FormGroup;
    public subscription = new Subscription();
    reviewDetailsData: any[] = [];
    isLoading: boolean = false;
    showLoader: boolean = true;
    isDesktop: boolean = false;
	datePipe = new DatePipe("en-US");
    totalProductList = 0 ;
    pageno = 1;
    pagesize = 10;
    filterparams: any = {};
	public itemsPerPage = 5;
	public currentPage = 1;
    productList = [ ];

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private dataService: ApiService,
        private toastService: ToastService,
        private platform: Platform,
        private formBuilder: FormBuilder,
        public commonService: CommonService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.dataId = this.activeRoute.snapshot.paramMap.get('id');
        this.isDesktop = this.platform.is('desktop')
    }

    ngOnInit(): void {
        const element = this.document.querySelector('#scrollId');
        if (element) {
            element.scrollIntoView();
        }
        this.couponForm = this.initializeForm();
        this.filterparams = {
            page: this.pageno,
            size: this.pagesize
        }
        this.getCouponDetail();
    }

    getCouponDetail(){
        this.showLoader = true;
        this.productList = [];
        let params = {};
         if (this.dataId) {
            params['coupon_id'] = this.dataId;
        }

        this.subscription.add(
            this.dataService.get(URLS.couponDetail, params).subscribe(data => {
                this.showLoader = false;
                if (data['code'] == 200) {
                    if (data['data']) {
                         let couponDetail = {
                            coupon_name: this.commonService.domDirectionLTR ? data['data'].coupon_title_en : data['data'].coupon_title_ar,
                            description: this.commonService.domDirectionLTR ? data['data'].coupon_description_en : data['data'].coupon_description_ar,
                            coupon_code: data['data']['coupon_code'],
                            discounted_amount: data['data']['coupon_value'],
                            discount_start_date:  this.datePipe.transform(data['data']['start_date'], 'YYYY-MM-dd'),
                            discount_end_date: this.datePipe.transform(data['data']['end_date'], 'YYYY-MM-dd'),
                            set_min_cart_value: data['data']['minimum_value'],
                            set_max_cart_value: data['data']['maximum_discount_value'],
                            no_of_attempts: data['data']['no_of_attempts'],
                            coupon_reference_type: data['data']['coupon_reference_type'],
                        }
                         this.couponForm = this.initializeForm(couponDetail);
                        this.productList = data['data']['products'];
                    }
                    const element = this.document.querySelector('#scrollId');
                    if (element) {
                        element.scrollIntoView();
                    }
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.showLoader = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }


    initializeForm(data?) {
        return this.formBuilder.group({
            coupon_name: new FormControl(
                data && data.coupon_name ? data.coupon_name : null, {
                validators: Validators.compose([]),
            }),
            description: new FormControl(  data && data.description ? data.description : null, {
                validators: Validators.compose([]),
            }),
            coupon_code: new FormControl(  data && data.coupon_code ? data.coupon_code : null, {
                validators: Validators.compose([]),
            }),
            discounted_amount: new FormControl(  data && data.discounted_amount ? data.discounted_amount : null,{
                validators: Validators.compose([]),
            }),
            discount_start_date: new FormControl(  data && data.discount_start_date ? data.discount_start_date : null,{
                validators: Validators.compose([]),
            }),
            discount_end_date: new FormControl(  data && data.discount_end_date ? data.discount_end_date : null,{
                validators: Validators.compose([]),
            }),
            set_min_cart_value: new FormControl(  data && data.set_min_cart_value ? data.set_min_cart_value : null,{
                validators: Validators.compose([]),
            }),
            set_max_cart_value: new FormControl(  data && data.set_max_cart_value ? data.set_max_cart_value : null,{
                validators: Validators.compose([]),
            }),
            no_of_attempts: new FormControl(  data && data.no_of_attempts ? data.no_of_attempts : null,{
                validators: Validators.compose([]),
            }),
            choose_region: new FormControl(  data && data.choose_region ? data.choose_region : null,{
                validators: Validators.compose([]),
            }),
            coupon_reference_type: new FormControl(  data && data.coupon_reference_type ? data.coupon_reference_type : null,{
                validators: Validators.compose([]),
            }),
        });
    }

    back() {
        this.router.navigate(['account-details/coupon-management']);
    }

    key: string = "id";
    sortType: string = "ASC";
    onSort(event, key: any) {
        this.key = key;

        const element = document.querySelectorAll('.sorting-icon');
        element.forEach(img => {
            img.setAttribute("src", "assets/icon/sort.svg")
        });

        let eventValue;
        if (event.target.className.includes("column-heading")) {
            eventValue = event.target.children[0];
        } else {
            eventValue = event.srcElement;
        }
        let order = eventValue.getAttribute("data-order");
          if (this.filterparams['sort_by'] != key) {
            order = "asc"
          }
          if (order === "asc") {
            this.sortType = "ASC";
            eventValue.setAttribute("data-order", "desc");
            eventValue.src = "assets/icon/sorting_asc.svg";
          } else if (order === "desc") {
            this.sortType = "DESC";
            eventValue.setAttribute("data-order", "");
            eventValue.src = "assets/icon/sorting_desc.svg";
          } else {
            delete this.filterparams['sort_by'];
            delete this.filterparams['sort_type'];
            eventValue.setAttribute("data-order", "asc");
            eventValue.src = "assets/icon/sort.svg";

          }
          if (order) {
            this.filterparams['sort_by'] = key;
            this.filterparams['sort_type'] = this.sortType;
          }
          // Required code
        // this.getProductsList(this.filterparams, false)
    }

    onChange(event): void {
		this.currentPage = event;
		this.pageno = event;

	}
    ionViewWillLeave() {
        this.subscription.unsubscribe();
    }

}
