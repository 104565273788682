import { DOCUMENT, Location } from "@angular/common";
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IonButton, ModalController } from '@ionic/angular';
import { CodeInputComponent } from 'angular-code-input';
import { Subscription, interval } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';
import { ApprovalWaitingComponent } from '../../components/approval-waiting/approval-waiting.component';

@Component({
    selector: 'app-2fa',
    templateUrl: './2fa.component.html',
    styleUrls: ['./2fa.component.scss'],
})
export class TwoFAComponent implements OnInit {
    @ViewChild('codeInput') codeInput !: CodeInputComponent;
    @ViewChild(IonButton) button;

    public resendTimer: any = null;
    public isCodeHidden = true;
    public otpInput = null;
    public redirectPage: string = '';
    public subscription = new Subscription();
    public subscribeOtp = new Subscription();
    otpCount = 0;
    registrationDetails: any;
    userName: any;
    @Input() fromPage: string;

    isModalOpen = false
    modal: any

    disableBtn = true;
    isLoading = false;
    uuid: string;
    isGettingOtp = false;
    mobileNo: any;
    mobileNoHint: any;

    constructor(
        private router: Router,
        public commonService: CommonService,
        private dataService: ApiService,
        private location: Location,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private modalCtrl: ModalController,
        private authService: AuthenticationService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((route: NavigationEnd) => {
            setTimeout(() => {
                this.codeInput.focusOnField(0);
            }, 600);
        })
    }

    ngOnInit() {
        this.startTimer();
        const element = this.document.querySelector('#scrollId');
        if (element) {
            element.scrollIntoView();
        }
        this.getFromPage();
        const decryptedData = localStorage.getItem("registration_data");
        this.registrationDetails = this.commonService.decryptData(decryptedData);
        this.registrationDetails = JSON.parse(this.registrationDetails);
        this.uuid = localStorage.getItem("uuid");
        this.userName = localStorage.getItem("user_name");
        this.mobileNoHint = localStorage.getItem("mobile_no");
        let number: any = "******" + this.mobileNoHint.substr(this.mobileNoHint.length - 3);
        this.mobileNoHint = number;
        this.subscription = new Subscription();
        this.subscribeOtp = new Subscription();
        setTimeout(() => {
            if(this.codeInput){
                this.codeInput.reset();
            }
            this.codeInput.focusOnField(0);
        }, 1000);
    }

    getFromPage() {
        this.route.queryParams.subscribe(params => {
            if (params && params.from) {
                this.fromPage = params.from;
            }
        });
    }
    ionViewWillEnter() {
        this.getFromPage();
        this.subscription = new Subscription();
        this.subscribeOtp = new Subscription();
    }

    cancel() {
        localStorage.clear();
        this.router.navigate(['/login'], { replaceUrl: true, }).then(() => {
            window.location.reload();
        });
    }

    getOtp() {
        this.otpInput = null;
        if(this.codeInput){
            this.codeInput.reset();
        }
        if (this.isGettingOtp) {
            return;
        }
        let userInfo = this.commonService.decryptData(localStorage.getItem('userInfo'));
        userInfo = JSON.parse(userInfo);
        let params = {
            uuid: this.uuid
        }
        this.mobileNo = localStorage.getItem('mobile_no');

        if (this.fromPage == "login") {
            params["mobile_no"] = userInfo['user_name'];
            params["purpose_type"] = "2FA";

        } else if (this.fromPage == "forgot_password") {
            params["mobile_no"] = this.mobileNo;
            params["purpose_type"] = "FORGOT_PASSWORD";
        } else {
            params["mobile_no"] = this.registrationDetails['supplier_mobile'];
            params["purpose_type"] = "REGISTRATION";
        }

        this.isGettingOtp = true;
        this.subscription.add(
            this.dataService.post(URLS.resendOtp, params).subscribe(data => {
                if (data['code'] == 200) {
                    this.startTimer();
                    this.otpCount += 1;
                    if (data['data']) {
                        if (data['data']['uuid']) {
                            localStorage.setItem('uuid', data['data']['uuid']);
                            this.uuid = data['data']['uuid'];
                        }
                    }
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                    if (this.fromPage == "registration") {
                        this.router.navigate(['/registration'], { replaceUrl: true, });
                    } else {
                        this.router.navigate(['/login'], { replaceUrl: true, });
                    }
                }
                this.isGettingOtp = false;
            }, error => {
                this.isGettingOtp = false;
                this.toastService.displayToast(error['message'], null, 'danger');
                if (this.fromPage == "registration") {
                    this.router.navigate(['/registration'], { replaceUrl: true, });
                } else {
                    this.router.navigate(['/login'], { replaceUrl: true, })
                }
            })
        )
    }

    enterOtpSubmit(event) {
        setTimeout(() => {
            if (event.keyCode === 13 && this.otpInput.length == 5) {
                this.verifyOtp();
            }
        }, 1000);
    }

    onCodeCompleted(event) {
        if (event) {
            this.otpInput = event;
        }
        if (this.otpInput.length == 5) {
            setTimeout(() => {
                this.button.el.focus();
            }, 600);
        }
    }

    onOtpChanged(event) {
        if (event) {
            this.otpInput = event;
        }
        if (event.length < 5) {
            this.disableBtn = true;
        } else {
            this.disableBtn = false;
        }
    }

    public resendOtpClick() {
        if (this.resendTimer == null) {
            this.subscribeOtp.unsubscribe();
            this.resendTimer = "00:30";
            this.isCodeHidden = true;
            this.otpInput = null;
            if(this.codeInput){
                this.codeInput.reset();
            }
        }
    }

    public startTimer() {
        this.subscribeOtp = new Subscription();
        this.subscribeOtp = interval(1000).pipe(take(31)).subscribe(res => {
            this.resendTimer = this.transform(30 - res);
            if (res >= 30) {
                this.resendTimer = null;
                this.subscribeOtp.unsubscribe();
            }
        });
    }

    transform(value: number): string {
        const minutes: number = Math.floor(value / 60);
        return (minutes < 10 ? '0' + minutes : minutes) + ':' + ((value - minutes * 60) < 10 ? '0' + (value - minutes * 60) : (value - minutes * 60))
    }

    verifyOtp() {
        let userInfo = this.commonService.decryptData(localStorage.getItem('userInfo'));
        userInfo = JSON.parse(userInfo);
        let mobileNo = localStorage.getItem('mobile_no');

        if (this.otpInput && this.otpInput.length == 5) {
            let params = {
                "uuid": this.uuid,
                "otp": +this.otpInput
            }

            const encrypted2faData = this.commonService.encryptData(JSON.stringify(params));
            localStorage.setItem('twoFaData', encrypted2faData)

            if (this.fromPage == "login") {
                params["mobile_no"] = mobileNo;
                params["purpose_type"] = "2FA";
            }
            else if (this.fromPage == "registration") {
                params["mobile_no"] = this.registrationDetails['supplier_mobile'];
                params["purpose_type"] = "REGISTRATION";
            }
            else if (this.fromPage == "forgot_password") {
                params["mobile_no"] = mobileNo;
                params["purpose_type"] = "FORGOT_PASSWORD";
            }
            else {
                params["mobile_no"] = mobileNo;
                params["purpose_type"] = "2FA";
            }
            this.disableBtn = true;
            this.isLoading = true;
            this.subscription.add(
                this.dataService.post(URLS.verifyOtp, params).subscribe(data => {
                    if (data['code'] == 200 && data['data']) {
                        this.disableBtn = false;
                        this.isLoading = false;
                        this.otpInput = null;
                        if(this.codeInput){
                            this.codeInput.reset();
                        }
                        localStorage.setItem('OTP_Verified', 'true');

                        if (this.fromPage == "login") {
                            if (data['data']['accessToken']) {
                                this.commonService.accessToken = data['data']['accessToken'];
                                localStorage.setItem('accessToken', data['data']['accessToken'])
                            }
                            this.checkApproveStatus();
                        } else if (this.fromPage == "forgot_password") {
                            this.router.navigate(['/reset-password'], { replaceUrl: true, });
                        } else {
                            if (data['data']) {
                                this.onRegistraion();
                            }
                        }
                    } else {
                        this.otpInput = null;
                        if(this.codeInput){
                            this.codeInput.reset();
                        }
                        this.toastService.displayToast(data['message'], null, 'danger');
                        this.disableBtn = true;
                        this.isLoading = false;
                        setTimeout(() => {
                            this.codeInput.focusOnField(0);
                        }, 6000);
                        setTimeout(() => {
                            this.codeInput.focusOnField(0);
                        }, 1000);
                    }
                }, error => {
                    this.otpInput = null;
                    if(this.codeInput){
                        this.codeInput.reset();
                    }
                    this.disableBtn = true;
                    this.isLoading = false;
                    setTimeout(() => {
                        this.codeInput.focusOnField(0);
                    }, 6000);
                    setTimeout(() => {
                        this.codeInput.focusOnField(0);
                    }, 1000);
                    this.toastService.displayToast(error['message'], null, 'danger');
                })
            )
        }
    }

    onRegistraion() {

        const params = {
            "supplier_name_en": this.registrationDetails["supplier_name_en"],
            "supplier_name_ar": this.registrationDetails["supplier_name_ar"],
            "supplier_email": this.registrationDetails["supplier_email"],
            "supplier_mobile": this.registrationDetails["supplier_mobile"],
            "password": this.registrationDetails["password"],
        }

        this.isLoading = true;
        this.subscription.add(this.dataService.post(URLS.registration, params).subscribe(
            (response) => {
                if (response['code'] === 200) {
                    this.isLoading = false;
                    this.commonService.accessToken = response['data']['accessToken'];
                    localStorage.removeItem('registration_data');
                    localStorage.setItem('isApproved', 'false');
                    this.router.navigate(['/company-details'], { replaceUrl: true, });
                    this.toastService.displayToast(response['message'], null, 'success');
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(response['message'], null, 'danger');
                }
            }, (error) => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            }));
    }

    checkApproveStatus() {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken || this.commonService.accessToken) {
            let companyData = localStorage.getItem('companyData');
            if (companyData == 'true') {
                let isApproved = localStorage.getItem('isApproved');
                if (isApproved == 'false' && !this.isModalOpen) {
                    this.modalCtrl.dismiss(this.modal)
                    this.router.navigate(['/account-details/marketplace-dashboard'], { replaceUrl: true, })
                    localStorage.setItem('accessToken', this.commonService.accessToken)
                    this.approvalCheck();
                } else {
                    this.commonService.getProfileData();
                    localStorage.setItem('accessToken', this.commonService.accessToken)
                    this.router.navigate(['/account-details/marketplace-dashboard'], { replaceUrl: true, })
                }
            } else {
                this.router.navigate(['/company-details'], { replaceUrl: true, })
            }
        }
    }

    approvalCheck() {
        let obj = {
            component: ApprovalWaitingComponent,
            backdropDismiss: false,
            canDismiss: true,
            cssClass: 'approval-modal'
        };
        this.modal = this.modalService.openModal(obj);
        this.isModalOpen = true;
        this.modal.then(value => {
            this.isModalOpen = false;
        }, error => {
            console.log(error);
        });
    }

    logout() {
        this.authService.logout().subscribe(data => {
            localStorage.clear();
            this.router.navigate(['/login'], { replaceUrl: true, }).then(() => {
                window.location.reload();
            });
        }, error => {
            localStorage.clear();
            this.router.navigate(['/login'], { replaceUrl: true, }).then(() => {
                window.location.reload();
            });
        });
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe();
    }
}
