import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import * as _ from 'lodash';
import 'lodash.product';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../_common/customvalidators';
import { URLS } from '../_config/api.config';
import { ApiService } from './api-data.service';
import { CommonService } from './common.service';
import { ToastService } from './toast.service';

@Injectable({
	providedIn: 'root',
})
export class ProductService {
	//Step 1
	subclassList = []; // Tree structure dropdown list array - Sub class
	regionList = []; // Region dropdown list array - Regions
	manufacturingCountryList = []; // Manufacturing country dropdown list array - Country of manufacturing
	stockAvailablityList = []; // Stock availablity dropdown list array - Stock availablity
	brandList = []; // Brand dropdown list array - Brand
	unitsList = []; // Units dropdown list array - Product unit & Secondary Unit & Sample product unit
	subclassOnlyList = []; // Array of all the subclasses object
	selectedAttributeList: any[] = []; // List of attributes of the selected Sub class
	public subscription = new Subscription();
	public productForm: FormGroup; // Add/Edit product step 1 Product form
	selectedSubclass: any; // Currently selected subclass full json obj
	disableFields: boolean = false; // Disable fields that are not editable;
	missingData = ''; // list on missing data;
	documentTypeList = []; // List of document type user
	addedDocumentList = []; //Added document list
	warehouseList = []; // Warehouse list
	selectedWarehouseList = []; //Selected warehouses  at step 1
	//Display All custom options
	showCustomOptions = false;
	// Display custom options based on the below flags
	//--------------------------
	showSinglePrice = false;
	showCommonImage = false;
	showWarehouse = false;
	showSampleProduct = false;
	showCommonQuantity = false;
	showCommonWeight = false;
	showSameName = false;
	withVat = true;
	bothCharged = false;
	shipmentCharged = false;
	//--------------------------

	//API loading flag
	isAddingProduct = false;

	//Base mobile& web image file array & image preview array
	baseWebImageFile = [];
	baseWebImagePreview = [];
	baseMobileImageFile = [];
	baseMobileImagePreview = [];

	//Common mobile& web image file array & image preview array
	webPreviewImages = [];
	webImagesFile = [];
	mobileImagesFile = [];
	mobilePreviewImages = [];
	savedWebImages = [];
	savedMobileImages = [];

	//Response of step 1 save button
	savedProduct: any;

	loaderConfig: any = {
		showBackdrop: false,
		translucent: true,
		backdropDismiss: false,
		message: 'Loading',
	};
	//Step 1 validation message
	public validation_messages = {
		productName: [{ type: 'required', message: 'productDetail.errors.name' }],
		productNameArabic: [
			{ type: 'required', message: 'productDetail.errors.nameAr' },
			{ type: 'invalidName', message: 'productDetail.errors.invalidNameAr' },
		],
		productDesc: [
			{ type: 'required', message: 'productDetail.errors.description' },
		],
		productDescArabic: [
			{ type: 'required', message: 'productDetail.errors.descriptionAr' },
			{
				type: 'invalidName',
				message: 'productDetail.errors.invalidDescriptionAr',
			},
		],
		subclass: [{ type: 'required', message: 'productDetail.errors.subclass' }],
		warehouseList: [
			{ type: 'required', message: 'productDetail.errors.warehouseList' },
		],
		sku: [{ type: 'required', message: 'productDetail.errors.sku' }],
		quantity: [
			{ type: 'required', message: 'productDetail.errors.quantity' },
			{ type: 'invalidNumber', message: 'productDetail.errors.invalidNumber' },
			{ type: 'min', message: 'productDetail.errors.quantityMin' },
		],
		stockAvailability: [
			{ type: 'required', message: 'productDetail.errors.stockAvailability' },
		],
		basePrice: [
			{ type: 'required', message: 'productDetail.errors.basePrice' },
			{ type: 'invalidNumber', message: 'productDetail.errors.invalidNumber' },
			{ type: 'min', message: 'productDetail.errors.basePriceMin' },
		],
		samplePrice: [
			{ type: 'required', message: 'productDetail.errors.samplePrice' },
			{ type: 'invalidNumber', message: 'productDetail.errors.invalidNumber' },
			{ type: 'min', message: 'productDetail.errors.samplePriceMin' },
		],
		sampleMaxUnit: [
			{ type: 'required', message: 'productDetail.errors.maxSampleUnit' },
			{ type: 'invalidNumber', message: 'productDetail.errors.invalidNumber' },
			{ type: 'min', message: 'productDetail.errors.maxSampleUnitMin' },
		],
		minQuantity: [
			{ type: 'required', message: 'productDetail.errors.minQuantityReq' },
			{ type: 'min', message: 'productDetail.errors.minQuantityLimit' },
		],
		conversionRatio: [
			{ type: 'required', message: 'productDetail.errors.conversionRatioReq' },
			{ type: 'invalidString', message: "productDetail.errors.conversionRatioInvalid" }
		],
		sampleSize: [
			{ type: 'required', message: 'productDetail.errors.sampleSize' },
		],
		sampleUnit: [
			{ type: 'required', message: 'productDetail.errors.sampleUnit' },
		],
		configSamplePrice: [{ type: 'required', message: 'Please enter price' }],
		productUnit: [
			{ type: 'required', message: 'productDetail.errors.productUnit' },
		],
		productSecondaryUnits: [
			{ type: 'required', message: 'productDetail.errors.productSecondaryUnits' },
		],
		country: [{ type: 'required', message: 'productDetail.errors.country' }],
		region: [{ type: 'required', message: 'productDetail.errors.region' }],
		brand: [{ type: 'required', message: 'productDetail.errors.brand' }],
		sameSKUName: [
			{ type: 'required', message: 'productDetail.errors.sameSkuName' },
		],
		sameSKUNameAr: [
			{ type: 'required', message: 'productDetail.errors.sameSkuNameAr' },
			{
				type: 'invalidName',
				message: 'productDetail.errors.sameSkuNameArInvalid',
			},
		],
		price: [
			{ type: 'required', message: 'productDetail.errors.price' },
			{ type: 'invalidNumber', message: 'productDetail.errors.invalidNumber' },
			{ type: 'min', message: 'productDetail.errors.priceMin' },
		],
		commonQuantity: [
			{ type: 'required', message: 'productDetail.errors.commonQuantity' },
			{ type: 'invalidNumber', message: 'productDetail.errors.invalidNumber' },
			{ type: 'min', message: 'productDetail.errors.commonQuantityMin' },
		],
		weight: [
			{ type: 'required', message: 'productDetail.errors.weight' },
			{ type: 'invalidNumber', message: 'productDetail.errors.invalidNumber' },
			{ type: 'min', message: 'productDetail.errors.commonQuantityMin' },
		],
	};

	//Step 2
	modalSave = false;
	updateFromModal = false;
	selectedWarehouse = [];
	warehouseQuantity = []; // array of object of quantity mapped with warehouse
	//Product preview - Step 3
	selectedCountries = [];
	selectedRegions = [];
	webImages = [
		'/assets/images/product2.png',
		'/assets/images/product2.png',
		'/assets/images/product2.png',
		'/assets/images/product2.png',
		'/assets/images/product2.png',
	];

	allDataLoaded: any;

	//static data
	documentTypeStaticList = [
		{
			id: 1,
			name: 'Technical',
			name_ar: 'اِصطِلاحِيّ',
			document_list: [], // array of document added
			//ngModel binding keys for input fields
			//-------------------
			document_name: '',
			document_name_ar: '',
			document_name_ar_error: false,
			document_description: '',
			document_description_ar: '',
			document_description_ar_error: false,
			document: null,
			document_filename: '',
			//---------------------
			isOpen: false, // accordian open flag
		},
		{
			id: 2,
			name: 'Functional',
			name_ar: 'وظيفي',
			document_list: [],
			document_name: '',
			document_name_ar: '',
			document_name_ar_error: false,
			document_description: '',
			document_description_ar: '',
			document_description_ar_error: false,
			document: null,
			document_filename: '',
			isOpen: false,
		},
	];

	constructor(
		private dataService: ApiService,
		private toastService: ToastService,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private router: Router,
		private loadingCtrl: LoadingController
	) { }

	initializeForm(data = null) {
		this.showCommonImage =
			data && data.is_common_images_to_all_sku
				? data.is_common_images_to_all_sku
				: false;
		this.showSameName =
			data && data.is_common_name_to_all_sku
				? data.is_common_name_to_all_sku
				: false;
		this.showSinglePrice =
			data && data.is_common_price_to_all_sku
				? data.is_common_price_to_all_sku
				: false;
		this.showCommonQuantity =
			data && data.is_common_quantity_to_all_sku
				? data.is_common_quantity_to_all_sku
				: false;
		this.showSampleProduct =
			data && data.is_sample_unit_available
				? data.is_sample_unit_available
				: null;
		this.showCommonWeight =
			data && data.is_common_weight_to_all_sku
				? data.is_common_weight_to_all_sku
				: 0;

		return this.formBuilder.group({
			productName: new FormControl(
				data && data.product_name_en ? data.product_name_en : null,
				{
					validators: Validators.compose([Validators.required]),
				}
			),
			productNameArabic: new FormControl(
				data && data.product_name_ar ? data.product_name_ar : null,
				{
					validators: Validators.compose([
						Validators.required,
						CustomValidator.arabicNameValidator,
					]),
				}
			),
			sameSKUName: new FormControl(
				data && data.is_common_name_to_all_sku && data.skus && data.skus.length
					? data.skus[0].product_name_en
					: null,
				{
					validators: Validators.compose([]),
				}
			),
			sameSKUNameAr: new FormControl(
				data && data.is_common_name_to_all_sku && data.skus && data.skus.length
					? data.skus[0].product_name_ar
					: null,
				{
					validators: Validators.compose([]),
				}
			),
			sameProductName: new FormControl(null, {
				validators: Validators.compose([CustomValidator.arabicNameValidator]),
			}),
			productDesc: new FormControl(
				data && data.product_desc_en ? data.product_desc_en : null,
				{
					validators: Validators.compose([Validators.required]),
				}
			),
			productDescArabic: new FormControl(
				data && data.product_desc_ar ? data.product_desc_ar : null,
				{
					validators: Validators.compose([
						Validators.required,
						CustomValidator.arabicNameValidator,
					]),
				}
			),
			subClass: new FormControl(
				{
					value:
						data && data.sub_class_id ? 'subclass' + data.sub_class_id : null,
					disabled: data ? true : false,
				},
				{
					validators: Validators.compose([Validators.required]),
				}
			),
			brand: new FormControl(data && data.brand_id ? data.brand_id : null, {
				validators: Validators.compose([Validators.required]),
			}),
			sku: new FormControl(
				{
					value: data && data.product_sku ? data.product_sku : null,
					disabled: data ? true : false,
				},
				{
					validators: Validators.compose([Validators.required]),
				}
			),
			quantity: new FormControl(
				data && data.total_quantity ? data.total_quantity : null,
				{
					validators: Validators.compose([]),
				}
			),
			minQuantity: new FormControl(
				data && data.min_quantity ? data.min_quantity : null,
				{
					validators: Validators.compose([
						Validators.required,
						Validators.min(1),
					]),
				}
			),
			conversionRatio: new FormControl(
				data && data.conversation_ratio ? +data.conversation_ratio : null,
				{
					validators: Validators.compose([
					]),
				}
			),
			weight: new FormControl(
				data && data.product_weight ? +data.product_weight : null,
				{
					validators: Validators.compose([]),
				}
			),
			stockAvailability: new FormControl(
				data && data.availability_id ? data.availability_id : null,
				{
					validators: Validators.compose([Validators.required]),
				}
			),
			basePrice: new FormControl(
				data && data.price_per_unit ? +data.price_per_unit : null,
				{
					validators: Validators.compose([
						Validators.required,
						CustomValidator.decimalNumberValidator,
						Validators.min(1),
					]),
				}
			),
			samplePrice: new FormControl(
				data && data.sample_price_per_unit ? +data.sample_price_per_unit : null,
				{
					validators: Validators.compose([
						CustomValidator.decimalNumberValidator,
					]),
				}
			),
			sampleSize: new FormControl(
				data && data.sample_unit_value ? data.sample_unit_value : null,
				{
					validators: Validators.compose([]),
				}
			),
			sampleMaxUnit: new FormControl(
				data && data.max_sample_unit_provided
					? data.max_sample_unit_provided
					: null,
				{
					validators: Validators.compose([Validators.min(1)]),
				}
			),
			// same price for all variations
			price: new FormControl(
				data && data.is_common_price_to_all_sku && data.skus && data.skus.length
					? data.skus[0].price_per_unit
					: null,
				{
					validators: Validators.compose([
						CustomValidator.decimalNumberValidator,
						Validators.min(1),
					]),
				}
			),
			configSamplePrice: new FormControl(null, {
				validators: Validators.compose([
					CustomValidator.decimalNumberValidator,
				]),
			}),
			sampleUnit: new FormControl(
				data && data.sample_unit_id ? data.sample_unit_id : null,
				{
					validators: Validators.compose([]),
				}
			),
			productUnit: new FormControl(data && data.unit_id ? data.unit_id : null, {
				validators: Validators.compose([Validators.required]),
			}),
			productSecondaryUnits: new FormControl(data && data.secondary_unit_id ? data.secondary_unit_id : null, {
				validators: Validators.compose([Validators.required]),
			}),
			country: new FormControl(
				data && data.product_countries ? data.product_countries : null,
				{
					validators: Validators.compose([Validators.required]),
				}
			),
			warehouseList: new FormControl(
				data && data.warehouses ? data.warehouses : null,
				{
					validators: Validators.compose([Validators.required]),
				}
			),
			region: new FormControl(
				data && data.product_regions ? data.product_regions : null,
				{
					validators: Validators.compose([]),
				}
			),
			commonQuantity: new FormControl(
				data &&
					data.is_common_quantity_to_all_sku &&
					data.skus &&
					data.skus.length
					? data.skus[0].quantity
					: null,
				{
					validators: Validators.compose([
						CustomValidator.numberValidator,
						Validators.min(1),
					]),
				}
			),
		});
	}

	//Initialize product form
	setFormControls(data?) {
		this.productForm = this.initializeForm(data);
	}

	getAllData() {
		return new Promise((resolve, reject) => {
			Promise.all([
				this.getSubclasses(),
				this.getManufacturingCountry(),
				this.getStockAvailability(),
				this.getBrands(),
				this.getUnits(),
				this.getWarehouses(),
				this.getDocumentList(),
			]).then(
				(data) => {
					this.allDataLoaded = true;
					setTimeout(() => {
						this.checkForMissingData();
					}, 500);
					resolve(data);
				},
				(err) => {
					setTimeout(() => {
						this.checkForMissingData();
					}, 500);
					reject(err);
				}
			);
		});
	}

	getSubclasses() {
		const params = {
			page: 0,
			limit: 0,
			status: true,
			dropdown: true,
		};
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.consolidatedGroup, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							if (data['data'] && data['data'].length) {
								let list = data['data'][0]['list'];
								this.subclassList = [];
								this.subclassOnlyList = [];
								this.subClassModelStructure(list);
								resolve(list);
							} else {
								this.toastService.displayToast(
									'error.noSubclass',
									null,
									'danger'
								);
								reject();
							}
						} else {
							if (data['code'] != 204) {
								reject();
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject();
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	subClassModelStructure(list) {
		list.forEach((group) => {
			let groupObj = {
				name: this.commonService.domDirectionLTR ? group.title : group.title_ar,
				type: group.type,
				level: group.level,
				id: group.id,
				children: group.children,
			};
			if (group.children && group.children.length) {
				groupObj['children'] = group.children;
				let groupChildren = [];
				group.children.forEach((category) => {
					let categoryObj = {};
					categoryObj['name'] = this.commonService.domDirectionLTR
						? category.title
						: category.title_ar;
					categoryObj['type'] = category.type;
					categoryObj['level'] = category.level;
					categoryObj['id'] = category.id;
					categoryObj['children'] = category.children;
					let categoryChildren = [];
					if (category.children && category.children.length) {
						category.children.forEach((classes) => {
							let classObj = {};
							classObj['name'] = this.commonService.domDirectionLTR
								? classes.title
								: classes.title_ar;
							classObj['type'] = classes.type;
							classObj['level'] = classes.level;
							classObj['id'] = classes.id;
							let subclasses = [];
							if (classes.children && classes.children.length) {
								classes.children.forEach((subclass) => {
									let subclassObj = {};
									subclassObj['name'] = this.commonService.domDirectionLTR
										? subclass.title
										: subclass.title_ar;
									subclassObj['type'] = subclass.type;
									subclassObj['level'] = subclass.level;
									subclassObj['id'] = 'subclass' + subclass.id;
									subclasses.push(subclassObj);
									this.subclassOnlyList.push(subclassObj);
								});
							}
							classObj['children'] =
								subclasses && subclasses.length ? subclasses : [];
							if (classObj['children'] && classObj['children'].length) {
								categoryChildren.push(classObj);
							}
						});
					}
					categoryObj['children'] =
						categoryChildren && categoryChildren.length ? categoryChildren : [];
					if (categoryObj['children'] && categoryObj['children'].length) {
						groupChildren.push(categoryObj);
					}
				});
				groupObj['children'] =
					groupChildren && groupChildren.length ? groupChildren : [];
			}
			if (groupObj['children'] && groupObj['children'].length) {
				this.subclassList.push(groupObj);
			}
		});
	}

	getRegions(param?) {
		let params = {
			dropdown: true,
		};
		if (param) {
			params = {
				...params,
				...param,
			};
		}
		this.regionList = [];
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.regionsList, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							this.regionList = [];
							if (
								data['data'] &&
								data['data']['result'] &&
								data['data']['result'].length
							) {
								let list =
									data['data'] &&
										data['data']['result'] &&
										data['data']['result'].length
										? data['data']['result']
										: [];
								list.forEach((regions) => {
									let obj = {
										id: regions.region_id ? regions.region_id : '',
										name: this.commonService.domDirectionLTR
											? regions.region_en
												? regions.region_en
												: ''
											: regions.region_ar
												? regions.region_ar
												: '',
									};
									this.regionList.push(obj);
								});
								resolve(this.regionList);
							} else {
								// this.toastService.displayToast('error.noRegions', null, 'danger');
								reject();
							}
						} else {
							if (data['code'] != 204) {
								reject();
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject();
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	getStockAvailability() {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.stockAvail).subscribe(
					(data) => {
						if (data['code'] == 200) {
							if (data['data'] && data['data'].length) {
								let list =
									data['data'] && data['data'].length ? data['data'] : [];
								this.stockAvailablityList = [];
								list.forEach((stock) => {
									let obj = {
										id: stock.availability_id ? stock.availability_id : '',
										name: this.commonService.domDirectionLTR
											? stock.total_available_quantity_lable_en
												? stock.total_available_quantity_lable_en
												: ''
											: stock.total_available_quantity_lable_ar
												? stock.total_available_quantity_lable_ar
												: '',
									};
									this.stockAvailablityList.push(obj);
								});
								resolve(this.stockAvailablityList);
							} else {
								this.toastService.displayToast(
									'error.noStockAvail',
									null,
									'danger'
								);
								reject();
							}
						} else {
							if (data['code'] != 204) {
								reject();
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	getBrands() {
		const params = {
			page: 0,
			limit: 0,
			is_active: true,
			dropdown: true,
			approval_status: 'APPROVED',
		};
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.brands, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							this.brandList = [];
							if (
								data['data'] &&
								data['data']['result'] &&
								data['data']['result'].length
							) {
								let list =
									data['data'] &&
										data['data']['result'] &&
										data['data']['result'].length
										? data['data']['result']
										: [];
								list.forEach((brand) => {
									let obj = {
										id: brand.brand_id ? brand.brand_id : '',
										name: this.commonService.domDirectionLTR
											? brand.brand_name_en
												? brand.brand_name_en
												: ''
											: brand.brand_name_ar
												? brand.brand_name_ar
												: '',
									};
									this.brandList.push(obj);
								});
								resolve(this.brandList);
							} else {
								this.toastService.displayToast(
									'error.noBrands',
									null,
									'danger'
								);
								reject();
							}
						} else {
							if (data['code'] != 204) {
								reject();
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject();
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	getUnits() {
		const params = {
			dropdown: true,
		};
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.units, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							if (data['data'] && data['data'].length) {
								let list =
									data['data'] && data['data'].length ? data['data'] : [];
								this.unitsList = [];
								list.forEach((unit) => {
									let obj = {
										id: unit.unit_id ? unit.unit_id : '',
										name: this.commonService.domDirectionLTR
											? unit.unit_name_en
												? unit.unit_name_en
												: ''
											: unit.unit_name_ar
												? unit.unit_name_ar
												: '',
									};
									this.unitsList.push(obj);
								});
								resolve(this.unitsList);
							} else {
								this.toastService.displayToast('error.noUnits', null, 'danger');
								reject();
							}
						} else {
							if (data['code'] != 204) {
								reject();
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject();
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	getWarehouses() {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.activeWarehouse).subscribe(
					(data) => {
						if (data['code'] == 200) {
							if (data['data'] && data['data'].length) {
								let list =
									data['data'] && data['data'].length ? data['data'] : [];
								this.warehouseList = [];
								list.forEach((warehouse) => {
									let obj = {
										id: warehouse.warehouse_id ? warehouse.warehouse_id : '',
										name: this.commonService.domDirectionLTR
											? warehouse.warehouse_title_en
												? warehouse.warehouse_title_en
												: ''
											: warehouse.warehouse_title_ar
												? warehouse.warehouse_title_ar
												: '',
									};
									this.warehouseList.push(obj);
								});
								resolve(this.warehouseList);
							} else {
								this.toastService.displayToast(
									'error.noWarehouse',
									null,
									'danger'
								);
								reject();
							}
						} else {
							if (data['code'] != 204) {
								reject();
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject();
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	getDocumentList() {
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.activeDocumentList).subscribe(
					(data) => {
						if (data['code'] == 200) {
							if (data['data'] && data['data'].length) {
								let list =
									data['data'] && data['data'].length ? data['data'] : [];
								this.documentTypeList = [];
								this.addedDocumentList = [];
								list.forEach((document) => {
									let obj = {
										id: document.document_type_id
											? document.document_type_id
											: '',
										name: document.document_type_en
											? document.document_type_en
											: '',
										name_ar: document.document_type_ar
											? document.document_type_ar
											: '',
										document_list: [], // array of document added
										//ngModel binding keys for input fields
										//-------------------
										document_name: '',
										document_name_ar: '',
										document_name_ar_error: false,
										isOpen: false,
										document_name_en_error: false,
										document_description: '',
										document_description_ar: '',
										document_description_ar_error: false,
										document_description_en_error: false,
										document: null,
										document_filename: '',
										max_doc_allowed: document.max_documents_to_upload
											? document.max_documents_to_upload
											: 1,
									};
									this.documentTypeList.push(obj);
								});
								resolve(this.documentTypeList);
							}
						} else {
							resolve(data);
							if (data['code'] != 204) {
								this.toastService.displayToast(data['message'], null, 'danger');
							} else {
							}
						}
					},
					(error) => {
						reject();
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	getManufacturingCountry() {
		const params = {
			dropdown: true,
		};
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.countryList, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							this.manufacturingCountryList = [];
							if (
								data['data'] &&
								data['data']['result'] &&
								data['data']['result'].length
							) {
								let list =
									data['data'] &&
										data['data']['result'] &&
										data['data']['result'].length
										? data['data']['result']
										: [];
								list.forEach((country) => {
									let obj = {
										id: country.country_id ? country.country_id : '',
										name: this.commonService.domDirectionLTR
											? country.country_en
												? country.country_en
												: ''
											: country.country_ar
												? country.country_ar
												: '',
									};
									this.manufacturingCountryList.push(obj);
								});
								resolve(this.manufacturingCountryList);
							} else {
								this.toastService.displayToast(
									'error.noCountry',
									null,
									'danger'
								);
								reject();
							}
						} else {
							if (data['code'] != 204) {
								reject();
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject();
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	checkForMissingData() {
		if (
			this.warehouseList.length &&
			this.manufacturingCountryList.length &&
			this.brandList.length &&
			this.subclassList.length &&
			this.stockAvailablityList.length &&
			this.unitsList.length
		) {
			this.missingData = '';
		} else {
			let data = [];
			if (!this.warehouseList.length) {
				if (this.commonService.domDirectionLTR) {
					data.push('Warehouse');
				} else {
					data.push('مستودع');
				}
			}
			let skip = true;
			if (!skip && !this.regionList.length) {
				if (this.commonService.domDirectionLTR) {
					data.push('Region');
				} else {
					data.push('منطقة');
				}
			}
			if (!this.manufacturingCountryList.length) {
				if (this.commonService.domDirectionLTR) {
					data.push('Country');
				} else {
					data.push('دولة');
				}
			}
			if (!this.brandList.length) {
				if (this.commonService.domDirectionLTR) {
					data.push('Brands');
				} else {
					data.push('العلامات التجارية');
				}
			}
			if (!this.stockAvailablityList.length) {
				if (this.commonService.domDirectionLTR) {
					data.push('Stock availability');
				} else {
					data.push('توافر المخزون');
				}
			}
			if (!this.subclassList.length) {
				if (this.commonService.domDirectionLTR) {
					data.push('Subclass');
				} else {
					data.push('فئة فرعية');
				}
			}
			if (!this.unitsList.length) {
				if (this.commonService.domDirectionLTR) {
					data.push('Units');
				} else {
					data.push('الوحدات');
				}
			}
			this.missingData = data.join(',');
		}
	}

	getSubclassDetails(id) {
		if (!id) {
			return;
		}
		const params = {
			sub_class_id: id,
		};
		this.subscription.add(
			this.dataService.get(URLS.subclassDetails, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data']) {
							this.selectedAttributeList = [];
							let attributeID = data['data']['configuration_id']
								? data['data']['configuration_id'].sort()
								: [];
							attributeID.forEach((id) => {
								this.getAttributes(id);
							});
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	getAttributes(id) {
		if (!id) {
			return;
		}
		const params = {
			configuration_id: id,
		};

		this.subscription.add(
			this.dataService.get(URLS.configurationDetails, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data']) {
							let attributeDetail = {
								isSelectAllSupplier: false,
								key: data['data']['configuration_id']
									? data['data']['configuration_id']
									: 'noname',
								id: data['data']['configuration_id']
									? data['data']['configuration_id']
									: '',
								attribute: data['data']['configuration_name_en']
									? data['data']['configuration_name_en']
									: '',
								attributeAr: data['data']['configuration_name_ar']
									? data['data']['configuration_name_ar']
									: '',
								type:
									data['data']['configuration_description_type'] &&
										data['data']['configuration_description_type'] ==
										'VALUE BASED'
										? 'dd'
										: data['data']['configuration_description_type'] &&
											data['data']['configuration_description_type'] ==
											'DESCRIPTIVE'
											? 'editor'
											: data['data']['configuration_description_type'] &&
												data['data']['configuration_description_type'] == 'BULLET'
												? 'text'
												: '',
							};
							if (
								data['data']['configuration_description_type'] &&
								data['data']['configuration_description_type'] == 'VALUE BASED'
							) {
								let options =
									data['data']['configuration_values'] &&
										data['data']['configuration_values'].length > 0
										? data['data']['configuration_values']
										: [];
								let dropdownOption = [];
								options.forEach((el) => {
									let optionObj = {
										label: el.configuration_value_en,
										id: el.configuration_value_id,
										configuration_id: data['data']['configuration_id']
											? data['data']['configuration_id']
											: '',
										configuration_value_id: el.configuration_value_id,
									};
									dropdownOption.push(optionObj);
								});
								attributeDetail['valueOptions'] = dropdownOption;
								attributeDetail['selectedAttr'] = [];
								attributeDetail['previouslySelectedAttr'] = [];
								attributeDetail['selectedAttrId'] = [];
								attributeDetail['isMainConfig'] = data['data'][
									'is_main_configurations'
								]
									? data['data']['is_main_configurations']
									: false;
							}
							if (attributeDetail['type'] == 'dd') {
								attributeDetail['validation_message'] = [
									{
										type: 'required',
										message:
											'Please select ' + data['data']['configuration_name_en'],
									},
								];
							} else {
								attributeDetail['validation_message'] = [
									{
										type: 'required',
										message:
											'Please enter ' + data['data']['configuration_name_en'],
									},
								];
							}

							if (this.savedProduct) {
								if (this.savedProduct.product_common_configurations) {
									if (!attributeDetail['isMainConfig']) {
										this.savedProduct.product_common_configurations.forEach(
											(element) => {
												if (attributeDetail['id'] == element.configuration_id) {
													if (attributeDetail.type == 'dd') {
														attributeDetail['selectedAttr'] =
															element.configuration_value_id
																? [element.configuration_value_id]
																: [];
														attributeDetail['previouslySelectedAttr'] =
															element.configuration_value_id
																? [element.configuration_value_id]
																: [];
													} else {
														attributeDetail['selectedAttr'] =
															element.configuration_value
																? element.configuration_value
																: '';
														attributeDetail['previouslySelectedAttr'] =
															element.configuration_value
																? element.configuration_value
																: '';
													}
												}
											}
										);
									}
								}
								if (this.savedProduct.product_main_configurations) {
									if (attributeDetail['isMainConfig']) {
										this.savedProduct.product_main_configurations.forEach(
											(element) => {
												if (attributeDetail['id'] == element.configuration_id) {
													if (attributeDetail.type == 'dd') {
														attributeDetail['selectedAttr'] =
															element.configration_values &&
																element.configration_values.length
																? element.configration_values
																: [];
														attributeDetail['previouslySelectedAttr'] =
															element.configration_values &&
																element.configration_values.length
																? element.configration_values
																: [];
														if (attributeDetail['selectedAttr'].length) {
															attributeDetail['selectedAttr'].forEach(
																(attr) => {
																	let attrObj = attributeDetail[
																		'valueOptions'
																	].find((el) => el.id == attr);
																	if (attrObj) {
																		attributeDetail['selectedAttrId'].push(
																			attrObj
																		);
																	}
																}
															);
														}
													} else {
														attributeDetail['selectedAttr'] =
															element.configuration_value
																? element.configuration_value
																: '';
														attributeDetail['previouslySelectedAttr'] =
															element.configuration_value
																? element.configuration_value
																: '';
													}
												}
											}
										);
									}
								}
							}

							this.productForm.addControl(
								attributeDetail.key,
								new FormControl({
									value:
										attributeDetail['selectedAttr'] &&
											attributeDetail['selectedAttr'].length
											? attributeDetail['isMainConfig'] ||
												attributeDetail['type'] != 'dd'
												? attributeDetail['selectedAttr']
												: attributeDetail['selectedAttr'][0]
											: null,
									disabled: this.savedProduct ? false : false,
								})
							);
							this.selectedAttributeList.push(attributeDetail);
							this.selectedAttributeList.sort((a, b) => a.id - b.id);
						}
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	//Generate SKU products configuration
	generateProducts(values) {
		let configurationsList = _.product(...values);
		let variations = [];
		configurationsList.map((element) => {
			let label = _.map(element, 'label').join('-');
			let variationObj = {
				min_quantity: this.productForm.value.minQuantity
					? +this.productForm.value.minQuantity
					: 1,
				product_weight: this.showCommonWeight
					? +this.productForm.value.weight
					: 0.0,

				name: this.showSameName
					? this.productForm.value.sameSKUName
					: this.productForm.value.productName,
				conversation_ratio: this.productForm.value.conversionRatio
					? +this.productForm.value.conversionRatio
					: null,
				name_ar: this.showSameName
					? this.productForm.value.sameSKUNameAr
					: this.productForm.value.productNameArabic,
				product_sku: this.showSameName
					? (
						(this.savedProduct
							? this.savedProduct.product_sku
							: this.productForm.value.sku) +
						'-' +
						label
					).replace(/ /g, '_')
					: (
						(this.savedProduct
							? this.savedProduct.product_sku
							: this.productForm.value.sku) +
						'-' +
						label
					).replace(/ /g, '_'),
				price: this.showSinglePrice
					? this.productForm.value.price
					: this.productForm.value.basePrice,
				Quantity: this.showCommonQuantity
					? this.productForm.value.commonQuantity
					: 0, //this.productForm.value.quantity,
				configurations: _.map(element, (e) =>
					_.pick(e, ['configuration_id', 'configuration_value_id'])
				), // Lodash method to pick only required key
			};
			variations.push(variationObj);
		});
		return variations;
	}

	addProduct(params) {
		this.documentTypeList.forEach((doc) => {
			if (doc.document_list && doc.document_list.length) {
				this.addedDocumentList = [
					...this.addedDocumentList,
					...doc.document_list,
				];
			}
		});
		if (this.isAddingProduct) {
			return;
		}
		this.isAddingProduct = true;

		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.addProduct, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							this.savedProduct = data['data'];
							this.mapSelectedValues(
								this.savedProduct.product_countries,
								this.manufacturingCountryList,
								'country'
							);
							this.selectedRegions = [];
							if (this.savedProduct && this.savedProduct.product_regions) {
								this.mapSelectedValues(
									this.savedProduct.product_regions,
									this.regionList,
									'region'
								);
								this.savedProduct.product_regions.forEach((regionId) => {
									let obj = this.regionList.find(
										(region) => region.id == regionId
									);
									if (obj) {
										this.selectedRegions.push(obj);
									}
								});
							}
							this.savedProduct.subclass_name = this.selectedSubclass
								? this.selectedSubclass.name
								: '';
							this.savedProduct.brand_name = this.mapIdWithValue(
								this.brandList,
								'id',
								this.savedProduct.brand_id
							);
							this.savedProduct.product_unit_name = this.mapIdWithValue(
								this.unitsList,
								'id',
								this.savedProduct.unit_id
							);
							this.savedProduct.product_secondary_unit_name = this.mapIdWithValue(
								this.unitsList,
								'id',
								this.savedProduct.secondary_unit_id
							);
							this.savedProduct.availability_name = this.mapIdWithValue(
								this.stockAvailablityList,
								'id',
								this.savedProduct.availability_id
							);
							this.warehouseQuantity = [];
							if (this.savedProduct.skus && this.savedProduct.skus.length) {
								this.savedProduct.skus.map((x) => {
									if (this.selectedWarehouse && this.selectedWarehouse.length) {
										let warehouses = [];
										this.selectedWarehouse.map((warehouse) => {
											let obj = {
												warehouse_id: warehouse.id,
												available_quantity:
													this.showCommonQuantity &&
														this.productForm &&
														this.productForm.value.commonQuantity
														? this.productForm.value.commonQuantity
														: 0,
											};
											warehouses.push(obj);
										});
										let warehousesObj = {
											product_id: x.product_id,
											warehouses: warehouses,
										};
										this.warehouseQuantity.push(warehousesObj);
									}
								});
							}
							let encryptedProduct = this.commonService.encryptData(
								JSON.stringify(this.savedProduct)
							);
							// localStorage.setItem('product', encryptedProduct);
							let promiseArray = [];
							promiseArray.push(
								this.imageUpload(this.savedProduct.product_id, '1', 'base')
							);
							if (this.addedDocumentList && this.addedDocumentList.length) {
								this.addedDocumentList.forEach((doc) => {
									promiseArray.push(
										this.addDocument(this.savedProduct.product_id, doc)
									);
								});
							}
							if (this.mobileImagesFile.length) {
								this.mobileImagesFile.forEach((file) => {
									promiseArray.push(
										this.imageUpload(
											this.savedProduct.product_id,
											'1',
											'mobile',
											file.image_url
										)
									);
								});
							}
							if (this.webImagesFile.length) {
								this.webImagesFile.forEach((file) => {
									promiseArray.push(
										this.imageUpload(
											this.savedProduct.product_id,
											'1',
											'web',
											file.image_url
										)
									);
								});
							}

							Promise.all(promiseArray).then(
								(images) => {
									this.isAddingProduct = false;
									this.mobileImagesFile = [];
									this.webImagesFile = [];
									this.addedDocumentList = [];
									this.documentTypeList.forEach((element) => {
										element.document_list = [];
									});
									resolve(images);
								},
								(err) => {
									this.addedDocumentList = [];
									this.documentTypeList.forEach((element) => {
										element.document_list = [];
									});
									this.isAddingProduct = false;
									reject(err);
								}
							);
						} else {
							this.isAddingProduct = false;
							if (data['code'] != 204) {
								reject();
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject();
						this.isAddingProduct = false;
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	imageUpload(id, pageNo, type?, file?) {
		let params = new FormData();
		params.append('product_id', id);
		params.append('page', pageNo);
		if (type == 'base') {
			if (this.baseWebImageFile.length) {
				params.append('default_image_web', this.baseWebImageFile[0].image_url);
			}
			if (this.baseMobileImageFile.length) {
				params.append(
					'default_image_mobile',
					this.baseMobileImageFile[0].image_url
				);
			}
		}
		if (pageNo == 1 && this.showCommonImage) {
			if (type == 'mobile') {
				params.append('images_mobile', file);
			}
			if (type == 'web') {
				params.append('images_web', file);
			}
		} else {
			if (type == 'mobile') {
				params.append('images_mobile', file);
			}
			if (type == 'web') {
				params.append('images_web', file);
			}
		}

		return new Promise<any>((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.addImage, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							if (pageNo == 1 && this.showCommonImage) {
								if (type == 'mobile') {
									if (
										data['data'] &&
										data['data']['images_mobile'] &&
										data['data']['images_mobile'].length
									) {
										let image_url =
											data['data']['images_mobile'][0].split('/products')[1];
										this.savedMobileImages.push('products' + image_url);
									}
								}
								if (type == 'web') {
									if (
										data['data'] &&
										data['data']['images_web'] &&
										data['data']['images_web'].length
									) {
										let image_url =
											data['data']['images_web'][0].split('/products')[1];
										this.savedWebImages.push('products' + image_url);
									}
								}
							}
							resolve(data);
						} else {
							if (data['code'] != 204) {
								reject(data);
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject(error);
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	updateProductVariations(
		nextStep = null,
		callFromStep1 = false,
		fromEdit = false,
		params = {},
		bypassValidations = false
	) {
		let priceQuantityError = false;
		this.warehouseQuantity = [];
		let imageError = false;
		let isWeightNotSet = false;
		let isNameNotSet = false;
		let isPriceNotSet = false;
		let isWebCoverNotSet = false;
		let isMobileCoverNotSet = false;
		if (this.savedProduct.skus && this.savedProduct.skus.length) {
			this.savedProduct.skus.map((x) => {
				if (priceQuantityError == false && !imageError) {
					if (
						!x['price_per_unit'] ||
						!x['product_sku'] ||
						!x['product_name_en']
					) {
						if (!x['price_per_unit']) {
							isPriceNotSet = true;
						}
						if (!x['product_name_en']) {
							isNameNotSet = true;
						}
						priceQuantityError = true;
					}
					if (!callFromStep1 && !this.updateFromModal) {
						if (!x['product_weight']) {
							isWeightNotSet = true;
							priceQuantityError = true;
						}
						if (!x['product_images_mobile'] || !x['product_images_web']) {
							imageError = true;
						}
						if (
							(x['product_images_mobile'] &&
								!x['product_images_mobile'].length) ||
							(x['product_images_web'] && !x['product_images_web'].length)
						) {
							imageError = true;
						}
						if (!imageError) {
							if (
								!isMobileCoverNotSet &&
								x['product_images_mobile'] &&
								x['product_images_mobile'].length
							) {
								let mobileCoverObj = x['product_images_mobile'].find(
									(img) => img.is_cover_image
								);
								if (!mobileCoverObj) {
									isMobileCoverNotSet = false;
									x['product_images_mobile'][0]['is_cover_image'] = true;
								}
							}
							if (
								!isWebCoverNotSet &&
								x['product_images_web'] &&
								x['product_images_web'].length
							) {
								let webCoverObj = x['product_images_web'].find(
									(img) => img.is_cover_image
								);
								if (!webCoverObj) {
									isWebCoverNotSet = false;
									x['product_images_web'][0]['is_cover_image'] = true;
								}
							}
						}
					}
					if (!callFromStep1) {
						x['product_weight'] = +x['product_weight'];
					}
					if (callFromStep1) {
						x['product_name_ar'] =
							this.showSameName &&
								this.productForm &&
								this.productForm.value.sameSKUNameAr
								? this.productForm.value.sameSKUNameAr
								: x['product_name_ar'];
						x['product_name_en'] =
							this.showSameName &&
								this.productForm &&
								this.productForm.value.sameSKUName
								? this.productForm.value.sameSKUName
								: x['product_name_en'];
						x['quantity'] = 0;
						x['total_quantity'] = 0;
						x['price_per_unit'] =
							this.showSinglePrice &&
								this.productForm &&
								this.productForm.value.price
								? this.productForm.value.price
								: x['price_per_unit'];
						x['product_weight'] =
							this.showCommonWeight &&
								this.productForm &&
								this.productForm.value.weight
								? +this.productForm.value.weight
								: x['product_weight'];
						x['min_quantity'] = this.productForm.value.minQuantity
							? +this.productForm.value.minQuantity
							: +x['min_quantity'];
						x['conversation_ratio'] = this.productForm.value.conversionRatio && !x['conversation_ratio']
							? +this.productForm.value.conversionRatio
							: +x['conversation_ratio'];
						if (this.showCommonImage) {
							if (this.savedWebImages.length) {
								let imgAr = [];
								let size =
									5 -
									(x['product_images_web'] && x['product_images_web'].length
										? x['product_images_web'].length
										: 0);
								for (var i = 0; i < size; i++) {
									if (this.savedWebImages[i]) {
										imgAr.push(this.savedWebImages[i]);
									}
								}
								x['images_web'] = imgAr;
							} else {
								if (this.modalSave) {
									x['images_web'] = x['images_web'];
								} else {
									if (!x['images_web']) {
										x['images_web'] = [];
									}
								}
							}
							if (this.savedMobileImages.length) {
								let imgAr = [];
								let size =
									5 -
									(x['product_images_mobile'] &&
										x['product_images_mobile'].length
										? x['product_images_mobile'].length
										: 0);
								for (var i = 0; i < size; i++) {
									if (this.savedMobileImages[i]) {
										imgAr.push(this.savedMobileImages[i]);
									}
								}
								x['images_mobile'] = imgAr;
							} else {
								if (this.modalSave) {
									x['images_mobile'] = x['images_mobile'];
								} else {
									if (!x['images_mobile']) {
										x['images_mobile'] = [];
									}
								}
							}
						}
						if (!x['product_images_mobile']) {
							x['product_images_mobile'] = [];
						}
						if (!x['product_images_web']) {
							x['product_images_web'] = [];
						}
					}
				}
				if (this.selectedWarehouse && this.selectedWarehouse.length) {
					let warehouses = [];
					this.selectedWarehouse.map((warehouse) => {
						// if(x[warehouse.id]){
						let obj = {
							warehouse_id: warehouse.id,
							available_quantity:
								this.showCommonQuantity &&
									this.productForm &&
									callFromStep1 &&
									this.productForm.value.commonQuantity
									? this.productForm.value.commonQuantity
									: x[warehouse.id]
										? x[warehouse.id]
										: 0,
						};
						warehouses.push(obj);
						// delete x[warehouse.id] // might be required
						// }
					});
					let warehousesObj = {
						product_id: x.product_id,
						warehouses: warehouses,
					};
					this.warehouseQuantity.push(warehousesObj);
				}
			});
		} else {
			this.savedProduct.skus = [];
		}
		if (!bypassValidations) {
			if (priceQuantityError) {
				if (isNameNotSet) {
					this.toastService.displayToast('error.productName', null, 'danger');
					return;
				}
				if (isPriceNotSet) {
					this.toastService.displayToast('error.productPrice', null, 'danger');
					return;
				}
				if (isWeightNotSet) {
					this.toastService.displayToast('error.productWeight', null, 'danger');
					return;
				}
				return;
			}
			if (imageError) {
				this.toastService.displayToast('error.allImage', null, 'danger');
				return;
			}
			if (isWebCoverNotSet) {
				this.toastService.displayToast('error.webCover', null, 'danger');
				return;
			}
			if (isMobileCoverNotSet) {
				this.toastService.displayToast('error.mobileCover', null, 'danger');
				return;
			}

		}
		let requestData = {
			product_details: this.savedProduct.skus.slice(),
		};
		if (callFromStep1) {
			requestData.product_details.push({
				product_id: this.savedProduct.product_id,
				product_countries: this.productForm
					? this.productForm.value.country
					: this.savedProduct.product_countries,
				// product_regions: this.productForm ? this.productForm.value.region : this.savedProduct.product_regions,
				product_name_en: this.productForm
					? this.productForm.value.productName
					: this.savedProduct.product_name_en,
				product_name_ar: this.productForm
					? this.productForm.value.productNameArabic
					: this.savedProduct.product_name_ar,
				product_desc_en: this.productForm
					? this.productForm.value.productDesc
					: this.savedProduct.product_desc_en,
				product_desc_ar: this.productForm
					? this.productForm.value.productDescArabic
					: this.savedProduct.product_desc_ar,
				total_quantity: 0, //this.productForm ? this.productForm.value.quantity : this.savedProduct.total_quantity,
				unit_id: this.productForm
					? this.productForm.value.productUnit
					: this.savedProduct.unit_id,
				secondary_unit_id: this.productForm
					? this.productForm.value.productSecondaryUnits
					: this.savedProduct.secondary_unit_id,
				availability_id: this.productForm
					? this.productForm.value.stockAvailability
					: this.savedProduct.availability_id,
				price_per_unit: this.productForm
					? this.productForm.value.basePrice
					: +this.savedProduct.price_per_unit,
				brand_id: this.productForm
					? this.productForm.value.brand
					: this.savedProduct.brand_id,
				product_weight: this.productForm
					? +this.productForm.value.weight
					: +this.savedProduct.product_weight,
				...params,
			});
		}
		if (requestData.product_details && requestData.product_details.length) {
			requestData.product_details.forEach(element => {
				if (!element.supplier_product_sku) {
					delete element.supplier_product_sku
				}
			});
		}
		this.isAddingProduct = true;
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.updateProduct, requestData).subscribe(
					(data) => {
						if (data['code'] == 200) {
							if (this.updateFromModal) {
								this.addInventory(this.warehouseQuantity).then(
									() => {
										this.isAddingProduct = false;
										this.savedWebImages = [];
										this.savedMobileImages = [];
										this.modalSave = false;
										this.updateFromModal = false;
										this.getProductDetails(this.savedProduct.product_id).then(
											(data) => {
												this.savedProduct.skus.map((item, index) => {
													if (
														item.product_images_mobile &&
														item.product_images_mobile.length
													) {
														let obj = item.product_images_mobile.find(
															(el) => el.is_cover_image
														);
														item.mobile_cover_img = obj ? true : false;
													}
													if (
														item.product_images_web &&
														item.product_images_web.length
													) {
														let obj = item.product_images_web.find(
															(el) => el.is_cover_image
														);
														item.web_cover_img = obj ? true : false;
													}
													if (item.inventory && item.inventory.length) {
														item.inventory.map((invItem) => {
															item[invItem.warehouse_id] =
																invItem.available_quantity
																	? invItem.available_quantity
																	: 0;
														});
													}
												});
											}
										);
									},
									(err) => {
										this.isAddingProduct = false;
										this.savedWebImages = [];
										this.savedMobileImages = [];
										this.modalSave = false;
										this.updateFromModal = false;
										this.getProductDetails(this.savedProduct.product_id).then(
											(data) => {
												this.savedProduct.skus.map((item, index) => {
													if (
														item.product_images_mobile &&
														item.product_images_mobile.length
													) {
														let obj = item.product_images_mobile.find(
															(el) => el.is_cover_image
														);
														item.mobile_cover_img = obj ? true : false;
													}
													if (
														item.product_images_web &&
														item.product_images_web.length
													) {
														let obj = item.product_images_web.find(
															(el) => el.is_cover_image
														);
														item.web_cover_img = obj ? true : false;
													}
													if (item.inventory && item.inventory.length) {
														item.inventory.map((invItem) => {
															item[invItem.warehouse_id] =
																invItem.available_quantity
																	? invItem.available_quantity
																	: 0;
														});
													}
												});
											}
										);
									}
								);
							} else {
								this.isAddingProduct = false;
								this.savedWebImages = [];
								this.savedMobileImages = [];
								this.modalSave = false;
								this.updateFromModal = false;
								this.getProductDetails(this.savedProduct.product_id).then(
									(data) => {
										this.savedProduct.skus.map((item, index) => {
											if (
												item.product_images_mobile &&
												item.product_images_mobile.length
											) {
												let obj = item.product_images_mobile.find(
													(el) => el.is_cover_image
												);
												item.mobile_cover_img = obj ? true : false;
											}
											if (
												item.product_images_web &&
												item.product_images_web.length
											) {
												let obj = item.product_images_web.find(
													(el) => el.is_cover_image
												);
												item.web_cover_img = obj ? true : false;
											}
											if (item.inventory && item.inventory.length) {
												item.inventory.map((invItem) => {
													item[invItem.warehouse_id] =
														invItem.available_quantity
															? invItem.available_quantity
															: 0;
												});
											}
										});
									}
								);
							}
							resolve(data);
						} else {
							this.isAddingProduct = false;
							this.modalSave = false;
							reject();
							// if (data['code'] != 204) {
							//   this.toastService.displayToast(data['message'], null, 'danger');
							// }
						}
					},
					(error) => {
						reject();
						this.isAddingProduct = false;
						this.modalSave = false;
						// this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	getProductDetails(id) {
		const params = {
			product_id: id,
		};
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.productDetails, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							this.savedProduct = data.data;
							if (this.savedProduct) {
								this.savedProduct.subclass_name = this.selectedSubclass
									? this.selectedSubclass.name
									: '';
								this.savedProduct.brand_name = this.mapIdWithValue(
									this.brandList,
									'id',
									this.savedProduct.brand_id
								);
								this.savedProduct.product_unit_name = this.mapIdWithValue(
									this.unitsList,
									'id',
									this.savedProduct.unit_id
								);
								this.savedProduct.product_secondary_unit_name = this.mapIdWithValue(
									this.unitsList,
									'id',
									this.savedProduct.secondary_unit_id
								);
								this.savedProduct.availability_name = this.mapIdWithValue(
									this.stockAvailablityList,
									'id',
									this.savedProduct.availability_id
								);
								this.mapSelectedValues(
									this.savedProduct.product_countries,
									this.manufacturingCountryList,
									'country'
								);
								// this.mapSelectedValues(this.savedProduct.product_regions, this.regionList, 'region');
								if (
									this.savedProduct.default_mobile_images &&
									this.savedProduct.default_mobile_images.length > 0
								) {
									this.baseMobileImagePreview = [];
									this.savedProduct.default_mobile_images.forEach(
										(image, i) => {
											if (image.is_cover_image) {
												let obj = {
													is_cover_image: image.is_cover_image,
													image_url: image.image_url,
													image_id: image.image_id,
												};
												this.baseMobileImagePreview.push(obj);
											}
										}
									);
								}
								if (
									this.savedProduct.default_web_images &&
									this.savedProduct.default_web_images.length > 0
								) {
									this.baseWebImagePreview = [];
									this.savedProduct.default_web_images.forEach((image, i) => {
										if (image.is_cover_image) {
											let obj = {
												is_cover_image: image.is_cover_image,
												image_url: image.image_url,
												image_id: image.image_id,
											};
											this.baseWebImagePreview.push(obj);
										}
									});
								}
							}
							resolve(this.savedProduct);
						} else {
							reject();
							// if (data['code'] != 204) {
							//   this.toastService.displayToast(data['message'], null, 'danger');
							// }
						}
					},
					(error) => {
						reject();
						// this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	next(id) {
		this.commonService.setEvent('productStep', {
			isStep: 1,
			isSecondStep: true,
		});
		this.router.navigate([
			'account-details/products-management/product/add-new-product/product-configuration',
			id,
		]);
	}

	editNext(id) {
		this.commonService.setEvent('productStep', {
			isStep: 1,
			isSecondStep: true,
		});
		this.router.navigate([
			'account-details/products-management/product/edit/product-configuration',
			id,
		]);
	}

	resetFlags(storageClear = false) {
		this.selectedAttributeList = [];
		this.webImagesFile = [];
		this.webPreviewImages = [];
		this.mobilePreviewImages = [];
		this.mobileImagesFile = [];
		this.baseMobileImageFile = [];
		this.baseMobileImagePreview = [];
		this.baseWebImagePreview = [];
		this.baseWebImageFile = [];
		this.savedProduct = null;
		if (storageClear) {
			localStorage.removeItem('product');
		}
		this.showCommonImage = false;
		this.showCustomOptions = false;
		this.showSampleProduct = false;
		this.showSinglePrice = false;
		this.showCommonWeight = false;
		this.showSampleProduct = false;
		this.withVat = true;
		this.showWarehouse = false;
		this.disableFields = false;
		this.bothCharged = false;
		this.shipmentCharged = false;
		this.selectedCountries = [];
		this.addedDocumentList = [];
		this.selectedRegions = [];
		this.documentTypeList.forEach((element) => {
			element.id = element.document_type_id;
			element.name = element.document_type_en;
			element.name_ar = element.document_type_ar;
			(element.document_list = []), // array of document added
				//ngModel binding keys for input fields
				//-------------------
				(element.document_name = '');
			element.document_name_ar = '';
			element.document_name_ar_error = false;
			element.document_name_en_error = false;
			element.document_description = '';
			element.document_description_ar = '';
			element.document_description_ar_error = false;
			element.document_description_en_error = false;
			element.document = null;
			element.isOpen = false;
			element.document_filename = '';
			element.max_doc_allowed = element.max_documents_to_upload
				? element.max_documents_to_upload
				: 1;
		});
		if (this.productForm) {
			this.productForm.reset();
		}
		// this.selectedWarehouses = [];
	}

	mapIdWithValue(list = [], keyName, keyValue) {
		let obj = list.find((el) => el[keyName] == keyValue);
		if (obj) {
			return obj.name;
		} else {
			return '';
		}
	}

	mapSelectedValues(selectedValueArray, dataList, value) {
		if (value == 'country') {
			this.selectedCountries = [];
		} else {
			this.selectedRegions = [];
		}
		if (selectedValueArray) {
			selectedValueArray.forEach((valueId) => {
				let obj = dataList.find((el) => el.id == valueId);
				if (obj) {
					if (value == 'country') {
						this.selectedCountries.push(obj);
					} else {
						this.selectedRegions.push(obj);
					}
				}
			});
		}
	}

	addDocument(id, document) {
		let params = new FormData();
		params.append('product_id', id);
		params.append('document_type_id', document.document_type_id);
		params.append('document_name_en', document.name);
		params.append('document_name_ar', document.name_ar);
		params.append('document_description_en', document.description);
		params.append('document_description_ar', document.description_ar);
		params.append('file', document.file);
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.addDocument, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							resolve(data);
						} else {
							if (data['code'] != 204) {
								reject(data);
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject(error);
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	updateDocument(id, document, deleting?) {
		let params = new FormData();
		params.append('product_id', id);
		params.append('document_type_id', document.document_type_id);
		params.append('product_document_id', document.product_document_id);
		if (deleting) {
			params.append('is_active', 'false');
		} else {
			params.append('document_name_en', document.name);
			params.append('document_name_ar', document.name_ar);
			params.append('document_description_en', document.description);
			params.append('document_description_ar', document.description_ar);
		}
		if (document.file) {
			params.append('file', document.file);
		}
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.put(URLS.updateDocument, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							resolve(data);
						} else {
							if (data['code'] != 204) {
								reject(data);
								this.toastService.displayToast(data['message'], null, 'danger');
							}
						}
					},
					(error) => {
						reject(error);
						this.toastService.displayToast(error['message'], null, 'danger');
					}
				)
			);
		});
	}

	addInventory(warehouses = []) {
		let params = {
			products: warehouses,
		};
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.addProductInventory, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							resolve(data);
						} else {
							if (data['code'] != 204) {
								reject(data);
							}
						}
					},
					(error) => {
						reject(error);
					}
				)
			);
		});
	}

	imageDelete(id) {
		const params = {
			image_id: id,
		};

		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.deleteImage, params).subscribe(
					(data) => {
						if (data['code'] == 200) {
							resolve(data);
						} else {
							this.toastService.displayToast(data['message'], null, 'danger');
							reject(data);
						}
					},
					(error) => {
						reject();
					}
				)
			);
		});
	}

	async showLoading() {
		const loading = await this.loadingCtrl.create(this.loaderConfig);
		loading.present();
	}


	hideLoading() {
		setTimeout(() => {
			this.loadingCtrl.getTop().then(data => {
				data ? this.loadingCtrl.dismiss() : null;
			});
		}, 300);

	}
}
