import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomValidator } from '../../_common/customvalidators';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ToastService } from '../../_services/toast.service';
import { GetLatLongService } from './../../_services/get-lat-long';

@Component({
    selector: 'app-add-edit-warehouse',
    templateUrl: './add-edit-warehouse.component.html',
    styleUrls: ['./add-edit-warehouse.component.scss'],
})
export class AddEditWarehouseComponent implements OnInit {
    public subscription = new Subscription();
    public warehouseForm: FormGroup;
    isLoading = false;
    isRegionDisable = true;
    isCityDisable = true;
    warehouseId: any;

    public validation_messages = {
        warehouseName: [
            { type: 'required', message: 'addEditWarehouse.errors.nameRequired' },
            { type: 'invalidString', message: 'addEditWarehouse.errors.nameInvalid' },
            { type: 'minlength', message: 'addEditWarehouse.errors.nameMinLength' },
        ],
        warehouseNameArabic: [
            { type: 'required', message: 'addEditWarehouse.errors.nameArRequired' },
            { type: 'invalidName', message: 'addEditWarehouse.errors.invalidNameAr' },
        ],
        contactName: [
            { type: 'required', message: 'addEditWarehouse.errors.contactNameRequired' },
            { type: 'invalidName', message: 'addEditWarehouse.errors.contactNameInvalid' },
            { type: 'minlength', message: 'addEditWarehouse.errors.contactNameMinLength' },
        ],
        email: [
            { type: 'required', message: 'MyProfileComponent.emailRequired' },
            { type: 'invalidEmail', message: 'MyProfileComponent.invalidEmail' },
        ],
        mobileNumber: [
            { type: 'required', message: 'addEditWarehouse.errors.mobileNumberRequired' },
            { type: 'invalidNumber', message: 'addEditWarehouse.errors.mobileNumberInvalid' },
        ],
        address: [
            { type: 'required', message: 'addEditWarehouse.errors.addressRequired' },
            { type: 'minlength', message: 'addEditWarehouse.errors.nameMinLength' },
        ],
        city: [
            { type: 'required', message: 'addEditWarehouse.errors.cityRequired' }
        ],
        zipcode: [
            { type: 'required', message: 'MyProfileComponent.zipRequired' },
            { type: 'minlength', message: 'MyProfileComponent.zipMaxlength' },
        ],
        region: [
            { type: 'required', message: 'addEditWarehouse.errors.regionRequired' }
        ],
        country: [
            { type: 'required', message: 'addEditWarehouse.errors.countryRequired' }
        ],
        addressAr: [
            { type: 'required', message: 'addEditWarehouse.errors.addressArRequired' },
            { type: 'invalidName', message: 'addEditWarehouse.errors.addressArInvalid' },
        ],
    };

    regionList = [];
    warehouseDetails = [];
    countryList = [];
    cityList = [];
    add = true;
    editData: any;

    constructor(
        private formBuilder: FormBuilder,
        private dataService: ApiService,
        private toastService: ToastService,
        public commonService: CommonService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        public getLatLongService: GetLatLongService
    ) {
        this.warehouseId = this.activeRoute.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        this.warehouseForm = this.initializeForm();

        this.getCountryList();
        // this.getRegions();
        // this.getCityList();
        this.warehouseForm.controls.region.disable();
        this.warehouseForm.controls.city.disable();

        if (this.router.url.includes('edit') && this.warehouseId) {
            this.add = false;
            this.getRegions();
            this.getCityList();
            this.getWarehouseData(this.warehouseId);
        } else {
            this.add = true;
        }
    }

    initializeForm(data = null) {
        return this.formBuilder.group({
            warehouseName: new FormControl(
                data && data.name ? data.name : null,
                {
                    validators: Validators.compose([Validators.required, Validators.minLength(5), CustomValidator.noWhiteSpaceValidator]),
                }
            ),
            warehouseNameArabic: new FormControl(
                data && data.nameAr ? data.nameAr : null,
                {
                    validators: Validators.compose([
                        Validators.required,
                        CustomValidator.arabicNameValidator
                    ]),
                }
            ),
            contactName: new FormControl(
                data && data.contactName ? data.contactName : null,
                {
                    validators: Validators.compose([
                        Validators.required,
                        Validators.minLength(2),
                        CustomValidator.nameValidatorMinCharacter
                    ]),
                }
            ),
            email: new FormControl(
                data && data.email ? data.email : null,
                {
                    validators: Validators.compose([
                        Validators.required,
                        CustomValidator.emailValidator,
                    ]),
                }
            ),
            mobileNumber: new FormControl(
                data && data.mobileNumber ? data.mobileNumber : null,
                {
                    validators: Validators.compose([
                        Validators.required,
                        CustomValidator.phoneValidator
                    ]),
                }
            ),
            city: new FormControl(
                data && data.city ? data.city : null,
                {
                    validators: Validators.compose([Validators.required]),
                }
            ),
            zipcode: new FormControl(
                data && data.zipcode ? data.zipcode : null,
                {
                    validators: Validators.compose([Validators.required, Validators.minLength(5),]),
                }
            ),
            region: new FormControl(
                data && data.region ? data.region : null,
                {
                    validators: Validators.compose([Validators.required]),
                }
            ),
            country: new FormControl(
                data && data.country ? data.country : null,
                {
                    validators: Validators.compose([Validators.required]),
                }
            ),
            address: new FormControl(
                data && data.address ? data.address : null,
                {
                    validators: Validators.compose([Validators.required, Validators.minLength(5)]),
                }
            ),
            addressAr: new FormControl(
                data && data.addressAr ? data.addressAr : null,
                {
                    validators: Validators.compose([
                        Validators.required,
                        CustomValidator.arabicNameValidator,
                    ]),
                }
            ),
        });
    }

    getCountryList() {
        const params = {
            dropdown: true,
        };
        this.subscription.add(
            this.dataService.get(URLS.countryList, params).subscribe(
                (data) => {
                    if (data['code'] == 200) {
                        this.countryList = [];
                        if (data['data'] && data['data']['result'].length) {
                            let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
                            list.forEach((country) => {
                                let obj = {
                                    id: country.country_id ? country.country_id : '1',
                                    name: this.commonService.domDirectionLTR ? country.country_en ? country.country_en : '' : country.country_ar ? country.country_ar : '',
                                };
                                this.countryList.push(obj);
                            });
                        }
                    } else {
                        if (data['code'] != 204) {
                            this.toastService.displayToast(data['message'], null, 'danger');
                        }
                    }
                },
                (error) => {
                    this.toastService.displayToast(error['message'], null, 'danger');
                }
            )
        );
    }

    getRegions(id?) {
        const params = {
            dropdown: true,
        };
        if (id) {
            params['countries'] = Number(id);
        }
        this.regionList = [];
        this.subscription.add(
            this.dataService.get(URLS.regionsList, params).subscribe(
                (data) => {
                    if (data['code'] == 200) {
                        this.regionList = [];
                        if (data['data'] && data['data']['result'].length) {
                            this.isRegionDisable = false;
                            let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
                            list.forEach((regions) => {
                                let obj = {
                                    id: regions.region_id ? regions.region_id : '',
                                    name: this.commonService.domDirectionLTR ? regions.region_en ? regions.region_en : '' : regions.region_ar ? regions.region_ar : '',
                                    country_id: regions.country_id ? regions.country_id : 1,
                                };
                                this.regionList.push(obj);
                            });
                        }
                        if (this.regionList.length) {
                            this.warehouseForm.controls.region.enable();

                        } else {
                            this.warehouseForm.controls.city.disable();
                        }
                    } else {
                        if (data['code'] != 204) {
                            this.toastService.displayToast(data['message'], null, 'danger');
                        }
                    }
                },
                (error) => {
                    this.toastService.displayToast(error['message'], null, 'danger');
                }
            )
        );
    }

    getCityList(id?) {
        const params = {
            dropdown: true,
        };
        if (id) {
            params['regions'] = Number(id);
        }
        this.cityList = [];
        this.subscription.add(
            this.dataService.get(URLS.cityList, params).subscribe(
                (data) => {
                    if (data['code'] == 200) {
                        this.cityList = [];
                        if (data['data'] && data['data']['result'].length) {
                            this.isCityDisable = false;
                            let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
                            list.forEach((city) => {
                                let obj = {
                                    id: city.city_id ? city.city_id : '',
                                    name: this.commonService.domDirectionLTR ? city.city_en ? city.city_en : '' : city.city_ar ? city.city_ar : '',
                                    region_id: city.region_id ? city.region_id : '',
                                };
                                this.cityList.push(obj);
                            });
                            if (this.cityList.length) {
                                this.warehouseForm.controls.city.enable();
                            } else {
                                this.warehouseForm.controls.city.disable();
                            }
                        }
                    } else {
                        if (data['code'] != 204) {
                            this.toastService.displayToast(data['message'], null, 'danger');
                        }
                    }
                },
                (error) => {
                    this.toastService.displayToast(error['message'], null, 'danger');
                }
            )
        );
    }

    onRegionChange(value?) {
        if (value) {
            this.warehouseForm.controls.city.reset();
            this.warehouseForm.controls.city.disable();
            this.getCityList(value.id);
        }
    }

    async onCityChange(value?) {
        if (value) {
            this.warehouseForm.controls['region'].setValue(value.region_id);
            for (let n = 0; n < this.cityList.length; n++) {
                for (let k = 0; k < this.regionList.length; k++) {
                    if (this.cityList[n]['region_id'] == this.regionList[k].id) {
                        this.warehouseForm.controls['country'].setValue(this.regionList[k].country_id);
                    }
                }
            }
            await this.getLatLongService.getLatLongLocation(value.name);
        }

    }

    onCountryChange(value: any) {
        if (value.id) {
            this.warehouseForm.controls.region.reset();
            this.warehouseForm.controls.region.disable();
            this.warehouseForm.controls.city.reset();
            this.warehouseForm.controls.city.disable();
            this.getRegions(value.id);
        }
    }

    getWarehouseData(id: any) {
        const params = {
            warehouse_id: Number(id),
        };
        this.subscription.add(
            this.dataService.get(URLS.warehouseDetails, params).subscribe(
                (data) => {
                    if (data['code'] == 200) {
                        if (data['data']) {
                            this.editData = {
                                name: data['data']['warehouse_title_en'],
                                nameAr: data['data']['warehouse_title_ar'],
                                contactName: data['data']['contact_person_name'],
                                mobileNumber: data['data']['contact_person_number'],
                                address: data['data']['warehouse_address_en'],
                                addressAr: data['data']['warehouse_address_ar'],
                                email: data['data']['contact_person_email'],
                                zipcode: data['data']['zipcode'],
                                region: data['data']['region_id'],
                                city: data['data']['city_id'],
                                country: data['data']['country_id'],
                            }
                            this.warehouseForm = this.initializeForm(this.editData);
                        }
                    } else {
                        if (data['code'] != 204) {
                            this.toastService.displayToast(data['message'], null, 'danger');
                        }
                    }
                },
                (error) => {
                    this.toastService.displayToast(error['message'], null, 'danger');
                }
            )
        );
    }

    submit() {
        for (const i in this.warehouseForm.controls) {
            this.warehouseForm.controls[i].markAsPristine();
            this.warehouseForm.controls[i].markAsDirty();
            this.warehouseForm.controls[i].updateValueAndValidity();
            this.warehouseForm.controls[i].markAsTouched();
        }
        if (this.warehouseForm.invalid) {
            return;
        }



        let params = {
            warehouse_title_en: this.warehouseForm.value.warehouseName,
            warehouse_title_ar: this.warehouseForm.value.warehouseNameArabic,
            contact_person_name: this.warehouseForm.value.contactName,
            contact_person_number: this.warehouseForm.value.mobileNumber,
            warehouse_address_en: this.warehouseForm.value.address,
            warehouse_address_ar: this.warehouseForm.value.addressAr,
            warehouse_city_id: this.warehouseForm.value.city,
            zipcode: this.warehouseForm.value.zipcode,
            contact_person_email: this.warehouseForm.value.email,
            latitude: +this.commonService.localStorageGet("latitude"),
            longitude: +this.commonService.localStorageGet("longitude")
        }

        if (this.router.url.includes('edit') && this.warehouseId) {
            params["warehouse_id"] = Number(this.warehouseId);
            this.updateWarehouse(params);
        } else {
            this.addWarehouse(params);
        }
    }

    /////////// Add Warehouse data /////////////
    addWarehouse(params: any) {

        this.isLoading = true;
        this.subscription.add(
            this.dataService.post(URLS.warehouseAdd, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    this.isLoading = false;
                    this.router.navigate(['/account-details/warehouse-management']);
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    /////////// Update Warehouse data /////////////
    updateWarehouse(params: any) {

        this.isLoading = true;
        this.subscription.add(
            this.dataService.put(URLS.warehouseUpdate, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    this.isLoading = false;
                    this.router.navigate(['/account-details/warehouse-management']);
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    cancel() {
        this.router.navigate(['/account-details/warehouse-management']);
    }

    restrictSpecialChar(event) {
        var k;
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

    phoneClick(e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
            e.preventDefault();
        }
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe();
    }


    numberOnlyValidation(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

}
