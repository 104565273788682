import { Injectable } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public toast;
  public toasterCtrl: any;

  msgText: any;
  msgType: any;
  isVisible: boolean = false;
  modalPopupAlert: boolean = false;
  constructor(public toastController: ToastController, public modalCtrl: ModalController, private translate: TranslateService) { }

  async displayToast(message: string, position?: any, color?) {
    this.modalPopupAlert = false
    let isModalOpen;
    this.modalCtrl.getTop().then((data) => {
      isModalOpen = data;
      if (isModalOpen) {
        this.modalPopupAlert = true;
      }
    });

    this.msgText = message;
    this.msgType = color;
    this.isVisible = true;
    setTimeout(() => {
      this.isVisible = false;
      this.modalPopupAlert = false;
    }, 5000)
  }
}
