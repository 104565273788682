import { Component, OnInit, Input, ElementRef, Inject } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { CommonService } from '../../_services/common.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DOCUMENT, DatePipe } from '@angular/common';

@Component({
	selector: 'app-refund',
	templateUrl: './refund.component.html',
	styleUrls: ['./refund.component.scss'],
})
export class RefundComponent implements OnInit {
	currencySymbol: string = "$";
	fromDate: any;
	toDate: any;
	statusSelect: any;
	isLoading: boolean = false;
	showFilter: boolean = false;
	showSearch: boolean = false;
	searchText: any;
	showLoader: boolean = false;
	pageno = 1;
	pagesize = 10;
	filterparams: any;
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' }
	public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

	public count = 0;

	public itemsPerPage = 5;

	public currentPage = 1;

	reviewList = [
		{
			product_id: '10215',
			customer_name: 'Daniel',
			email: 'abc@gmail.com',
			image: '../../../assets/images/product1.png',
			product_name: 'Metal Ceiling CT1782',
			customer_mobile: 9876453210,
			sku: '-',
			quantity: '-',
			order_total: '-',
			refunded_amount: '-',
			type: '-',
			billed_to: '-',
			shipped_to: '-',
			request_created_date: '-',
			reason_of_return: '-',
			review_status: 0,//approved
		},
		{
			product_id: '10216',
			customer_name: 'Daniel',
			email: 'abc@gmail.com',
			image: '../../../assets/images/product1.png',
			product_name: 'Metal Ceiling CT1782',
			customer_mobile: 9876453210,
			sku: '-',
			quantity: '-',
			order_total: '-',
			refunded_amount: '-',
			type: '-',
			billed_to: '-',
			shipped_to: '-',
			request_created_date: '-',
			reason_of_return: '-',
			review_status: 1,//pending
		},
		{
			product_id: '10217',
			customer_name: 'Daniel',
			email: 'abc@gmail.com',
			image: '../../../assets/images/product1.png',
			product_name: 'Metal Ceiling CT1782',
			customer_mobile: 9876453210,
			sku: '-',
			quantity: '-',
			order_total: '-',
			refunded_amount: '-',
			type: '-',
			billed_to: '-',
			shipped_to: '-',
			request_created_date: '-',
			reason_of_return: '-',
			review_status: 2, //cancel
		},
		{
			product_id: '10218',
			customer_name: 'Daniel',
			email: 'abc@gmail.com',
			image: '../../../assets/images/product1.png',
			product_name: 'Metal Ceiling CT1782',
			customer_mobile: 9876453210,
			sku: '-',
			quantity: '-',
			order_total: '-',
			refunded_amount: '-',
			type: '-',
			billed_to: '-',
			shipped_to: '-',
			request_created_date: '-',
			reason_of_return: '-',
			review_status: 0,//approved
		},
	];
	@Input() showHeader: boolean;
	dateRange: FormGroup;
	statusList = [];
	maxFromDate: any;
	minToDate: any;
	todaysDate = new Date();
	maxDate: any;
	statusFilter = 'all';
	constructor(
		private modalService: ModalService,
		public commonService: CommonService,
		private fb: FormBuilder,
		private targetElem: ElementRef,
		private router: Router,
		private datePipe: DatePipe,
		@Inject(DOCUMENT) private document: Document,
	) { }

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		});
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

		this.statusList = [
			{ value: '', label: this.commonService.domDirectionLTR ? 'All' : 'الجميع' },
			{ value: 'APPROVED', label: this.commonService.domDirectionLTR ? 'Approved' : 'وافق' },
			{ value: 'PENDING', label: this.commonService.domDirectionLTR ? 'Pending' : 'قيد الانتظار' },
			{ value: 'REJECTED', label: this.commonService.domDirectionLTR ? 'Rejected' : 'مرفوض' },
		]
		this.searchText = null;
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
	}

	ionViewWillEnter() {
		this.showHeader = true;
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	search() {
	}

	clearSearch() {
		this.searchText = null;
	}

	onChange(event): void {
		this.currentPage = event;
	}

	detailPage(id) {
		this.router.navigate(['account-details/refund-details', id]);
	}

	key: string = "id";
	sortType: string = "ASC";
	onSort(event, key: any) {
		this.key = key;

		const element = document.querySelectorAll('.sorting-icon');
		element.forEach(img => {
			img.setAttribute("src", "assets/icon/sort.svg")
		});

		let eventValue;
		if (event.target.className.includes("column-heading")) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute("data-order")
		if (this.filterparams['sort_by'] != key) {
			order = "asc"
		}
		if (order === "asc") {
			this.sortType = "ASC";
			eventValue.setAttribute("data-order", "desc");
			eventValue.src = "assets/icon/sorting_asc.svg";
		} else if (order === "desc") {
			this.sortType = "DESC";
			eventValue.setAttribute("data-order", "");
			eventValue.src = "assets/icon/sorting_desc.svg";
		} else {
			delete this.filterparams['sort_by'];
			delete this.filterparams['sort_type'];
			eventValue.setAttribute("data-order", "asc");
			eventValue.src = "assets/icon/sort.svg";

		}
		if (order) {
			this.filterparams['sort_by'] = key;
			this.filterparams['sort_type'] = this.sortType;
		}
	}

	applyBtn() {

	}


	clearFilter() {
		this.statusFilter = 'all';
		this.fromDate = null;
		this.toDate = null;
	}
}
