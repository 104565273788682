import { Injectable } from '@angular/core';
import { ModalController, Platform, NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  isDesktop = false
  constructor(private modalCtrl: ModalController,
    private platform: Platform,
    private navCtrl: NavController) {
    this.isDesktop = this.platform.is('desktop')
  }
  async openModal(modalOptions, callback = null) {
    if (!modalOptions) {
      return;
    }
    if (!modalOptions['component']) {
      return;
    }
    modalOptions['componentProps'] = {
      ...modalOptions['componentProps'],
      isDesktop: this.isDesktop
    }
    let obj = {
      componentProps: { isDesktop: this.isDesktop },

      // initialBreakpoint: this.platform.is('desktop') ? null : 0.25,
    }
    if (!this.platform.is('desktop')) {
      obj['initialBreakpoint'] = 0.5
    }
    if (!this.platform.is('desktop') && modalOptions['id'] === "twoFaModal") {
      obj['initialBreakpoint'] = 0;
      obj['backdropBreakpoint'] = 0.5;
      modalOptions['cssClass'] = 'twoFaModal';
    }
    if (this.platform.is('desktop') && modalOptions['id'] === "registerModal") {
      modalOptions['cssClass'] = 'register-Modal';
    }
    const modal = await this.modalCtrl.create(modalOptions);
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    modal.onDidDismiss().then(data => {
      if (data.data && data.data.update)
        callback();
    })

    return modal as any;
  }

  dismissModal(dismissValue = null) {
    this.modalCtrl.dismiss(dismissValue).catch(err => {
      this.navCtrl.back()
    })
  }

}
