import { Component, OnInit, Inject, Input } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../_services/common.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../_services/authentication.service';
import { ToastService } from '../../_services/toast.service';
import { AlertController } from '@ionic/angular';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-account-menu',
  templateUrl: './my-account-menu.component.html',
  styleUrls: ['./my-account-menu.component.scss'],
})
export class MyAccountMenuComponent implements OnInit {
  pageOffsetHeight: any;
  profileData: any;
  public subscription = new Subscription();
  @Input() isMobileSideMenu = false;
  connectLabel = "Connect to your account";
  useremail = " daniel.stevenson@gmail.com";
  username = " Daniel Steveson";
  connectDescription = " Dui sit tellus proin a faucibus vel viverra";
  mobileView = false;
  buttonLabel = {
    register: 'Register',
    login: 'Login'
  }
  menuOptions = [
    {
      menu_name: 'accountMenuComponent.dashboard',
      route: '/account-details/marketplace-dashboard',
      is_enable: true,
      icon_url: './assets/icon/myAccountDetails.svg',
      id: 1
    },
    {
      menu_name: 'accountMenuComponent.myProfile',
      route: '/account-details/my-profile',
      is_enable: true,
      icon_url: './assets/icon/myAccountDetails.svg',
      id: 2
    },
    {
      menu_name: 'accountMenuComponent.brandManagement',
      route: '/account-details/brand-management',
      is_enable: true,
      icon_url: './assets/icon/myAccountDetails.svg',
      id: 3
    },
    {
      menu_name: 'accountMenuComponent.addNewProduct',
      route: '/account-details/add-new-product',
      is_enable: false,
      icon_url: './assets/icon/myAccountDetails.svg',
      id: 4
    },
    {
      menu_name: 'accountMenuComponent.warehouseManagement',
      route: "/account-details/warehouse-management",
      is_enable: true,
      icon_url: '',
      id: 19
    },
    {
      menu_name: 'accountMenuComponent.productManagement',
      route: '/account-details/products-management',
      is_enable: true,
      icon_url: '',
      id: 5
    },
    {
      menu_name: 'accountMenuComponent.inventoryManagement',
      route: '/account-details/inventory-management',
      is_enable: true,
      icon_url: '',
      id: 6
    },
    {
      menu_name: 'accountMenuComponent.featuredProductManagement',
      route: '/account-details/features-products-management',
      is_enable: true,
      icon_url: '',
      id: 7
    },
    {
      menu_name: 'accountMenuComponent.productDiscountManagement',
      route: '/account-details/product-discount-management',
      is_enable: true,
      icon_url: '',
      id: 8
    },
    {
      menu_name: 'accountMenuComponent.couponManagement',
      route: '/account-details/coupon-management',
      is_enable: true,
      icon_url: '',
      id: 9
    },
    {
      menu_name: 'accountMenuComponent.ordersManagement',
      route: '/account-details/orders-management',
      is_enable: true,
      icon_url: '',
      id: 10
    },
    {
      menu_name: 'accountMenuComponent.cancelOrdersManagement',
      route: '/account-details/cancel-orders-management',
      is_enable: true,
      icon_url: '',
      id: 11
    },
    {
      menu_name: 'accountMenuComponent.returnOrdersManagement',
      route: '/account-details/return-orders-management',
      is_enable: true,
      icon_url: '',
      id: 12
    },
    {
      menu_name: 'accountMenuComponent.productReviews',
      route: '/account-details/product-reviews',
      is_enable: true,
      icon_url: '',
      id: 13
    },
    {
      menu_name: 'accountMenuComponent.transactionManagement',
      route: '/account-details/my-transaction-list',
      is_enable: true,
      icon_url: './assets/icon/houseIcon.svg',
      id: 14
    },
    {
      menu_name: 'accountMenuComponent.earnings',
      route: "/account-details/marketplace-dashboard/earnings",
      is_enable: true,
      icon_url: './assets/icon/creditCardIcon.svg',
      id: 15
    },
    {
      menu_name: 'accountMenuComponent.payoutHistory',
      route: '/account-details/marketplace-dashboard/payout-history',
      is_enable: false,
      icon_url: '',
      id: 16
    },
    {
      menu_name: 'accountMenuComponent.notificationManagement',
      route: '/account-details/notification-management',
      is_enable: true,
      icon_url: './assets/icon/settingIcon.svg',
      id: 17
    },
    {
      menu_name: 'accountMenuComponent.refund',
      route: "/account-details/refund",
      is_enable: false,
      icon_url: './assets/icon/settingIcon.svg',
      id: 18
    },
    {
      menu_name: 'accountMenuComponent.settings',
      route: "/account-details/settings",
      is_enable: true,
      icon_url: '',
      id: 20
    },
  ];
  constructor(private modalService: ModalService,
    public commonService: CommonService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private router: Router,
    private alertController: AlertController,
    private dataService: ApiService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    this.commonService.eventObservable.subscribe(data => {
      this.profileData = this.commonService.profileData;
    })
    this.menuClick(this.router.url);
    this.subscription = new Subscription();
    this.getProfileData();
  }

  ionViewWillEnter() {
  }

  menuClick(routeUrl) {
    var pageHeight;
    setTimeout(() => {
      if (routeUrl.includes("marketplace-dashboard")) {
        pageHeight = this.document.getElementsByClassName(
          'marketplace-dashboard-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("my-profile")) {
        pageHeight = this.document.getElementsByClassName(
          'my-profile-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("brand-management")) {
        pageHeight = this.document.getElementsByClassName(
          'brand-management-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("new-products")) {
        pageHeight = this.document.getElementsByClassName(
          'new-products-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("my-products-list")) {
        pageHeight = this.document.getElementsByClassName(
          'products-list-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("my-transaction-list")) {
        pageHeight = this.document.getElementsByClassName(
          'my-transaction-list-contaner',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("earnings")) {
        pageHeight = this.document.getElementsByClassName(
          'earnings-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("customers")) {
        pageHeight = this.document.getElementsByClassName(
          'customers-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("notification-management")) {
        pageHeight = this.document.getElementsByClassName(
          'notification-management-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (routeUrl.includes("review")) {
        pageHeight = this.document.getElementsByClassName(
          'review-container',
        ) as HTMLCollectionOf<HTMLElement>;
      }

      if (pageHeight) {
        if (pageHeight[0]) {
          this.pageOffsetHeight = pageHeight[0].offsetHeight;
        }
      } else {
        this.pageOffsetHeight = 0;
      }

      const setMenuHeight = this.document.getElementsByClassName(
        'side-menu',
      ) as HTMLCollectionOf<HTMLElement>;

      if (setMenuHeight[0]) {
        setMenuHeight[0].style.minHeight = this.pageOffsetHeight + "px";
      }
    }, 1000);
  }

  getProfileData() {
    let otpVerified = localStorage.getItem('OTP_Verified');
    let token = localStorage.getItem('accessToken');
    if (otpVerified == 'true' && token) {
      this.subscription.add(
        this.dataService.get(URLS.supplierDetails).subscribe(
          (data) => {
            if (data['code'] == 200) {
              if (data['data']) {
                this.profileData = {
                  name: this.commonService.domDirectionLTR ? data['data']['supplier_name_en'] ? data['data']['supplier_name_en'] : '' : data['data']['supplier_name_ar'] ? data['data']['supplier_name_ar'] : '',
                  companyLogoUrl: data['data']['supplier_logo_url'],
                  companyName: this.commonService.domDirectionLTR ? data['data']['supplier_legal_name_en'] ? data['data']['supplier_legal_name_en'] : '' : data['data']['supplier_legal_name_ar'] ? data['data']['supplier_legal_name_ar'] : '',
                }
                this.commonService.profileData = this.profileData
              }
            } else {
              if (data['code'] != 204) {
                this.toastService.displayToast(data['message'], null, 'danger');
              }
            }
          },
          (error) => {
            this.toastService.displayToast(error['message'], null, 'danger');
          }
        )
      );
    }
  }

  goBack() {
    this.modalService.dismissModal()
  }

  openModal(id) {
    let options: any = {
      componentProps: { mobileView: true },
      id: "addressModal"
    };
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
      message: this.commonService.domDirectionLTR ? 'You want to logout?' : 'تريد الخروج؟',
      buttons: [
        {
          text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
          role: 'confirm',
          handler: () => {
            this.logout();
          },
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
  }

  logout() {
    this.authService.logout().subscribe(data => {
      localStorage.clear();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
      if (data['code'] != 200 && data['data']) {
        this.toastService.displayToast(data['message'], null, 'danger');
      } 
    }, error => {
      this.toastService.displayToast(error['message'], null, 'danger');
      localStorage.clear();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    });
  }
}
