import { EventEmitter, Inject, Injectable, Output, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { SECRET_KEY } from '../_config/api.config';
import { ApprovalWaitingComponent } from '../components/approval-waiting/approval-waiting.component';
import { AuthenticationService } from './authentication.service';
import { ModalService } from './modal.service';
import { Router } from '@angular/router';
import { URLS } from '../_config/api.config';
import { ApiService } from '../_services/api-data.service';
import { Subscription } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import * as CryptoJS from 'crypto-js';
declare var forge: any;
var CryptoJS = require("crypto-js");

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  domDirectionLTR = true;
  accessToken: string;
  isWebMenuOpen = true;
  currencySymbol: string = "SR";
  profileData: any = {};
  //For Add new product Steps
  public eventObservable = new Subject<any>();
  public event: any = {};
  public subscription = new Subscription();
  twoFaEnabled = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthenticationService,
    private modalService: ModalService,
    private router: Router,
    private dataService: ApiService,
  ) { }

  public encryptData(data: any) {
    try {
      var encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY);
      return encryptedData.toString();
    } catch (e) {
      console.log(e);
    }
  }

  public decryptData(encryptedData: string) {
    if (!encryptedData) {
      return null;
    }
    try {
      var bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
      var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      return decryptedData;
    } catch (e) {
      console.log(e);
    }
  }
  //For Add new product Steps
  public setEvent(event: string, data: any) {
    this.event = { 'event': event, 'data': data };
    this.eventObservable.next(this.event);
  }

  localStorageSet(key: string, value: any) {
    if (!isPlatformServer(this.platformId)) {
      localStorage.setItem(key, value);
    }
  }

  localStorageGet(key) {
    if (!isPlatformServer(this.platformId)) {
      return localStorage.getItem(key);
    }
    return null;
  }

  localStorageRemove(key) {
    if (!isPlatformServer(this.platformId)) {
      localStorage.removeItem(key);
    }
  }

  localStorageClear() {
    if (!isPlatformServer(this.platformId)) {
      localStorage.clear();
    }
  }

  approvalCheck() {
    let obj = {
      component: ApprovalWaitingComponent,
      backdropDismiss: false,
      canDismiss: false,
      cssClass: 'approval-modal'
    };
    const modal = this.modalService.openModal(obj);

    modal.then(value => {
      this.logout();
    }, error => {
      console.log(error);
    });
  }

  logout() {
    this.authService.logout().subscribe(data => {
      localStorage.clear();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }, error => {
      localStorage.clear();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    });
  }

  getProfileData() {
    this.subscription.add(
      this.dataService.get(URLS.supplierDetails).subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data']) {
              this.profileData = {
                name: this.domDirectionLTR ? data['data']['supplier_name_en'] ? data['data']['supplier_name_en'] : '' : data['data']['supplier_name_ar'] ? data['data']['supplier_name_ar'] : '',
                companyLogoUrl: data['data']['supplier_logo_url'],
                companyName: this.domDirectionLTR ? data['data']['supplier_legal_name_en'] ? data['data']['supplier_legal_name_en'] : '' : data['data']['supplier_legal_name_ar'] ? data['data']['supplier_legal_name_ar'] : '',
              }
              this.setEvent('userchange', this.profileData)
            }
          } else {
            this.setEvent('userchange', this.profileData)
          }
        },
        (error) => {
          this.setEvent('userchange', this.profileData)
        }
      )
    );
  }
}
