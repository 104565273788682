import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidator } from '../../_common/customvalidators';
import { Subscription } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalController, AlertController } from '@ionic/angular';
import { termsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { ToastService } from '../../_services/toast.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  public registrationForm: FormGroup;
  public subscription = new Subscription();
  isLoading = false;
  passwordType = 'password';
  confirmPasswordType = 'password';

  public validation_messages = {
    name: [
      { type: 'required', message: 'registrationComponent.nameRequired' },
      { type: 'invalidString', message: 'registrationComponent.invalidName' },
      { type: 'maxlength', message: 'registrationComponent.nameMaxLength' },
    ],
    nameArabic: [
      { type: 'required', message: 'registrationComponent.nameRequiredArabic' },
      { type: 'invalidName', message: 'registrationComponent.invalidNameArabic' },
      { type: 'maxlength', message: 'registrationComponent.nameMaxLengthArabic' },
    ],
    email: [
      { type: 'required', message: 'registrationComponent.emailRequired' },
      { type: 'invalidEmail', message: 'registrationComponent.invalidEmail' },
    ],
    phone_no: [
      { type: 'required', message: 'registrationComponent.phoneNoRequired' },
      { type: 'invalidNumber', message: 'registrationComponent.invalidPhoneNo' }
    ],
    password: [
      { type: 'required', message: 'registrationComponent.passwordRequired' },
      { type: 'invalidPassword', message: 'registrationComponent.invalidPassword' },
    ],
    confirm_password: [
      { type: 'required', message: 'registrationComponent.confirmPasswordRequired' },
      { type: 'passMismatch', message: 'registrationComponent.passMismatch' },
    ],
    termsCondition: [
      { type: 'required', message: 'registrationComponent.termConditionMsg' },
    ],

  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public apiService: ApiService,
    private modalCtrl: ModalController,
    public commonService: CommonService,
    private dataService: ApiService,
    private toastService: ToastService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    const element = this.document.querySelector('#scrollId');
    if (element) {
      element.scrollIntoView();
    }
    this.registrationForm = this.initializeForm();
  }

  ionViewWillEnter() {
    this.subscription = new Subscription();
    this.registrationForm = this.initializeForm();
  }

  initializeForm() {
    return this.formBuilder.group({
      name: new FormControl(null, {
        validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.noWhiteSpaceValidator]),
      }),
      nameArabic: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.arabicNameValidator]),
      }),
      email: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.emailValidator]),
      }),
      phone_no: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.phoneValidator]),
      }),
      password: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
      }),
      confirm_password: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.confirmPassword]),
      }),
      termsCondition: new FormControl(null, {
        validators: Validators.compose([Validators.requiredTrue]),
      }),
    });
  }

  phoneClick(e) {
    if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault();
    }
  }

  onSubmit() {

    for (const i in this.registrationForm.controls) {
      this.registrationForm.controls[i].markAsDirty();
      this.registrationForm.controls[i].updateValueAndValidity();
      this.registrationForm.controls[i].markAsTouched();
    }
    if (this.registrationForm.invalid) {
      return
    }

    const params = {
      "supplier_name_en": this.registrationForm.controls.name.value,
      "supplier_name_ar": this.registrationForm.controls.nameArabic.value,
      "supplier_email": this.registrationForm.controls.email.value,
      "supplier_mobile": this.registrationForm.controls.phone_no.value,
      "password": this.registrationForm.controls.password.value,
    }

    const encryptedData = this.commonService.encryptData(JSON.stringify(params));

    localStorage.setItem('registration_data', encryptedData);
    this.getOtp();

  }

  async termsConditions(event?) {

    event.preventDefault();
    event.stopPropagation();
    const modal = await this.modalCtrl.create({
      component: termsConditionsComponent,
      cssClass: 'terms-conditions-modal',
    });
    modal.present();

    let dataOfModal: any = await modal.onDidDismiss();
    if (dataOfModal.data.data) {
      this.registrationForm.controls['termsCondition'].setValue(true);
    } else {
      if (this.registrationForm.controls['termsCondition'].value) {
        this.registrationForm.controls['termsCondition'].setValue(true);
      } else {
        this.registrationForm.controls['termsCondition'].setValue(false);
      }
    }
  }

  cancel() {
    this.registrationForm.reset();
    this.router.navigate(['/login'])
  }

  restrictSpecialChar(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || k == 39 || (k >= 48 && k <= 57));
  }

  getOtp() {
    if (this.isLoading) {
      return;
    }
    let userInfo = this.commonService.decryptData(localStorage.getItem('userInfo'));
    userInfo = JSON.parse(userInfo);
    const params = {
      "supplier_email": this.registrationForm.controls.email.value,
      "supplier_mobile": this.registrationForm.controls.phone_no.value,
      "purpose_type": "REGISTRATION",
    }

    const navigationExtras: NavigationExtras = {
      queryParams: {
        from: 'registration',
      },
    };
    this.isLoading = true;
    this.subscription.add(
      this.dataService.post(URLS.generateOtp, params).subscribe(data => {
        if (data['code'] == 200) {
          if (this.registrationForm.controls.email.value) {
            localStorage.setItem('user_name', this.registrationForm.controls.email.value);
          }
          if (this.registrationForm.controls.phone_no.value) {
            localStorage.setItem('mobile_no', this.registrationForm.controls.phone_no.value);
          }
          this.registrationForm.reset();
          this.router.navigate(['/two-factor-authentication'], navigationExtras);
          if (data['data']) {
            if (data['data']['uuid']) {
              localStorage.setItem('uuid', data['data']['uuid']);
            }
          }
        } else {
          this.toastService.displayToast(data['message'], null, 'danger');
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.toastService.displayToast(error['message'], null, 'danger');
      })
    )
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }
}
