import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../_services/common.service';
import { GetPlatformService } from '../../_services/get-platform.service';
import { ApiService } from '../../_services/api-data.service';
import { ToastService } from '../../_services/toast.service';

@Component({
    selector: 'app-toast-msg',
    templateUrl: './toast-msg.component.html',
    styleUrls: ['./toast-msg.component.scss'],
})
export class ToastMsgComponent implements OnInit {

    constructor(private modalService: ModalService,
        public commonService: CommonService,
        public getPlatformService: GetPlatformService,
        private dataService: ApiService,
        private translate: TranslateService,
        private alertController: AlertController,
        public navCtrl: NavController,
        public toastService: ToastService,
        private router: Router) {

    }

    ngOnInit() {

    }



    toastClose() {
        this.toastService.isVisible = false;
        this.toastService.modalPopupAlert = false
    }

}
