import { Component, OnInit, Input, Inject } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../../_services/api-data.service';
import { URLS } from '../../_config/api.config';
import { Subscription } from 'rxjs';
import { ToastService } from '../../_services/toast.service';

@Component({
  selector: 'app-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.scss'],
})
export class NotificationManagementComponent implements OnInit {
  currencySymbol: string = "$";
  showFilter: boolean = false;
  notificationArray: any = [];
  array: any = [];
  enableNext = false;
  pageNo = 1;
  pageSize = '10';
  count = 0;
  perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
  private subscription: Subscription = new Subscription();
  categoryId: any;
  @Input() showHeader: boolean;
  constructor(
    public commonService: CommonService,
    private router: Router,
    public route: ActivatedRoute,
    private dataService: ApiService,
    private toastService: ToastService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    this.scrollTop();
    this.categoryId = this.route.snapshot.paramMap.get('categoryId');
    this.showHeader = true;
    this.getNotifications()
  }

  scrollTop() {
    const element = this.document.querySelector('#scrollId');
    if (element) {
      element.scrollIntoView();
    }
  }

  ionViewWillEnter() {
    this.showHeader = true;
  }

  openFilters() {
    this.showFilter = !this.showFilter;
  }

  onChange(event): void {
    this.pageNo = event;
    this.getNotifications();
  }

  selectPageLength(event) {
    this.pageSize = event.detail.value;
    this.pageNo = 1;
    this.getNotifications();
  }

  getNotifications(infiniteScroll?) {
    const params = {
      page: this.pageNo,
      limit: +this.pageSize,
      notification_type_id: +this.categoryId
    }
    return new Promise((resolve, reject) => {
      this.subscription.add(
        this.dataService.get(URLS.notificationList, params).subscribe(data => {
          if (data && data['code'] == 200) {
            this.enableNext = data['data']['enable_next'] ? true : false;
            this.count = data['data'] && data['data']['total_count'] ? +data['data']['total_count'] : 0;
            let list = data['data'] && data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
            if (!infiniteScroll) {
              this.notificationArray = [];
            }
            list.forEach(element => {
              let obj = {
                title: element.notification_en ? element.notification_en : '',
                titleAr: element.notification_ar ? element.notification_ar : '',
                category: element.notification_type_en ? element.notification_type_en : '',
                categoryAR: element.notification_type_ar ? element.notification_type_ar : '',
                notification_desc_en: element.notification_desc_en ? element.notification_desc_en : '',
                notification_desc_ar: element.notification_desc_ar ? element.notification_desc_ar : '',
                notification_image_url_ar: element.notification_image_url_ar ? element.notification_image_url_ar : '',
                notification_image_url_en: element.notification_image_url_en ? element.notification_image_url_en : '',
                time: element.created_at ? element.created_at : '',
                supplier_notification_id: element.supplier_notification_id,
                isRead: element.is_read ? true : false,
                notification_type_id: element.notification_type_id,
                reference_type: element.reference_type ? element.reference_type : '',
                reference_id: element.reference_id ? element.reference_id : '',
              }
              this.notificationArray.push(obj);
            });
            this.array = this.notificationArray;
            this.scrollTop();
            resolve(this.array)
          } else {
            reject();
          }
        }, error => {
          reject();
        })
      )
    })
  }

  readNotification(notification) {
    if (notification.isRead) {
      this.redirectTo(notification)
      return
    }
    const params = {
      "supplier_notification_id": notification.supplier_notification_id,
      "is_read": true
    }
    this.subscription.add(
      this.dataService.put(URLS.updateNotificationStatus, params).subscribe(data => {
        if (data['code'] === 200) {
          notification.isRead = true;
          this.redirectTo(notification)
        } else {
          this.redirectTo(notification)
        }
      }, error => {
        this.redirectTo(notification)
      })
    )

  }

  redirectTo(notification) {
    switch (notification.reference_type) {
      case "Product Status":
        this.router.navigate(['/account-details/products-management/view-product', notification.reference_id])
        break;
      case "Brand Status":
        this.router.navigate(['/account-details/brand-management'])
        break;
      case "Coupons":
        this.router.navigate(['account-details/coupon-management/coupon-details', notification.reference_id])
        break;
      case "New Orders":
        this.router.navigate(['/account-details/orders-management/order-details', notification.reference_id])
        break;
      case "Return Orders":
        this.router.navigate(['/account-details/return-orders-management'])
        break;
      case "Cancel Orders":
        this.router.navigate(['/account-details/cancel-orders-management'])
        break;
      case "Account Information":
        this.router.navigate(['/account-details/my-profile'])
        break;
      case "Product Stock Status":
        break;
      case "News/Events/Announcements from Juman":
        break;
      default:
        break;
    }
  }
}
