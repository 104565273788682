import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
// import { FilterComponent } from '../filter/filter.component';
import { CommonService } from '../../_services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  currencySymbol: string = "$";
  columnHeaders = {
    order_id: 'Order #',
    date: 'Date',
    ship_to: 'Ship to',
    order_total: 'Order total',
    status: 'Status',

  }
  orderList = [
    {
      order_id: '12910212',
      date: 'Oct 10, 2021',
      ship_to: '128 Melody street, New York, N...',
      order_total: '1890',
      order_status: 0,//pending
    },
    {
      order_id: '12910212',
      date: 'Oct 10, 2021',
      ship_to: '128 Melody street, New York, N...',
      order_total: '1890',
      order_status: 1,//pending
    },
    {
      order_id: '12910212',
      date: 'Oct 10, 2021',
      ship_to: '128 Melody street, New York, N...',
      order_total: '1890',
      order_status: 2,//canceled
    },
    {
      order_id: '12910212',
      date: 'Oct 10, 2021',
      ship_to: '128 Melody street, New York, N...',
      order_total: '1890',
      order_status: 0,//pending
    },
    // {
    //   order_id: '12910212',
    //   date: 'Oct 10, 2021',
    //   ship_to: '128 Melody street, New York, N...',
    //   order_total: '1890',
    //   order_status: 1,//pending
    // },
    // {
    //   order_id: '12910212',
    //   date: 'Oct 10, 2021',
    //   ship_to: '128 Melody street, New York, N...',
    //   order_total: '1890',
    //   order_status: 2,//canceled
    // },
  ];
  @Input() showHeader: boolean;
  constructor(
    private modalService: ModalService,
    public commonService: CommonService,
    private router: Router) { }

  ngOnInit() { }

  ionViewWillEnter() {
    //if (this.router.url.includes("my-products-list")) {
    this.showHeader = true;
    //}

  }

  openFilters() {
    // const obj = {
    //   component: FilterComponent,
    //   componentProps: { title: 'Filters' },
    //   id: 'filterModal'
    // }
    // this.modalService.openModal(obj)
  }

}
