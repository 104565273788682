import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { Router } from '@angular/router';
import { ModalService } from '../../_services/modal.service';
import { ModalController } from '@ionic/angular';
import { PayoutHistoryDetailsComponent } from '../payoutHistoryDetails/payoutHistoryDetails.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-payout-history',
  templateUrl: './payout-history.component.html',
  styleUrls: ['./payout-history.component.scss'],
})
export class PayoutHistoryComponent implements OnInit {

  showSearch = false;
  sortType: string = "ASC";
  pageno = 1;
  pagesize = 10;
  filterparams: any = {}
  public count = 0;
  public itemsPerPage = 5;
  public currentPage = 1;
  public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
  perPageInterface = { cssClass: 'custom-select-dd per-page-dd' }
  tableData = [
    {
      order_id: "1",
      orderNumber: "10212",
      TotalTransaction: "6,000",
      totalAmount: "6,000",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 0,
    },
    {
      order_id: "2",
      orderNumber: "10213",
      TotalTransaction: "6,001",
      totalAmount: "6,001",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 1,
    },
    {
      order_id: "3",
      orderNumber: "10214",
      TotalTransaction: "6,002",
      totalAmount: "6,002",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 2,
    },
    {
      order_id: "4",
      orderNumber: "10215",
      TotalTransaction: "6,003",
      totalAmount: "6,003",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 0,
    },
    {
      order_id: "5",
      orderNumber: "10212",
      TotalTransaction: "6,000",
      totalAmount: "6,000",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 1,
    },
    {
      order_id: "6",
      orderNumber: "10212",
      TotalTransaction: "6,000",
      totalAmount: "6,000",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 2,
    },
    {
      order_id: "7",
      orderNumber: "10212",
      TotalTransaction: "6,000",
      totalAmount: "6,000",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 0,
    },
    {
      order_id: "8",
      orderNumber: "10212",
      TotalTransaction: "6,000",
      totalAmount: "6,000",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 1,
    },
    {
      order_id: "9",
      orderNumber: "10212",
      TotalTransaction: "6,000",
      totalAmount: "6,000",
      paymentsDate: "Aug 1, 2022",
      payoutMode: "Bank transfer",
      status: 2,
    }
  ];
  constructor(
    private modalService: ModalService,
    public commonService: CommonService,
    private router: Router,
    private modalCtrl: ModalController,
    private targetElem: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    const element = this.document.querySelector('#scrollId');
    if (element) {
      element.scrollIntoView();
    }
    this.filterparams = {
      page: this.pageno,
      size: this.pagesize
    }
  }

  async details(data) {
    const modal = await this.modalCtrl.create({
      component: PayoutHistoryDetailsComponent,
      cssClass: 'payoutHistoryDetails-modal',
      componentProps: { payoutHistoryData: data ? data : '' }
    });
    modal.present();
  }

  onChange(event): void {
    this.currentPage = event;
  }

  key: string = "id";
  reverse: boolean = false;
  onSort(event, key: any) {
    this.key = key;

    const element = document.querySelectorAll('.sorting-icon');
    element.forEach(img => {
      img.setAttribute("src", "assets/icon/sort.svg")
    });

    let eventValue;
    if (event.target.className.includes("column-heading")) {
      eventValue = event.target.children[0];
    } else {
      eventValue = event.srcElement;
    }
    let order = eventValue.getAttribute("data-order")
    if (this.filterparams['sort_by'] != key) {
      order = "asc"
    }
    if (order === "asc") {
      this.sortType = "ASC";
      eventValue.setAttribute("data-order", "desc");
      eventValue.src = "assets/icon/sorting_asc.svg";
    } else if (order === "desc") {
      this.sortType = "DESC";
      eventValue.setAttribute("data-order", "");
      eventValue.src = "assets/icon/sorting_desc.svg";
    } else {
      delete this.filterparams['sort_by'];
      delete this.filterparams['sort_type'];
      eventValue.setAttribute("data-order", "asc");
      eventValue.src = "assets/icon/sort.svg";

    }
    if (order) {
      this.filterparams['sort_by'] = key;
      this.filterparams['sort_type'] = this.sortType;
    }
  }

}
