import { AgmCoreModule } from '@agm/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CodeInputModule } from 'angular-code-input';
import { StarRatingModule } from 'ionic5-star-rating';
import { NgChartsModule } from 'ng2-charts';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { TwoFAComponent } from '../components/2fa/2fa.component';
import { addDiscountComponent } from '../components/add-discount/add-discount.component';
import { AddEditWarehouseComponent } from '../components/add-edit-warehouse/add-edit-warehouse.component';
import { AddFeaturedProductComponent } from '../components/add-featured-product/add-featured-product.component';
import { ApprovalWaitingComponent } from '../components/approval-waiting/approval-waiting.component';
import { BrandManagementComponent } from '../components/brand-management/brand-management.component';
import { CancelOrdersManagementComponent } from '../components/cancel-orders-management/cancel-orders-management.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { CompanyDetailsComponent } from '../components/company-details/company-details.component';
import { CouponDetailsComponent } from '../components/coupon-details/coupon-details.component';
import { CouponManagementComponent } from '../components/coupon-management/coupon-management.component';
import { CustomersComponent } from '../components/customers/customers.component';
import { DetailsComponent } from '../components/details/details.component';
import { EarningsComponent } from '../components/earnings/earnings.component';
import { FeaturedProductManagementComponent } from '../components/featured-product-management/featured-product-management.component';
import { FooterComponent } from '../components/footer/footer.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { HeaderComponent } from '../components/header/header.component';
import { InventoryManagementComponent } from '../components/inventory-management/inventory-management.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { LoginComponent } from '../components/login/login.component';
import { MarketplaceDashboardComponent } from '../components/marketplace-dashboard/marketplace-dashboard.component';
import { MassUploadComponent } from '../components/mass-upload/mass-upload.component';
import { MenusComponent } from '../components/menus/menus.component';
import { MyAccountMenuComponent } from '../components/my-account-menu/my-account-menu.component';
import { MyProfileComponent } from '../components/my-profile/my-profile.component';
import { MyTransactionListComponent } from '../components/my-transaction-list/my-transaction-list.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { NotificationCategoryComponent } from '../components/notification-category/notification-category.component';
import { NotificationManagementComponent } from '../components/notification-management/notification-management.component';
import { OrderDetailsComponent } from '../components/order-details/order-details.component';
import { OrderSummaryComponent } from '../components/order-summary/order-summary.component';
import { OrdersManagementComponent } from '../components/orders-management/orders-management.component';
import { PayoutHistoryComponent } from '../components/payout-history/payout-history.component';
import { PayoutHistoryDetailsComponent } from '../components/payoutHistoryDetails/payoutHistoryDetails.component';
import { ProductCardComponent } from '../components/product-card/product-card.component';
import { ProductConfigurationComponent } from '../components/product-configuration/product-configuration.component';
import { ProductDetailComponent } from '../components/product-detail/product-detail.component';
import { ProductDiscountDetailsComponent } from '../components/product-discount-details/product-discount-details.component';
import { ProductDiscountManagementComponent } from '../components/product-discount-management/product-discount-management.component';
import { ProductPreviewComponent } from '../components/product-preview/product-preview.component';
import { ProductReviewsComponent } from '../components/product-reviews/product-reviews.component';
import { ProductsManagementComponent } from '../components/products-management/products-management.component';
import { RefundDetailsComponent } from '../components/refund-details/refund-details.component';
import { RefundComponent } from '../components/refund/refund.component';
import { RegistrationComponent } from '../components/registration/registration.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { ChangeReturnCancelStatusComponent } from '../components/return-cancel-status/return-cancel-status.component';
import { ReturnOrdersManagementComponent } from '../components/return-orders-management/return-orders-management.component';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { termsConditionsComponent } from '../components/terms-conditions/terms-conditions.component';
import { ToastMsgComponent } from '../components/toast-msg/toast-msg.component';
import { TwoFAToggleComponent } from '../components/two-fa-toggle/two-fa-toggle.component';
import { UploadImageComponent } from '../components/upload-image/upload-image.component';
import { ViewProductReviewComponent } from '../components/view-product-review/view-product-review.component';
import { WarehouseManagementComponent } from '../components/warehouse-management/warehouse-management.component';
import { AddNewProductPage } from '../pages/add-new-product/add-new-product.page';
import { CommonPipe } from '../_pipes/common.pipe';
import { ProductPreviewDetailComponent } from './../components/product-preview-detail/product-preview-detail.component';
import { TemplateDownloadComponent } from './../components/template-download/template-download.component';
import { CapitalizeFirstDirective } from './capitalize-first.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { PreventNumberInputScrollDirective } from './input-scroll-disable.directive';

const COMPONENTS = [
	NotificationCategoryComponent,
	ToastMsgComponent,
	LoginComponent,
	RegistrationComponent,
	BrandManagementComponent,
	DetailsComponent,
	NotFoundComponent,
	TwoFAComponent,
	CompanyDetailsComponent,
	ForgotPasswordComponent,
	ResetPasswordComponent,
	ProductsManagementComponent,
	FeaturedProductManagementComponent,
	OrdersManagementComponent,
	HeaderComponent,
	ProductReviewsComponent,
	CouponManagementComponent,
	CouponDetailsComponent,
	ProductDiscountDetailsComponent,
	ViewProductReviewComponent,
	RefundComponent,
	RefundDetailsComponent,
	MenusComponent,
	MyAccountMenuComponent,
	FooterComponent,
	MarketplaceDashboardComponent,
	MyProfileComponent,
	BrandManagementComponent,
	AddNewProductPage,
	SearchBarComponent,
	MyTransactionListComponent,
	EarningsComponent,
	CustomersComponent,
	NotificationManagementComponent,
	OrderDetailsComponent,
	OrderSummaryComponent,
	ProductDetailComponent,
	ProductConfigurationComponent,
	ProductPreviewComponent,
	UploadImageComponent,
	MassUploadComponent,
	TemplateDownloadComponent,
	ProductCardComponent,
	InventoryManagementComponent,
	CancelOrdersManagementComponent,
	ReturnOrdersManagementComponent,
	ApprovalWaitingComponent,
	PayoutHistoryComponent,
	PayoutHistoryDetailsComponent,
	ProductDiscountManagementComponent,
	addDiscountComponent,
	AddFeaturedProductComponent,
	WarehouseManagementComponent,
	AddEditWarehouseComponent,
	termsConditionsComponent,
	ProductPreviewDetailComponent,
	ChangePasswordComponent,
	ClickOutsideDirective,
	SettingsComponent,
	CapitalizeFirstDirective,
	TwoFAToggleComponent,
	LoaderComponent,
	ChangeReturnCancelStatusComponent

];


const MODULES = [
	CommonModule,
	IonicModule,
	RouterModule,
	FormsModule,
	StarRatingModule,
	ReactiveFormsModule,
	CodeInputModule,
	TranslateModule,
	NgxPaginationModule,
	NgxFileDropModule,
	AgmCoreModule.forRoot({
		apiKey: 'AIzaSyDHvKG_qCt_sT-rYBPzzIvCYy3BndUs8Ro',
		libraries: ['places']
	}),
	NgChartsModule,
	CKEditorModule,
	NgSelectModule,
	EditorModule
];

const PIPES = [];
@NgModule({
	imports: [...MODULES],
	declarations: [
		...COMPONENTS,
		...PIPES,
		CommonPipe,
		PreventNumberInputScrollDirective,
	],
	entryComponents: [...COMPONENTS, ...PIPES],
	exports: [...MODULES, ...COMPONENTS, ...PIPES, CommonPipe],
	providers: [NativeGeocoder, DatePipe],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
