import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { CustomValidator } from '../../_common/customvalidators';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { ToastService } from '../../_services/toast.service';
import { ModalController, AlertController } from '@ionic/angular';
import { termsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { DOCUMENT } from '@angular/common';
import { ApprovalWaitingComponent } from '../../components/approval-waiting/approval-waiting.component';
import { ModalService } from '../../_services/modal.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
  @ViewChild('companyLogoNameElem') companyLogoNameElem: ElementRef;
  @ViewChild('companyCrNoElem') companyCrNoElem: ElementRef;
  @ViewChild('vatCertificateNoElem') vatCertificateNoElem: ElementRef;
  @ViewChild('brandLogoElem') brandLogoElem: ElementRef;
  @ViewChild('nationalAddressFileElem') nationalAddressFileElem: ElementRef;

  public detailsForm: FormGroup;
  public subscription = new Subscription();
  public message: any;
  public fileToUpload: any
  isMainBrand = 0;

  disableBtn = false;
  isModalOpen = false
  modal: any

  isBrandNameSame: boolean = false;
  isCompanyCRNoFile = false;
  isVatCertificateNoFile = false;
  isBrandLogoFile = false;
  isCompanyLogoFile = false;
  companyLogoName: any;
  brandLogoName: any;
  companyLogoPreview: any;
  brandLogoPreview: any;
  companyCRNoName: any;
  vatCertificateNoName: any;
  companyLogoFile: any;
  brandLogoFile: any;
  companyCRNoFile: any;
  vatCertificateNoFile: any;
  nationalAddressNoFile: any;
  supplierId: any;
  accessToken: any;
  files: any;
  nationalAddressFile: any;
  isNationalAddressFile = false;

  public validation_messages = {
    name: [
      { type: 'required', message: 'companyDetailsComponent.nameRequired' },
      { type: 'invalidName', message: 'companyDetailsComponent.invalidName' },
      { type: 'maxlength', message: 'companyDetailsComponent.nameMaxLength' },
    ],
    vatNumber: [
      { type: 'required', message: 'companyDetailsComponent.vatNumberRequired' },
      { type: 'invalidNumber', message: 'companyDetailsComponent.invalidVatNo' },
      { type: 'minlength', message: 'companyDetailsComponent.vatCertNoMinLength' },
    ],
    crNumber: [
      { type: 'required', message: 'companyDetailsComponent.crNumberRequired' },
      { type: 'invalidNumber', message: 'companyDetailsComponent.invalidCrNo' },
      { type: 'minlength', message: 'companyDetailsComponent.crNoMinLength' },
    ],
    nameArabic: [
      { type: 'required', message: 'companyDetailsComponent.nameRequiredArabic' },
      { type: 'invalidName', message: 'companyDetailsComponent.invalidNameArabic' },
      { type: 'maxlength', message: 'companyDetailsComponent.nameMaxLength' },
    ],
    mainBrand: [
      { type: 'required', message: 'companyDetailsComponent.mainBrandNameRequired' },
      { type: 'invalidName', message: 'companyDetailsComponent.invalidMainBrandName' },
      { type: 'maxlength', message: 'companyDetailsComponent.mainBrandMaxLength' },

    ],
    companyLogoName: [
      { type: 'required', message: 'companyDetailsComponent.companyLogoRequired' },
    ],
    mainBrandArabic: [
      { type: 'required', message: 'companyDetailsComponent.mainBrandNameArRequired' },
      { type: 'invalidName', message: 'companyDetailsComponent.invalidMainBrandNameAr' },
      { type: 'maxlength', message: 'companyDetailsComponent.mainBrandMaxLength' },

    ],
    brandLogoName: [
      { type: 'required', message: 'companyDetailsComponent.brandLogoNameRequired' },
    ],
    companyCRNoName: [
      { type: 'required', message: 'companyDetailsComponent.companyCRNoNameError' },
    ],
    vatCertificateNoName: [
      { type: 'required', message: 'companyDetailsComponent.vatCertificateNoError' },
    ],
    termsCondition: [
      { type: 'required', message: 'companyDetailsComponent.termConditionMsg' },
    ],
    nationalAddressFile: [
      { type: 'required', message: 'companyDetailsComponent.nationalAddressFileError' },
    ]
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: ApiService,
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private modalService: ModalService,
    private authService: AuthenticationService,
    public commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    const element = this.document.querySelector('#scrollId');
    if (element) {
      element.scrollIntoView();
    }
    this.supplierId = localStorage.getItem("supplierId");
    this.accessToken = localStorage.getItem("accessToken");
    this.detailsForm = this.initializeForm();
  }

  upload(files, fieldname) {
    files.preventDefault();
    files.stopPropagation();

    this.files = files['target']['files'][0].name;
    if (files && files['target'] && files['target']['files'] && files['target']['files'][0]) {

      if (fieldname == 'company-logo' || fieldname == 'brand-logo') {
        // for max size 2MB
        const max_size = 2097152;
        if (files['target']['files'][0].size > max_size) {
          this.message =
            'Maximum size allowed is ' + max_size / 1048576 + 'MB';
          this.files = null;
          this.fileToUpload = null;
          files.target.value = null;
          this.toastService.displayToast(this.message, null, 'warning');
          return false;
        }
      } else {
        // for max size 2MB
        const max_size = 2097152;
        if (files['target']['files'][0].size > max_size) {
          this.message =
            'Maximum size allowed is ' + max_size / 1048576 + 'MB';
          this.files = null;
          this.fileToUpload = null;
          files.target.value = null;
          this.toastService.displayToast(this.message, null, 'warning');
          return false;
        }
      }

      if (fieldname == 'company-logo' || fieldname == 'brand-logo') {

        if (files['target']['files'][0].type == "image/png") {
          this.message = null;
          const reader = new FileReader();
          reader.readAsDataURL(files['target']['files'][0]);
          reader.onload = (event: any) => {
            if (fieldname == 'company-logo') {
              this.companyLogoPreview = event.target.result;
              if (this.isBrandNameSame) {
                this.brandLogoPreview = event.target.result;
              }
            } else {
              this.brandLogoPreview = event.target.result;
            }
          };
          const obj = {
            fileUploaded: true
          }
          if (fieldname == 'company-logo') {
            this.companyLogoFile = files['target']['files'][0];
            this.companyLogoName = files['target']['files'][0].name;
            if (this.isBrandNameSame) {
              this.brandLogoFile = files['target']['files'][0];
              this.brandLogoName = files['target']['files'][0].name;
            }
          } else {
            this.brandLogoFile = files['target']['files'][0];
            this.brandLogoName = files['target']['files'][0].name;
          }
        } else {
          this.message = 'Only png images are allowed!';
          this.fileToUpload = null;
          files.target.value = null;
          this.files = null;
          this.toastService.displayToast(this.message, null, 'warning');
          return false;
        }
      }

      if (fieldname == 'company-cr-number') {
        this.isCompanyCRNoFile = false;
        setTimeout(() => {
          const setTooltipWidth = this.document.getElementsByClassName(
            'company-cr-no-tooltip',
          ) as HTMLCollectionOf<HTMLElement>;
          if (this.companyCrNoElem) {
            const companyCrDivWidth = this.companyCrNoElem.nativeElement.offsetWidth;
            if (setTooltipWidth[0]) {
              setTooltipWidth[0].style.width = companyCrDivWidth + "px";
            }
          }
        }, 500);
      }
      if (fieldname == 'vat-certificate-no') {
        this.isVatCertificateNoFile = false;
        setTimeout(() => {
          const setTooltipWidth = this.document.getElementsByClassName(
            'vat-certificate-no-tooltip',
          ) as HTMLCollectionOf<HTMLElement>;
          if (this.vatCertificateNoElem) {
            const companyVatDivWidth = this.vatCertificateNoElem.nativeElement.offsetWidth;
            if (setTooltipWidth[0]) {
              setTooltipWidth[0].style.width = companyVatDivWidth + "px";
            }
          }
        }, 500);
      }

      if (fieldname == 'national-address-file') {
        this.isNationalAddressFile = false;
        setTimeout(() => {
          const setTooltipWidth = this.document.getElementsByClassName(
            'national-address-file-tooltip',
          ) as HTMLCollectionOf<HTMLElement>;
          if (this.nationalAddressFileElem) {
            const companyVatDivWidth = this.nationalAddressFileElem.nativeElement.offsetWidth;
            if (setTooltipWidth[0]) {
              setTooltipWidth[0].style.width = companyVatDivWidth + "px";
            }
          }
        }, 500);
      }
      if (fieldname == 'brand-logo') {
        this.isBrandLogoFile = false;
        setTimeout(() => {
          const setTooltipWidth = this.document.getElementsByClassName(
            'brand-logo-tooltip',
          ) as HTMLCollectionOf<HTMLElement>;
          if (this.brandLogoElem) {
            const brandLogoDivHeight = this.brandLogoElem.nativeElement.offsetWidth;
            if (setTooltipWidth[0]) {
              setTooltipWidth[0].style.width = brandLogoDivHeight + "px";
            }
          }
        }, 500);
      }
      if (fieldname == 'company-logo') {
        this.isCompanyLogoFile = false;
        setTimeout(() => {
          const setTooltipWidth = this.document.getElementsByClassName(
            'company-logo-tooltip',
          ) as HTMLCollectionOf<HTMLElement>;
          if (this.companyLogoNameElem) {
            const companyLogoDivHeight = this.companyLogoNameElem.nativeElement.offsetWidth;
            if (setTooltipWidth[0]) {
              setTooltipWidth[0].style.width = companyLogoDivHeight + "px";
            }
          }
        }, 500);
      }

      if (fieldname == 'company-cr-number' || fieldname == 'vat-certificate-no' || fieldname == 'national-address-file') {
        if ((files['target']['files'][0].name).includes(".pdf") || (files['target']['files'][0].name).includes("application/pdf") || (files['target']['files'][0].name).includes(".docx") || (files['target']['files'][0].name).includes("application/docx")) {
          this.message = null;
          const reader = new FileReader();
          // reader.readAsDataURL(files['target']['files'][0]);
          // reader.onload = () => {
          // 	this.fileToUpload = null;
          // 	this.fileToUpload = reader.result
          // };
          const obj = {
            fileUploaded: true
          }
          if (fieldname == 'company-cr-number') {
            this.companyCRNoFile = files['target']['files'][0];
            this.companyCRNoName = files['target']['files'][0].name;
          } else if (fieldname == 'national-address-file') {
            this.nationalAddressNoFile = files['target']['files'][0];
            this.nationalAddressFile = files['target']['files'][0].name;
          }
          else {
            this.vatCertificateNoFile = files['target']['files'][0];
            this.vatCertificateNoName = files['target']['files'][0].name;
          }
        }
        else {
          this.message = 'Only PDF and DOCX files are allowed';
          this.fileToUpload = null;
          files.target.value = null;
          this.files = null;
          this.toastService.displayToast(this.message, null, 'warning');
          return false;
        }

      }
    }
    files.target.value = '';
    // }
  }

  ionViewWillEnter() {
    this.subscription = new Subscription();
    this.detailsForm = this.initializeForm();
  }

  initializeForm() {
    return this.formBuilder.group({
      name: new FormControl(null, {
        validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
      }),
      nameArabic: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.arabicNameValidator]),
      }),
      companyLogoName: new FormControl(null, {
        validators: Validators.compose([]),
      }),
      mainBrand: new FormControl(null, {
        validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.nameValidator]),
      }),
      mainBrandArabic: new FormControl(null, {
        validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.arabicNameValidator]),
      }),
      brandLogoName: new FormControl(null, {
        validators: Validators.compose([]),
      }),
      companyCRNoName: new FormControl(null, {
        validators: Validators.compose([]),
      }),
      vatCertificateNoName: new FormControl(null, {
        validators: Validators.compose([]),
      }),
      nationalAddressFile: new FormControl(null, {
        validators: Validators.compose([]),
      }),
      vatNumber: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.vatNoValidator]),
      }),
      crNumber: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.crValidator]),
      }),
      termsCondition: new FormControl(null, {
        validators: Validators.compose([Validators.requiredTrue]),
      }),
    });
  }

  onSubmit() {
    this.isCompanyCRNoFile = true;
    this.isVatCertificateNoFile = true;
    this.isNationalAddressFile = true;
    this.isBrandLogoFile = true;
    this.isCompanyLogoFile = true;
    if (this.companyCRNoFile) this.isCompanyCRNoFile = false;
    if (this.vatCertificateNoFile) this.isVatCertificateNoFile = false;
    if (this.brandLogoFile) this.isBrandLogoFile = false;
    if (this.companyLogoFile) this.isCompanyLogoFile = false;
    if (this.nationalAddressFile) this.isNationalAddressFile = false;

    for (const i in this.detailsForm.controls) {
      this.detailsForm.controls[i].markAsDirty();
      this.detailsForm.controls[i].updateValueAndValidity();
      this.detailsForm.controls[i].markAsTouched();
    }
    if (this.detailsForm.invalid) return
    // code required
    // !this.nationalAddressNoFile
    if (!this.companyCRNoFile || !this.vatCertificateNoFile || !this.brandLogoFile || !this.companyLogoFile || !this.nationalAddressNoFile) return

    const params = new FormData();
    if (this.detailsForm.controls.name.value) {
      params.append('supplier_legal_name_en', this.detailsForm.controls.name.value);
    }
    if (this.detailsForm.controls.nameArabic.value) {
      params.append('supplier_legal_name_ar', this.detailsForm.controls.nameArabic.value);
    }
    if (this.companyLogoFile) {
      params.append('supplier_logo_url', this.companyLogoFile);
    }

    if (this.detailsForm.controls.mainBrand.value) {
      params.append('brand_name_en', this.detailsForm.controls.mainBrand.value);
    }
    if (this.detailsForm.controls.mainBrandArabic.value) {
      params.append('brand_name_ar', this.detailsForm.controls.mainBrandArabic.value);
    }
    if (this.brandLogoFile) {
      params.append('brand_logo_url', this.brandLogoFile);
    }
    if (this.vatCertificateNoFile) {
      params.append('supplier_cr_url', this.vatCertificateNoFile);
    }
    if (this.nationalAddressNoFile) {
      params.append('national_address_url', this.nationalAddressNoFile);
    }
    if (this.companyCRNoFile) {
      params.append('supplier_vat_url', this.companyCRNoFile);
    }
    if (this.detailsForm.controls.crNumber.value) {
      params.append('supplier_cr_no', this.detailsForm.controls.crNumber.value);
    }
    if (this.detailsForm.controls.vatNumber.value) {
      params.append('supplier_vat_no', this.detailsForm.controls.vatNumber.value);
    }

    this.disableBtn = true;
    this.subscription.add(this.dataService.post(URLS.addCompanyDetails, params).subscribe(
      (response) => {
        if (response['code'] === 200 && response['data']) {
          localStorage.setItem('companyData', 'true');
          localStorage.setItem("accessToken", this.commonService.accessToken);
          this.disableBtn = false;
          this.checkApproveStatus();
        } else {
          this.disableBtn = false;
          this.toastService.displayToast(response['message'], null, 'danger');
        }
      }, (error) => {
        this.disableBtn = false;
        console.error(error);
        this.toastService.displayToast(error['message'], null, 'danger');
      }));
  }

  cancel() {
    this.detailsForm.reset();
  }

  selectCheckbox(event) {
    this.isBrandNameSame = event.detail.checked;
    if (event.detail.checked) {
      this.isMainBrand = 1;
      if (this.detailsForm.controls.name.value) {
        this.detailsForm.controls.mainBrand.setValue(this.detailsForm.controls.name.value);
      }
      if (this.detailsForm.controls.nameArabic.value) {
        this.detailsForm.controls.mainBrandArabic.setValue(this.detailsForm.controls.nameArabic.value);
      }
      if (this.isBrandNameSame) {
        this.brandLogoFile = this.companyLogoFile;
        this.brandLogoName = this.companyLogoName;
        this.brandLogoPreview = this.companyLogoPreview;
      }
      this.isBrandLogoFile = false;
    } else {
      this.isMainBrand = 0;
      this.detailsForm.controls.mainBrand.reset();
      this.detailsForm.controls.mainBrandArabic.reset();
      this.brandLogoPreview = null;
      this.brandLogoFile = null;
      this.brandLogoName = null;
    }
  }

  onKeyupLegalName(event: any) {
    if (this.isMainBrand) {
      if (this.detailsForm.controls.name.value) {
        this.detailsForm.controls.mainBrand.setValue(this.detailsForm.controls.name.value);
        this.detailsForm.controls.mainBrandArabic.setValue(this.detailsForm.controls.nameArabic.value);
      }
    }
  }

  phoneClick(e) {
    if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault();
    }
  }

  restrictSpecialChar(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  remove(event, fieldname) {
    event.preventDefault();
    event.stopPropagation();
    if (fieldname == 'company-logo') {
      this.companyLogoFile = null;
      this.companyLogoName = null;
      this.companyLogoPreview = null;
      if (this.isBrandNameSame) {
        this.brandLogoPreview = null;
        this.brandLogoFile = null;
        this.brandLogoName = null;
      }
    } else if (fieldname == 'brand-logo') {
      this.brandLogoPreview = null;
      this.brandLogoFile = null;
      this.brandLogoName = null;
    }
    else if (fieldname == 'company-cr-number') {
      this.companyCRNoFile = null;
      this.companyCRNoName = null;
    } else if (fieldname == 'national-address-file') {
      this.nationalAddressNoFile = null;
      this.nationalAddressFile = null;
    } else {
      this.vatCertificateNoFile = null;
      this.vatCertificateNoName = null;
    }
  }

  async termsConditions(event?) {
    event.preventDefault();
    event.stopPropagation();

    const modal = await this.modalCtrl.create({
      component: termsConditionsComponent,
      cssClass: 'terms-conditions-modal',
      componentProps: {
        isModel: true,
      }
    });
    modal.present();

    let dataOfModal: any = await modal.onDidDismiss();
    if (dataOfModal.data.data) {
      this.detailsForm.controls['termsCondition'].setValue(true);
    } else {
      if (this.detailsForm.controls['termsCondition'].value) {
        this.detailsForm.controls['termsCondition'].setValue(true);
      } else {
        this.detailsForm.controls['termsCondition'].setValue(false);
      }
    }
  }

  checkApproveStatus() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let companyData = localStorage.getItem('companyData');
      if (companyData == 'true') {
        let isApproved = localStorage.getItem('isApproved');
        if (isApproved == 'false' && !this.isModalOpen) {
          this.modalCtrl.dismiss(this.modal)
          this.approvalCheck();
        } else {
          this.router.navigate(['/account-details/marketplace-dashboard'])
        }
      } else {
        this.router.navigate(['/company-details'])
      }
    }
  }

  approvalCheck() {
    let obj = {
      component: ApprovalWaitingComponent,
      backdropDismiss: false,
      canDismiss: true,
      cssClass: 'approval-modal'
    };
    this.modal = this.modalService.openModal(obj);
    this.isModalOpen = true;
    this.modal.then(value => {
      this.isModalOpen = false;
    }, error => {
      console.log(error);
    });
  }

  logout() {
    this.authService.logout().subscribe(data => {
      localStorage.clear();
      this.router.navigate(['/login']);
    }, error => {
      localStorage.clear();
      this.router.navigate(['/login']);
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

}
