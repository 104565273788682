import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { FormComponent } from '../../_guards/can-deactivate-guard';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ProductService } from '../../_services/product.service';
import { ToastService } from '../../_services/toast.service';
import { ProductPreviewDetailComponent } from './../product-preview-detail/product-preview-detail.component';

@Component({
	selector: 'app-product-preview',
	templateUrl: './product-preview.component.html',
	styleUrls: ['./product-preview.component.scss'],
})
export class ProductPreviewComponent
	implements OnInit, FormComponent, OnDestroy {
	step = 3;
	validateForm = new FormGroup({});
	dataSaved = false;
	showConversion = false;
	public productForm: FormGroup;
	public subscription = new Subscription();
	productId: any;
	isSaving = false;
	customerPreviewData: any;
	variationInfo = [];

	product: any = {};
	isEditPage = false;
	viewPage = false;
	isLoading = false;
	relatedProducts = [];
	relatedProductisOpen = false;
	constructor(
		private modalService: ModalService,
		private toastService: ToastService,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		private router: Router,
		private dataService: ApiService,
		public productService: ProductService,
		private activeRoute: ActivatedRoute,
		@Inject(DOCUMENT) private document: Document
	) {
		this.productId = this.activeRoute.snapshot.paramMap.get('id');
	}

	ngOnInit() {
		this.productId = this.activeRoute.snapshot.paramMap.get('id');
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		let selectedWarehouse = localStorage.getItem('selectedWarehouse');
		if (selectedWarehouse) {
			this.productService.selectedWarehouseList = JSON.parse(selectedWarehouse);
		}
		if (this.router.url.includes('/product/edit')) {
			this.isEditPage = true;
			this.viewPage = false;
		}
		if (this.router.url.includes('/view-product/')) {
			this.viewPage = true;
		}
		this.productForm = this.initializeForm();
		if (this.viewPage) {
			this.getPreviewData(this.productId);
			this.isLoading = true;
			this.productService.getAllData().then(
				(data) => {
					this.getProductDetails(this.productId);
				},
				(err) => {
					this.getProductDetails(this.productId);
				}
			);
		} else {
			if (this.productService.savedProduct) {
				this.product = this.productService.savedProduct;
				this.getPreviewData(this.product.product_id);
				this.productService.getAllData().then(
					(data) => {
						this.getProductDetails(this.productId);
					},
					(err) => {
						this.getProductDetails(this.productId);
					}
				);
			} else {
				this.getPreviewData(this.productId);
				this.productService.getAllData().then(
					(data) => {
						this.getProductDetails(this.productId);
					},
					(err) => {
						this.getProductDetails(this.productId);
					}
				);
			}
		}
	}

	ionViewWillEnter() {
		this.subscription = new Subscription();
		this.productForm = this.initializeForm(this.productService.savedProduct);
	}

	initializeForm(data?) {
		return this.formBuilder.group({
			productName: new FormControl(
				data && data.product_name_en ? data.product_name_en : null
			),
			productNameArabic: new FormControl(
				data && data.product_name_ar ? data.product_name_ar : null
			),
			productDesc: new FormControl(
				data && data.product_desc_en ? data.product_desc_en : null
			),
			productDescArabic: new FormControl(
				data && data.product_desc_ar ? data.product_desc_ar : null
			),
			subClass: new FormControl(
				data && data.subclass_name ? data.subclass_name : null
			),
			sku: new FormControl(data && data.product_sku ? data.product_sku : null),
			country: new FormControl({
				value: data && data.product_countries ? data.product_countries : null,
				disabled: true,
			}),
			warehouseList: new FormControl({
				value: data && data.warehouses ? data.warehouses : null,
				disabled: true,
			}),
			quantity: new FormControl(
				data && data.total_quantity ? data.total_quantity : null
			),
			conversionRatio: new FormControl(
				data && data.conversation_ratio ? data.conversation_ratio : null
			),
			stockAvailability: new FormControl(
				data && data.availability_name ? data.availability_name : null
			),
			basePrice: new FormControl(
				data && data.price_per_unit ? data.price_per_unit : null
			),
			samplePrice: new FormControl(
				data && data.product_name_en ? data.product_name_en : null
			),
			units: new FormControl(data && data.unit_id ? data.unit_id : null),
			brand: new FormControl(data && data.brand_name ? data.brand_name : null),
			sampleSize: new FormControl(null),
			productUnit: new FormControl(
				data && data.product_unit_name ? data.product_unit_name : null
			),
			productSecondaryUnit: new FormControl(
				data && data.product_secondary_unit_name ? data.product_secondary_unit_name : null
			),
		});
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}

	ngOnDestroy() {
		if (this.viewPage) {
			this.productService.resetFlags(true);
		}
	}

	save() {
		localStorage.removeItem('product');
		this.router.navigate(['/account-details/products-management']);
	}

	prev() {
		this.dataSaved = true;
		if (this.isEditPage) {
			this.router.navigate([
				'account-details/products-management/product/edit/product-configuration',
				this.productService.savedProduct.product_id,
			]);
		} else {
			this.router.navigate([
				'account-details/products-management/product/add-new-product/product-configuration',
				this.productService.savedProduct.product_id,
			]);
		}
		this.commonService.setEvent('productStep', {
			isStep: 1,
		});
	}

	skipStep2() {
		if (this.isEditPage) {
			this.router.navigate([
				'account-details/products-management/product/edit/product-detail',
			]);
		} else {
			this.router.navigate(['account-details/add-new-product/product-detail']);
		}
		this.commonService.setEvent('productStep', {
			isStep: 0,
		});
	}

	accordionGroupChange(event) {
		const selectedValue = event.detail.value;
		this.variationInfo.forEach((element) => {
			if (selectedValue.includes(element.value)) {
				element.isOpen = true;
			} else {
				element.isOpen = false;
			}
		});
	}

	getProductDetails(id) {
		if (!id) {
			return;
		}
		const params = {
			product_id: Number(id),
		};
		this.isLoading = true;
		this.subscription.add(
			this.dataService.get(URLS.productDetails, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						this.product = data.data;
						if (this.product.related_products && this.product.related_products.length) {
							this.relatedProducts = this.product.related_products;
							this.relatedProducts.forEach(element => {
								element['web_image_url'] = element.product_images_web
							});

						}
						this.productService.savedProduct = this.product;
						if (this.productService.savedProduct.skus && this.productService.savedProduct.skus.length) {
							this.productService.savedProduct.skus.forEach(el => {
								if (!this.productService.savedProduct.conversation_ratio) {
									el['conversation_ratio'] = null;
								}
							});
						}
						setTimeout(() => { }, 300);
						let subclass = this.productService.subclassOnlyList.find(
							(el) =>
								el.id ==
								'subclass' + this.productService.savedProduct.sub_class_id
						);
						if (subclass) {
							this.productService.selectedSubclass = subclass;
						}
						this.productService.mapSelectedValues(
							this.productService.savedProduct.product_countries,
							this.productService.manufacturingCountryList,
							'country'
						);
						// this.productService.mapSelectedValues(this.productService.savedProduct.product_regions, this.productService.regionList, 'region') //might need later
						this.product.subclass_name = this.productService.selectedSubclass
							? this.productService.selectedSubclass.name
							: '';
						this.product.brand_name = this.productService.mapIdWithValue(
							this.productService.brandList,
							'id',
							this.product.brand_id
						);
						this.product.product_unit_name = this.productService.mapIdWithValue(
							this.productService.unitsList,
							'id',
							this.product.unit_id
						);
						this.product.product_secondary_unit_name = this.productService.mapIdWithValue(
							this.productService.unitsList,
							'id',
							this.product.secondary_unit_id
						);
						this.product.availability_name = this.productService.mapIdWithValue(
							this.productService.stockAvailablityList,
							'id',
							this.product.availability_id
						);
						if (this.product && this.product.unit_id === this.product.secondary_unit_id) {
							this.showConversion = false;
						} else {
							this.showConversion = true;
						}
						this.productService.savedProduct = this.product;
						this.productForm = this.initializeForm(this.product);
						let variations =
							this.product && this.product.skus && this.product.skus.length
								? this.product.skus
								: [];
						this.variationInfo = [];
						this.productService.selectedWarehouse = [];
						variations.forEach((element, index) => {
							let inv = [];
							let warehouseList = [];
							if (element.inventory && element.inventory.length) {
								element.inventory.forEach((invEle) => {
									if (index == 0) {
										if (invEle.available_quantity != null) {
											this.productService.selectedWarehouse.push({
												id: invEle.warehouse_id,
												name: this.commonService.domDirectionLTR
													? invEle.warehouse_title_en
													: invEle.warehouse_title_ar,
												value: null,
											});
											this.productService.selectedWarehouseList.push(
												invEle.warehouse_id
											);
										}
										if (invEle.available_quantity != null) {
											if (!warehouseList.includes(invEle.warehouse_id)) {
												warehouseList.push(invEle.warehouse_id);
											}
										}
									}
									if (
										this.productService.selectedWarehouseList.includes(
											invEle.warehouse_id
										)
									) {
										inv.push(invEle);
									}
									this.productForm.controls.warehouseList.setValue(
										warehouseList
									);
									let selectedWarehouse =
										localStorage.getItem('selectedWarehouse');
									if (selectedWarehouse) {
										this.productService.selectedWarehouseList =
											JSON.parse(selectedWarehouse);
										this.productForm.controls.warehouseList.setValue(
											this.productService.selectedWarehouseList
										);
									}
								});
							}
							let obj = {
								inventory: inv,
								value: element.product_sku,
								title: element.product_sku,
								name: element.product_name_en,
								conversation_ratio: element.conversation_ratio,
								sku: element.product_sku,
								supplier_product_sku: element.supplier_product_sku,
								price: element.price_per_unit,
								product_weight: element.product_weight,
								qty: element.quantity,
								status: element.is_active ? "active" : "inactive",
								webImages: element.product_images_web
									? element.product_images_web
									: [{ image_url: './assets/images/empty-img.png' }],
								mobileImages: element.product_images_mobile
									? element.product_images_mobile
									: [{ image_url: './assets/images/empty-img.png' }],
								isOpen: false,
							};
							this.variationInfo.push(obj);
						});

						this.isLoading = false;
					} else {
						this.isLoading = false;
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.isLoading = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	saveProduct() {
		let firstStepEditPage: any =
			this.commonService.localStorageGet('firstStepEditPage');
		let secondStepEditPage: any =
			this.commonService.localStorageGet('secondStepEditPage');

		if (
			firstStepEditPage != 'true' &&
			secondStepEditPage != 'true' &&
			this.product.is_draft != true
		) {
			this.dataSaved = true;
			this.productService.resetFlags(true);
			localStorage.removeItem('product_id');
			localStorage.removeItem('selectedWarehouse');
			this.router.navigate(['/account-details/products-management']);
			return;
		}

		let requestData = {
			product_details: [],
		};
		requestData.product_details.push({
			product_id: this.product.product_id,
			is_draft: false,
		});
		if (this.product.skus && this.product.skus.length) {
			this.product.skus.forEach((element) => {
				requestData.product_details.push({
					product_id: element.product_id,
					is_draft: false,
				});
			});
		}
		if (this.isSaving) {
			return;
		}
		this.isSaving = true;
		this.subscription.add(
			this.dataService.post(URLS.updateProduct, requestData).subscribe(
				(data) => {
					if (data['code'] == 200) {
						this.isSaving = false;
						this.dataSaved = true;
						this.productService.resetFlags(true);
						localStorage.removeItem('product_id');
						localStorage.removeItem('selectedWarehouse');
						this.router.navigate(['/account-details/products-management']);
					} else {
						this.isSaving = false;
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
					}
				},
				(error) => {
					this.isSaving = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}
	preview() {
		let obj = {
			component: ProductPreviewDetailComponent,
			backdropDismiss: false,
			cssClass: 'preview-modal',
			componentProps: {
				product_detail: this.customerPreviewData,
				product_id: this.productId ? this.productId : this.product.product_id,
			},
		};
		const modal = this.modalService.openModal(obj);

		modal.then(
			(value) => { },
			(error) => {
				console.log(error);
			}
		);
	}

	getPreviewData(id) {
		const params = {
			product_id: id,
		};
		this.subscription.add(
			this.dataService.get(URLS.getPreview, params).subscribe((data) => {
				if (data['code'] == 200) {
					this.customerPreviewData = data['data'];
				}
			})
		);
	}

	back() {
		this.router.navigate(['/account-details/products-management']);
	}

	productaccordionGroupChange(i) {
		this.relatedProductisOpen = !this.relatedProductisOpen;
	}
}
