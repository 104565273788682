import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './_services/common.service';
import { ModalService } from './_services/modal.service';
import { ApprovalWaitingComponent } from './components/approval-waiting/approval-waiting.component';
import { Router } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';
import { GetPlatformService } from './_services/get-platform.service';
import { ToastService } from './_services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    @Inject(DOCUMENT) private document: any,
    private renderer2: Renderer2,
    private translate: TranslateService,
    public commonService: CommonService,
    private router: Router,
    private authService: AuthenticationService,
    private modalService: ModalService,
    public toastService: ToastService,
    public getPlatformService: GetPlatformService
  ) {

    translate.addLangs(['en', 'ar']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }

    this.getPlatformService.getPlatformType();
    this.checkApproveStatus();

  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

  approvalCheck() {
    let obj = {
      component: ApprovalWaitingComponent,
      backdropDismiss: true,
      canDismiss: true,
      cssClass: 'approval-modal'
    };
    const modal = this.modalService.openModal(obj);

    modal.then(value => {
      this.logout();
    }, error => {
      console.log(error);
    });
  }

  checkApproveStatus() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let companyData = localStorage.getItem('companyData');
      if (companyData == 'true') {
        let isApproved = localStorage.getItem('isApproved');
        if (isApproved != 'true') {
          this.approvalCheck();
        }
      } else {
        this.router.navigate(['/company-details'])
      }
    }
  }

  logout() {
    this.authService.logout().subscribe(data => {
      localStorage.clear();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }, error => {
      localStorage.clear();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    });
  }

}
