import { Component, OnInit, Input, ElementRef, Inject } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { Router } from '@angular/router';
import { CommonService } from '../../_services/common.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ToastService } from '../../_services/toast.service';
import { Subscription } from 'rxjs';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ApiService } from '../../_services/api-data.service';
import { URLS } from '../../_config/api.config';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-product-reviews',
	templateUrl: './product-reviews.component.html',
	styleUrls: ['./product-reviews.component.scss'],
})
export class ProductReviewsComponent implements OnInit {
	public subscription = new Subscription();
	dateRange: FormGroup;
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	fromDate: any;
	toDate: any;
	statusSelect: any = "all";
	actionSelect: any;
	isLoading: boolean = false;
	showFilter: boolean = false;
	showSearch: boolean = false;
	isDesktop: boolean = false;
	searchText: any;
	reviewListData: any[] = [];
	showLoader: boolean = true;
	totalReviewCount: number = 0;
	pageno = 1;
	pagesize = 10;
	filterparams: any;

	statusList = [];

	actionList = [];

	public count = 0;

	public itemsPerPage = 10;

	public currentPage = 1;
	searchObj: any;
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate = new Date();

	@Input() showHeader: boolean;

	constructor(
		private modalService: ModalService,
		private fb: FormBuilder,
		public commonService: CommonService,
		private targetElem: ElementRef,
		private dataService: ApiService,
		private toastService: ToastService,
		private router: Router,
		private datePipe: DatePipe,
		private platform: Platform,
		private translate: TranslateService,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.isDesktop = this.platform.is('desktop')
	}

	ngOnInit() {
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.initializeDateForm();
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate());
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

		this.statusList = [
			{ value: 'all', name: this.translate.instant('all') },
			{ value: 'APPROVED', name: this.translate.instant('approved') },
			{ value: 'PENDING', name: this.translate.instant('pending') },
			{ value: 'REJECTED', name: this.translate.instant('rejected') }
		]

		this.actionList = [
			{ value: 'APPROVED', name: this.translate.instant('approved'), disabled: false },
			{ value: 'PENDING', name: this.translate.instant('pending'), disabled: true },
			{ value: 'REJECTED', name: this.translate.instant('rejected'), disabled: false }
		]

		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})

		this.searchText = null;
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
		this.getReviewList(this.filterparams, false);

	}

	initializeDateForm() {
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})
	}

	ionViewWillEnter() {
		this.initializeDateForm();
	}

	openFilters() {
		this.showFilter = !this.showFilter;
	}

	search() {
		this.filterparams = {
			page: this.pageno,
			size: this.pagesize
		}
		if (this.searchText && this.searchText.trim() == '') {
			this.searchText = null;
			return
		}
		if (this.searchText) {
			this.filterparams['search'] = this.searchText.trim();
			this.getReviewList(this.filterparams, false);
		}
	}

	// select data per page
	selectPageLength(event) {
		let selectPageSize = event.detail.value;
		this.itemsPerPage = event.detail.value
		this.pagesize = selectPageSize;
		this.currentPage = 1;
		this.filterparams['page'] = 1;
		this.filterparams['size'] = selectPageSize;
		this.getReviewList(this.filterparams, false)
	}

	onPageChange(event): void {
		this.currentPage = event;
		this.filterparams['page'] = this.currentPage;
		this.filterparams['size'] = this.itemsPerPage;
		this.getReviewList(this.filterparams, false)
	}

	clearSearch() {
		this.searchText = null;
		let params = {
			page: 1,
			size: this.pagesize
		}
		this.getReviewList(params, false);
	}

	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.statusSelect = 'all';
		this.dateRange.reset();
		setTimeout(() => {
			this.getReviewList(this.searchObj, true);
		}, 300);
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}

	//////////// Get Product Review List //////////////
	getReviewList(params?, clearFilter?: boolean) {
		this.showLoader = true;
		this.reviewListData = [];
		if (this.searchObj) {
			params = { ...this.searchObj, ...params }
		}
		if (clearFilter) {
			params = {};
			params['page'] = this.pageno;
			params['size'] = this.pagesize;
		}
		this.subscription.add(
			this.dataService.get(URLS.supplierReview, params).subscribe(data => {
				this.showLoader = false;
				if (data['code'] == 200) {
					if (data['data']) {
						this.totalReviewCount = parseInt(data['data']['total_count']);
					}
					this.reviewListData = [];
					if (data['data'] && data['data']['result'].length) {
						let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
						list.forEach((review) => {
							let obj = {
								id: review.review_id ? review.review_id : '',
								product_id: review.product_id ? review.product_id : '',
								customer_id: review.customer_id ? review.customer_id : '',
								review_id: review.review_id ? review.review_id : '',
								customer_name: review.customer_name ? review.customer_name : '-',
								customer_rating: review.customer_rating ? review.customer_rating : '-',
								review_title: review.review_title ? review.review_title : '-',
								customer_review: review.customer_review ? review.customer_review : '-',
								approval_status: review.approval_status ? (review.approval_status).toUpperCase() : '-',
								image_url: this.isDesktop ? review.web_image_url : review.mobile_image_url,
								product_name: this.commonService.domDirectionLTR ? review.product_name_en ? review.product_name_en : '-' : review.product_name_ar ? review.product_name_ar : '-',
								customer_type: review.customer_type ? review.customer_type : '-',
								mobile_no: review.mobile_no ? review.mobile_no : '-',
								is_visible: review.is_visible ? review.is_visible : false,
							};
							this.reviewListData.push(obj);
						});
					}
					const element = this.document.querySelector('#scrollId');
					if (element) {
						element.scrollIntoView();
					}
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.showLoader = false;
				this.reviewListData = [];
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	onStatusChange(event: any, reviewId) {
		let params = {};
		if (reviewId) {
			params["review_id"] = Number(reviewId);
		}
		if (event.value) {
			params["approval_status"] = event.value;
		}
		if (reviewId && event.value)
			this.updateStatus(params);
	}

	/////////// Update Product Review Status /////////////
	updateStatus(params: any) {

		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.reviewUpdate, params).subscribe(data => {
				if (data['code'] == 200 && data['data']) {
					this.isLoading = false;
				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	applyBtn() {
		this.searchObj = {}
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
                this.toastService.displayToast(this.translate.instant('error.selectFromDate'), null, 'danger');
            }
            if (!this.toDate) {
                this.toastService.displayToast(this.translate.instant('error.selectToDate'), null, 'danger');
            }
			return
		}
		if (this.fromDate && this.toDate) {
			this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
			this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
		}

		if (this.statusSelect != 'all') {
			this.searchObj['approval_status'] = this.statusSelect;
		}

		this.searchObj['page'] = this.pageno;
		this.searchObj['size'] = this.pagesize;
		setTimeout(() => {
			this.getReviewList(this.searchObj, false);
		}, 300);

	}

	detailPage(id) {
		this.router.navigate(['account-details/product-reviews/product-reviews-details', id], { "state": { "breadcrumb": this.translate.instant('reviewDetail') + " " + id, "url": '/account-details/product-reviews/product-reviews-details/' + id } });
	}

	key: string = "id";
	sortType: string = "ASC";

	onSort(event, key: any) {

		if (!this.reviewListData.length) {
			return;
		}
		this.key = key;

		const element = document.querySelectorAll('.sorting-icon');
		element.forEach(img => {
			img.setAttribute("src", "assets/icon/sort.svg")
		});

		let eventValue;
		if (event.target.className.includes("column-heading")) {
			eventValue = event.target.children[0];
		} else {
			eventValue = event.srcElement;
		}
		let order = eventValue.getAttribute("data-order")
		if (this.filterparams['sort_by'] != key) {
			order = "asc"
		}
		if (order === "asc") {
			this.sortType = "ASC";
			eventValue.setAttribute("data-order", "desc");
			eventValue.src = "assets/icon/sorting_asc.svg";
		} else if (order === "desc") {
			this.sortType = "DESC";
			eventValue.setAttribute("data-order", "");
			eventValue.src = "assets/icon/sorting_desc.svg";
		} else {
			delete this.filterparams['sort_by'];
			delete this.filterparams['sort_type'];
			eventValue.setAttribute("data-order", "asc");
			eventValue.src = "assets/icon/sort.svg";

		}
		if (order) {
			this.filterparams['sort_by'] = key;
			this.filterparams['sort_type'] = this.sortType;
		}
		this.getReviewList(this.filterparams, false)
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}
}
