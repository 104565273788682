import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { ExportExcelService } from 'src/app/_services/export-excel.service';
import { ToastService } from 'src/app/_services/toast.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';

@Component({
    selector: 'app-earnings',
    templateUrl: './earnings.component.html',
    styleUrls: ['./earnings.component.scss'],
})
export class EarningsComponent implements OnInit {
    public subscription = new Subscription();
    currencySymbol: string = "$";
    isLoading: boolean = false;
    showFilter: boolean = false;
    showSearch: boolean = false;
    filterparams: any;
    searchText: any;
    showLoader: boolean = false;
    pageno = 1;
    pagesize = 10;

    @Input() showHeader: boolean;
    tableData: any = [];
    supplierEarnings: any;
    key: string = "id";
    sortType: string = "ASC";

    perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
    selectInterface = { cssClass: 'custom-select-dd' };
    public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
    public count = 0;
    public itemsPerPage = 10;
    public currentPage = 1;
    totalCount = 0;
    isDownloading: boolean = false;
    constructor(
        private modalService: ModalService,
        public commonService: CommonService,
        private router: Router,
        private targetElem: ElementRef,
        public dataService: ApiService,
        @Inject(DOCUMENT) private document: Document,
        public toastService: ToastService,
        public exportService: ExportExcelService
    ) { }

    ngOnInit() {
        const element = this.document.querySelector('#scrollId');
        if (element) {
            element.scrollIntoView();
        }
        this.searchText = null;
        this.filterparams = {
            page: this.pageno,
            limit: this.pagesize
        }
        this.getEarningDeatils();
    }


    onSort(event, key: any) {
        this.key = key;

        const element = document.querySelectorAll('.sorting-icon');
        element.forEach(img => {
            img.setAttribute("src", "assets/icon/sort.svg")
        });

        let eventValue;
        if (event.target.className.includes("column-heading")) {
            eventValue = event.target.children[0];
        } else {
            eventValue = event.srcElement;
        }
        let order = eventValue.getAttribute("data-order")
        if (this.filterparams['sort_by'] != key) {
            order = "asc"
        }
        if (order === "asc") {
            this.sortType = "ASC";
            eventValue.setAttribute("data-order", "desc");
            eventValue.src = "assets/icon/sorting_asc.svg";
        } else if (order === "desc") {
            this.sortType = "DESC";
            eventValue.setAttribute("data-order", "");
            eventValue.src = "assets/icon/sorting_desc.svg";
        } else {
            delete this.filterparams['sort_by'];
            delete this.filterparams['sort_type'];
            eventValue.setAttribute("data-order", "asc");
            eventValue.src = "assets/icon/sort.svg";

        }
        if (order) {
            this.filterparams['sort_by'] = key;
            this.filterparams['sort_type'] = this.sortType;
        }

        this.pageno = 1;
        this.getEarningDeatils();
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe();
    }

    getEarningDeatils() {
        this.showLoader = true;
        this.tableData = [];

        let params = {};
        if (this.isDownloading) {
            params['dropdown'] = true;
        }
        else {
            params = {
                ...this.filterparams
            }

            if (this.filterparams['sort_by']) {
                params['sort_by'] = this.filterparams['sort_by'];
                params['sort_type'] = this.sortType;
            }
        }

        this.subscription.add(
            this.dataService.get(URLS.earningDetails, params).subscribe(data => {
                this.showLoader = false;
                if (data['code'] == 200) {
                    if (data['data']) {
                        this.tableData = data['data']['result'];
                        this.supplierEarnings = data['data']['supplier_earnings'];
                        this.totalCount = parseInt(data['data']['total_count']);

                        if (this.isDownloading) {
                            const list = data.data.result && data.data.result.length ? data.data.result : [];
                            if (list.length) {
                                this.exportService.saveAsXlsx(list, 'earning_history_list');
                            }
                        }

                    }
                    const element = this.document.querySelector('#scrollId');
                    if (element) {
                        element.scrollIntoView();
                    }
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.showLoader = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    selectPageLength(event) {
        let selectPageSize = event.detail.value;
        this.itemsPerPage = event.detail.value
        this.currentPage = 1;
        this.pageno = 1;
        this.pagesize = selectPageSize;
        this.getEarningDeatils();
    }

    onChange(event): void {
        this.currentPage = event;
        this.pageno = this.currentPage;
        this.getEarningDeatils()
    }

    downloadExcel() {
        if (this.isDownloading) {
            return;
        }
        this.isDownloading = true;
        this.getEarningDeatils();
    }
}
