import { Component, OnInit, Inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { GetPlatformService } from '../../_services/get-platform.service';
import { ApiService } from '../../_services/api-data.service';
import { ToastService } from '../../_services/toast.service';
import { ModalService } from '../../_services/modal.service';
import { TranslateConfigService } from '../../_services/translate-config.service';
import { CommonService } from '../../_services/common.service';
import { DOCUMENT } from '@angular/common';
declare var google;
declare var navigator;
@Component({
    selector: 'app-notification-category',
    templateUrl: './notification-category.component.html',
    styleUrls: ['./notification-category.component.scss'],
})
export class NotificationCategoryComponent implements OnInit {

    private subscription: Subscription = new Subscription();

    notificationCategoryArray: any = [];
    noData: boolean = false;
    public isLoading = false;

    constructor(public getPlatformService: GetPlatformService,
        private nativeGeocoder: NativeGeocoder,
        private dataService: ApiService,
        private toastService: ToastService,
        private modalService: ModalService,
        public navCtrl: NavController,
        private translateConfigService: TranslateConfigService,
        public translate: TranslateService,
        public commonService: CommonService,
        @Inject(DOCUMENT) private document: Document,
        public router: Router) {


    }

    ngOnInit() {
        this.scrollTop();
        this.getNotificationsCategory();
    }

    ionViewWillEnter() {
        this.getNotificationsCategory();
    }

    onChange(event): void {
        this.getNotificationsCategory();
    }

    scrollTop() {
        const element = this.document.querySelector('#scrollId');
        if (element) {
            element.scrollIntoView();
        }
    }

    getNotificationsCategory() {
        this.isLoading = true;
        this.noData = false;
        const params = {
            dropdown: true
        }
        this.subscription.add(
            this.dataService.get(URLS.notificationListType, params).subscribe(data => {
                if (data && data['code'] == 200) {
                    this.isLoading = false;
                    let list = data['data'].length ? data['data'] : [];
                    this.notificationCategoryArray = [];
                    list.forEach(element => {
                        let obj = {
                            id: element.supplier_notification_type_id,
                            title: element.notification_type_en ? element.notification_type_en : '',
                            title_ar: element.notification_type_ar ? element.notification_type_ar : '',
                            unread: element.unread ? element.unread : '',
                            image_url: element.image_url ? element.image_url : '',
                            is_active: element.is_enabled ? true : false,
                            sequence: element.sequence
                        }
                        this.notificationCategoryArray.push(obj);
                    });
                    if (this.notificationCategoryArray.length < 1) {
                        this.noData = true;
                    }
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
                this.noData = false;
            })
        )
    }


    goBack() {
        this.navCtrl.back({ animated: false });
    }

    gotoNotificationlist(data) {
        this.router.navigate(['/account-details/notification-management/' + data.id], {
            "state": {
                "breadcrumb": this.commonService.domDirectionLTR ? data.title : data.title_ar,
                "url": "/account-details/notification-management/" + data.id
            }
        });
    }

    gotoMobileNotificationlist(data) {

        const navigationExtras: NavigationExtras = {
            queryParams: {
                "categoryId": data.id,
            }
        };

        this.router.navigate(['/home/mobile-menu/notification-list'], navigationExtras)
    }

}
