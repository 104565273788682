import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { outputFile } from 'fs-extra';
import { Router } from '@angular/router';

@Component({
	selector: 'app-menus',
	templateUrl: './menus.component.html',
	styleUrls: ['./menus.component.scss'],
})
export class MenusComponent implements OnInit {
	@Input() menuVisibility;
	@ViewChild('menu') menu: ElementRef;
	menuHeight;
	@Output() public childEvent = new EventEmitter();
	items: any[] = [
		{
			heading: 'Ceilings', options: [
				{ category: 'Acoustic Ceilings', link: '' }, { category: 'Lorem ipsum', link: '' },
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' }
			]
		},
		{
			heading: 'Metal Fabrications', options: [
				{ category: 'Metal Pan Stairs', link: '' }, { category: 'Lorem ipsum', link: '' }
			]
		},
		{
			heading: 'Decorative Metal', options: [
				{ category: 'Decorative Metal Railings', link: '' }, { category: 'Decorative Metal Stairs', link: '' },
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' },
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' }
			]
		},
		{
			heading: 'Category #4', options: [
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' },
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' }
			]
		},
		{
			heading: 'Category #5', options: [
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' },
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' }
			]
		},
		{
			heading: 'Category #6', options: [
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' },
				{ category: 'Lorem ipsum dolor', link: '' }, { category: 'Lorem ipsum', link: '' }
			]
		}
	]
	constructor(public router: Router) { }

	ngOnInit() { }

	// hide menu on category clicks
	hideMenu() {
		this.menuVisibility = false;
		this.childEvent.emit(this.menuVisibility);
	}

	goToProductListing(category) {
		this.hideMenu();
	}

	notifyMe() {
		setTimeout(() => {
			this.menuHeight = this.menu.nativeElement.offsetHeight;
		}, 500);
	}



}
