import { Component, OnInit, Inject, Input } from '@angular/core';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { URLS } from '../../_config/api.config';
import { ToastService } from '../../_services/toast.service';
import { Subscription } from 'rxjs';
import { Router, NavigationExtras, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DOCUMENT, DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ModalService } from '../../_services/modal.service';
import { CustomValidator } from '../../_common/customvalidators';
import { ModalController, AlertController } from '@ionic/angular';
import { filter } from 'rxjs/operators'


@Component({
    selector: 'app-product-discount-details',
    templateUrl: './product-discount-details.component.html',
    styleUrls: ['./product-discount-details.component.scss'],
})
export class ProductDiscountDetailsComponent implements OnInit {
    public addDiscountForm: FormGroup;
    public subscription = new Subscription();
    @Input() discountData;
    @Input() dataFrom;
    @Input() fromPage;
    modalTitle: any;
    discountEndDate: any;
    discountStartDate: any;
    selectSubClass: any;
    discountId: any;
    editData: any;
    isLoading = false;
    subClassesList = [];
    subClassProductList = [];
    productIdList = [];
    files: any;
    public message: any;
    public fileToUpload: any
    discountLogoFile: any;
    discountLogoPreview: any;
    discountLogoName: any;
    isDiscountLogoFile = false;
    showLoader: boolean = false;
    datePipe = new DatePipe("en-US");
    selectInterface = { cssClass: 'custom-select-dd' };
    public totalItems = 0;
    public addDiscountItemsPerPage = 5;
    public addDiscountCurrentPage = 1;
    public pageno = 1;
    discountArray = [
        { value: 'PERCENTAGE', name: this.commonService.domDirectionLTR ? 'PERCENTAGE' : 'نسبة مئوية' },
        { value: 'PRICE', name: this.commonService.domDirectionLTR ? 'PRICE' : 'سعر' }
    ];

    public validation_messages = {
        discount_name_en: [
            { type: 'required', message: 'addDiscountComponent.errors.nameRequired' },
            { type: 'invalidName', message: 'addDiscountComponent.errors.nameInvalid' },
            { type: 'minlength', message: 'addDiscountComponent.errors.nameMinLength' },
            { type: 'maxlength', message: 'addDiscountComponent.errors.nameMaxLength' },
        ],
        discount_name_ar: [
            { type: 'required', message: 'addDiscountComponent.errors.nameArRequired' },
            { type: 'invalidName', message: 'addDiscountComponent.errors.invalidNameAr' },
        ],
        description_en: [
            { type: 'required', message: 'addDiscountComponent.errors.descriptionRequire' },
            { type: 'invalidName', message: 'addDiscountComponent.errors.invalidDescription' },
        ],
        description_ar: [
            { type: 'required', message: 'addDiscountComponent.errors.descriptionArRequired' },
            { type: 'invalidName', message: 'addDiscountComponent.errors.invalidDescriptionAr' },
        ],
        discount: [
            { type: 'required', message: 'addDiscountComponent.errors.discountRequire' },
            { type: 'invalidName', message: 'addDiscountComponent.errors.invalidDiscount' },
        ],
        subclass: [
            { type: 'required', message: 'addDiscountComponent.errors.subclassRequire' },
        ],
        discount_startdate: [
            { type: 'required', message: 'addDiscountComponent.errors.startdateRequire' }
        ],
        discount_enddate: [
            { type: 'required', message: 'addDiscountComponent.errors.enddateRequire' }
        ],
        discountLogoName: [
            { type: 'required', message: 'addDiscountComponent.errors.discountLogoRequire' }
        ],
        discount_type: [
            { type: 'required', message: 'addDiscountComponent.errors.discountTypeRequire' }
        ],
    }

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private toastService: ToastService,
        public commonService: CommonService,
        private modalService: ModalService,
        private modalCtrl: ModalController,
        private dataService: ApiService,
        private alertController: AlertController,
        private activeRoute: ActivatedRoute
    ) {

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((route: NavigationEnd) => {
            if (route.url.includes('/product-discount-details')) {
                this.activeRoute.queryParamMap.subscribe(data => {
                    if (data && data['params']['from']) {
                        this.fromPage = data['params']['from'];
                    }
                    if (data && data['params']['discount_id']) {
                        this.discountId = data['params']['discount_id'];
                    }
                })
            }
        })
    }

    ngOnInit() {
        this.addDiscountForm = this.initializeForm();
        this.getProductDiscount(this.discountId);
        this.getSubclass();
    }

    ionViewWillEnter() {
        this.subscription = new Subscription();
        this.addDiscountForm = this.initializeForm();
    }

    initializeForm(data = null) {
        return this.formBuilder.group({
            discount_name_en: new FormControl(data && data.discount_title_en ? data.discount_title_en : null, {
                validators: Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(50), CustomValidator.nameValidator]),
            }),
            discount_name_ar: new FormControl(data && data.discount_title_ar ? data.discount_title_ar : null, {
                validators: Validators.compose([Validators.required, CustomValidator.arabicNameValidator]),
            }),
            description_en: new FormControl(data && data.discount_description_en ? data.discount_description_en : null, {
                validators: Validators.compose([Validators.required]),
            }),
            description_ar: new FormControl(data && data.discount_description_ar ? data.discount_description_ar : null, {
                validators: Validators.compose([Validators.required, CustomValidator.arabicNameValidator]),
            }),
            discount: new FormControl(data && data.discount_value ? data.discount_value : null, {
                validators: Validators.compose([Validators.required]),
            }),
            subclass: new FormControl(null, {
                validators: Validators.compose([]),
            }),
            discount_type: new FormControl({ value: data && data.discount_type ? data.discount_type : null, disabled: data }, {
                validators: Validators.compose([Validators.required]),
            }),
            discount_start_date: new FormControl(data && data.start_date ? data.start_date : null, {
                validators: Validators.compose([Validators.required]),
            }),
            discount_startdate: new FormControl(data && data.start_date ? data.start_date : null, {
                validators: Validators.compose([Validators.required]),
            }),
            discount_end_date: new FormControl(data && data.end_date ? data.end_date : null, {
                validators: Validators.compose([Validators.required]),
            }),
            discount_enddate: new FormControl(data && data.end_date ? data.end_date : null, {
                validators: Validators.compose([Validators.required]),
            }),
            discountLogoName: new FormControl(data && data.image_url ? data.image_url : null, {
                validators: Validators.compose([]),
            }),

        });
    }

    upload(files) {
        files.preventDefault();
        files.stopPropagation();

        this.files = files['target']['files'][0].name;
        if (files && files['target'] && files['target']['files'] && files['target']['files'][0]) {

            // for max size 2MB
            const max_size = 2097152;
            if (files['target']['files'][0].size > max_size) {
                this.message =
                    'Maximum size allowed is ' + max_size / 1048576 + 'MB';
                this.files = null;
                this.fileToUpload = null;
                files.target.value = null;
                this.toastService.displayToast(this.message, null, 'warning');
                return false;
            }
        }

        if (files['target']['files'][0].type == "image/png") {
            this.message = null;
            const reader = new FileReader();
            reader.readAsDataURL(files['target']['files'][0]);
            reader.onload = (event: any) => {
                this.discountLogoPreview = event.target.result;
            };

            this.discountLogoFile = files['target']['files'][0];
            this.discountLogoName = files['target']['files'][0].name;
            this.isDiscountLogoFile = false;
        } else {
            this.message = 'Only png images are allowed!';
            this.fileToUpload = null;
            files.target.value = null;
            this.files = null;
            this.toastService.displayToast(this.message, null, 'warning');
            return false;
        }

        files.target.value = '';

    }

    remove(event) {
        event.preventDefault();
        event.stopPropagation();

        this.discountLogoFile = null;
        this.discountLogoName = null;
        this.discountLogoPreview = null;
    }

    getSubclass() {
        this.subscription.add(
            this.dataService.get(URLS.subClassList).subscribe(data => {
                if (data['code'] == 200) {
                    let list = data['data'] && data['data'].length ? data['data'] : [];
                    this.subClassesList = [];
                    list.forEach(element => {
                        this.subClassesList.push({
                            id: element.sub_class_id,
                            name: this.commonService.domDirectionLTR ? element.sub_class_name_en ? element.sub_class_name_en : '-' : element.sub_class_name_ar ? element.sub_class_name_ar : '-'
                        })
                    });

                } else {
                    if (data['code'] != 204) {
                        this.toastService.displayToast(data['message'], null, 'danger');
                    }
                }
            }, error => {
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    selectCheckbox(event: any, productId: any) {
        const isChecked = event.detail.checked;
        if (isChecked) {
            this.productIdList.push(productId);
        } else {
            const index = this.productIdList.indexOf(productId);
            if (index > -1) {
                this.productIdList.splice(index, 1);
            }
        }
    }

    onSubClassChange(list: any) {
        const subclassList = [];
        list.forEach((item) => {
            subclassList.push(item.id);
        });
        if (subclassList.length > 0) {
            this.getProductsList(subclassList)
        } else {
            this.subClassProductList = [];
        }
    }

    getProductsList(id: any) {
        const params = {
            sub_class_id: id.toString(),
        };
        this.showLoader = true;
        this.subscription.add(
            this.dataService.get(URLS.subClassProduct, params).subscribe(
                (data) => {
                    if (data['code'] == 200) {
                        if (data['data']) {
                            this.subClassProductList = [];
                            let list = data['data'] && data['data'].length ? data['data'] : [];
                            if (data['data'] && data['data'].length) {
                                list.forEach((product) => {
                                    let obj = {
                                        product_id: product['product_id'],
                                        sub_class_id: product['sub_class_id'],
                                        supplier_id: product['supplier_id'],
                                        product_name_en: product['product_name_en'],
                                        product_name_ar: product['product_name_ar'],
                                        total_quantity: product['total_quantity'],
                                        product_sku: product['product_sku'],
                                        price_per_unit: product['price_per_unit'],
                                        category_name_en: product['category_name_en'],
                                        category_name_ar: product['category_name_ar'],
                                        sub_class_name_en: product['sub_class_name_en'],
                                        sub_class_name_ar: product['sub_class_name_ar'],
                                        created_at: product['created_at'],
                                        updated_at: product['updated_at'],
                                        web_image_url: product['web_image_url'],
                                        mobile_image_url: product['mobile_image_url'],
                                    };
                                    this.subClassProductList.push(obj);
                                });
                            }
                            this.showLoader = false;
                        }
                    } else {
                        if (data['code'] != 204) {
                            this.showLoader = false;
                            this.toastService.displayToast(data['message'], null, 'danger');
                        }
                    }
                },
                (error) => {
                    this.showLoader = false;
                    this.toastService.displayToast(error['message'], null, 'danger');
                }
            )
        );
    }

    getProductDiscount(id: any) {
        const params = {
            discount_id: Number(id),
        };
        this.subscription.add(
            this.dataService.get(URLS.discountDetails, params).subscribe(
                (data) => {
                    if (data['code'] == 200) {
                        if (data['data']) {

                            this.editData = {
                                discount_id: data['data']['discount_id'],
                                discount_description_en: data['data']['discount_description_en'],
                                discount_description_ar: data['data']['discount_description_ar'],
                                discount_title_en: data['data']['discount_title_en'],
                                discount_title_ar: data['data']['discount_title_ar'],
                                start_date: this.datePipe.transform(data['data']['start_date'], 'longDate'),
                                end_date: this.datePipe.transform(data['data']['end_date'], 'longDate'),
                                discount_value: data['data']['discount_value'],
                                discount_type: data['data']['discount_type'],
                                sub_class_id: data['data']['sub_class_id'],
                                image_url_en: data['data']['image_url_en'],
                                image_url_ar: data['data']['image_url_ar'],
                                image_url: this.commonService.domDirectionLTR ? data['data']['image_url_en'] : data['data']['image_url_ar'],
                                discounted_value: data['data']['discounted_value'],
                                discounted_product_id: data['data']['discounted_product_id'],
                                product: data['data']['product'] && data['data']['product'].length ? data['data']['product'] : [],
                            }
                            let selectedSubclass = [];
                            this.editData.product.forEach(product => {
                                if (!selectedSubclass.includes(product.sub_class_id)) {
                                    selectedSubclass.push(product.sub_class_id)
                                }
                            });
                            this.addDiscountForm = this.initializeForm(this.editData);
                            if (this.editData.product) {
                                this.subClassProductList = this.editData.product;
                            }
                            if (this.subClassProductList.length) {
                                this.addDiscountForm.controls.subclass.setValue(selectedSubclass);
                                this.addDiscountForm.controls.subclass.disable();
                            }

                            if (this.editData.image_url) {
                                this.discountLogoPreview = this.editData.image_url;
                                this.discountLogoFile = this.editData.image_url;
                                let discountLogoData = this.discountLogoFile.split("/");
                                this.discountLogoName = discountLogoData[discountLogoData.length - 1].replace(".webp", "");
                            }
                        }
                    } else {
                        if (data['code'] != 204) {
                            this.toastService.displayToast(data['message'], null, 'danger');
                        }
                    }
                },
                (error) => {
                    this.toastService.displayToast(error['message'], null, 'danger');
                }
            )
        );
    }

    onSubmit() {

        this.isDiscountLogoFile = true;
        if (this.discountLogoFile) this.isDiscountLogoFile = false;

        for (const i in this.addDiscountForm.controls) {
            this.addDiscountForm.controls[i].markAsDirty();
            this.addDiscountForm.controls[i].updateValueAndValidity();
            this.addDiscountForm.controls[i].markAsTouched();
        }
        if (this.addDiscountForm.invalid) {
            return
        }

        if (!this.discountLogoFile) return

        if (this.productIdList.length == 0) {
            this.toastService.displayToast("Please select at least one product from list.", null, 'danger');
            return
        }

        const params = new FormData();
        if (this.discountId) {
            params.append('discount_id', this.discountId);
        }
        if (this.addDiscountForm.value.discount) {
            params.append('discount_value', this.addDiscountForm.value.discount.toString());
        }
        if (this.addDiscountForm.value.discount_start_date) {
            params.append('start_date', this.datePipe.transform(this.addDiscountForm.value.discount_start_date, 'YYYY-MM-dd'));
        }
        if (this.addDiscountForm.value.discount_end_date) {
            params.append('end_date', this.datePipe.transform(this.addDiscountForm.value.discount_end_date, 'YYYY-MM-dd'));
        }
        if (this.productIdList) {
            params.append('product_id', JSON.stringify(this.productIdList));
        }
        if (this.addDiscountForm.value.discount_type) {
            params.append('discount_type', this.addDiscountForm.value.discount_type);
        }
        if (this.addDiscountForm.value.discount_name_en) {
            params.append('discount_title_en', this.addDiscountForm.value.discount_name_en);
        }
        if (this.addDiscountForm.value.discount_name_ar) {
            params.append('discount_title_ar', this.addDiscountForm.value.discount_name_ar);
        }
        if (this.addDiscountForm.value.description_en) {
            params.append('discount_description_en', this.addDiscountForm.value.description_en);
        }
        if (this.addDiscountForm.value.description_ar) {
            params.append('discount_description_ar', this.addDiscountForm.value.description_ar);
        }
        if (this.discountLogoFile && this.commonService.domDirectionLTR) {
            params.append('image_url_en', this.discountLogoFile);
        } else if (this.discountLogoFile) {
            params.append('image_url_ar', this.discountLogoFile);
        }

        this.isLoading = true;
        this.subscription.add(
            this.dataService.post(URLS.discountUpdate, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'success');
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    prev() {
        this.router.navigate(['account-details/product-discount-management']);
    }

    onDateChange(datetime) {
        datetime.cancel(true);
    }

    async presentAlert(discountedProductId) {

        let mesaage = this.commonService.domDirectionLTR ? "You want to delete product?" : "تريد حذف المنتج؟";
        const alert = await this.alertController.create({
            header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
            message: mesaage,
            buttons: [
                {
                    text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
                    role: 'cancel',
                    handler: () => {

                    },
                },
                {
                    text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
                    role: 'confirm',
                    handler: () => {
                        let params = {
                            discounted_product_id: discountedProductId.toString(),
                        };
                        this.deleteProduct(params);
                    },
                },
            ],
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
    }

    restrictSpecialChar(event) {
        var k;
        k = event.charCode;  //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

    /////////// delete product data /////////////
    deleteProduct(params) {

        this.subscription.add(
            this.dataService.post(URLS.discountUpdate, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    this.getProductDiscount(this.discountId);
                    this.toastService.displayToast(data['message'], null, 'success');
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe();
    }

    onChange(event): void {
        this.addDiscountCurrentPage = event;
        this.pageno = event;

    }
}
