import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  constructor(public commonService: CommonService) {
  }

  ngOnInit() { }

}
