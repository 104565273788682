import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';
import { ToastService } from '../_services/toast.service';
import { CommonService } from '../_services/common.service';


const CODEMESSAGE = {
	200: 'The server successfully returned the requested data.',
	201: 'New or modified data is successful.',
	202: 'A request has entered the background queue (asynchronous task).',
	204: 'The data was deleted successfully.',
	400: 'The request was made with an error and the server did not perform any operations to create or modify data.',
	401: 'User does not have permission (token, username, password is incorrect).',
	403: 'The user is authorized, but access is forbidden.',
	404: 'The request is made for a record that does not exist and the server does not operate.',
	406: 'The format of the request is not available.',
	410: 'The requested resource is permanently deleted and will not be retrieved.',
	422: 'A validation error occurred when creating an object.',
	500: 'An error occurred on the server. Please check the server.',
	502: 'Gateway error.',
	503: 'The service is unavailable and the server is temporarily overloaded or maintained.',
	504: 'The gateway timed out.',
};

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	isPopOpen = false;
	currentRequestUrl;
	constructor(private authService: AuthenticationService,
		private router: Router,
		private toastService: ToastService,
		public commonService: CommonService
	) { }

	getToken() {
		return localStorage.getItem('accessToken');
	}


	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.currentRequestUrl = req.url;
		const token = localStorage.getItem('accessToken');
		let language = localStorage.getItem('locale');
		const headers = {};
		headers['Cache-Control'] = 'no-cache';
		headers['language'] = language ? language : 'en';
		headers['Access-Control-Allow-Origin'] = '*';

		headers['issupplierrequest'] = 'true';
		if ((token || this.commonService.accessToken) && !this.currentRequestUrl.includes("/supplier/verifyOtp") && !this.currentRequestUrl.includes("/supplier/reset/password") && !this.currentRequestUrl.includes("/supplier/registration") && !this.currentRequestUrl.includes("/supplier/login") && !this.currentRequestUrl.includes("/supplier/generateOtp") && !this.currentRequestUrl.includes("/supplier/resend/otp")) {
			headers['Authorization'] = token;
			if (this.commonService.accessToken) {
				headers['Authorization'] = this.commonService.accessToken;
			}

		} else {
			headers['Authorization'] = 'e49802a8-efcd-49ba-8460-882353e18703';
		}

		// Use the token in the request
		req = req.clone({
			setHeaders: headers
		});
		// Handle the request
		return next.handle(req).pipe(
			mergeMap((event: any) => {
				if (event instanceof HttpResponseBase) return this.handleData(event);
				return of(event);
			}),
			catchError((err: HttpErrorResponse) => this.handleData(err)),
		);

	}
	private checkStatus(ev: HttpResponseBase) {
		if (ev && ((ev.status >= 200 && ev.status < 300) || ev.status === 401)) {
			return;
		}
		if (ev.status == 0) {
			localStorage.setItem('isNetworkConnected', 'false');
		}
	}

	public handleData(ev: HttpResponseBase): Observable<any> {
		let mytoken = localStorage.getItem('token');
		this.checkStatus(ev);

		switch (ev['status']) {
			case 0:
				return throwError(ev['error']);
			case 200:
				if (ev['body'] && ev['body']['code'] == 401 && !ev['url'].includes('/supplier/verifyOtp') && !ev['url'].includes('/supplier/login') && !ev['url'].includes('/supplier/logout')) {
					this.logout();
				}
				break;
			case 400:
				return throwError(ev['error']);
				break;
			case 401:
				return throwError(ev['error']);
				break;
			case 403:
				this.logout();
				return throwError(ev['error']);
				break;
			case 404:
				return throwError(ev['error']);
				break;
			case 410:
				return throwError(ev['error']);
				break;
			case 500:
				return throwError(ev['error']);
				break;
			default:
				if (ev instanceof HttpErrorResponse) {
					console.warn(`I don't know the error, most of it is caused by the backend not supporting CORS or invalid configuration.`, ev['error']);
					return throwError(ev['error']);
				}
				break;
		}
		return of(ev);
	}

	logout() {
		this.authService.logout().subscribe(data => {
			localStorage.clear();
			this.router.navigate(['/login']);
		}, error => {
			localStorage.clear();
			this.router.navigate(['/login']);
		});
	}

}




