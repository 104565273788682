import { Component, ViewChild, OnInit } from '@angular/core';
import { Platform, IonInput } from '@ionic/angular';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidator } from '../../_common/customvalidators';
import { Router, NavigationStart } from '@angular/router';
import { CommonService } from '../../_services/common.service';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { ToastService } from '../../_services/toast.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators'

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	@ViewChild('passwordInput', { static: false }) passwordInput!: IonInput;

	public subscription = new Subscription();
	isLoading = false;
	passwordType = 'password';
	confirmPasswordType = 'password';

	public desktopView: boolean = false;
	message: string = "";
	approvalText: string = "";
	mobile_no: any;
	uuid: any;
	twoFaDetails: any;

	public validation_messages = {
		password: [
			{ type: 'required', message: 'registrationComponent.passwordRequired' },
			{ type: 'invalidPassword', message: 'registrationComponent.invalidPassword' },
		],
		confirm_password: [
			{ type: 'required', message: 'registrationComponent.confirmPasswordRequired' },
			{ type: 'passMismatch', message: 'registrationComponent.passMismatch' },
		],
	}

	resetPassFormGroup: FormGroup;

	constructor(
		private platform: Platform,
		private formBuilder: FormBuilder,
		private router: Router,
		public commonService: CommonService,
		private dataService: ApiService,
		private toastService: ToastService,
	) {
		this.desktopView = this.platform.is('desktop');
		this.router.events.pipe(
			filter(event => event instanceof NavigationStart)
		).subscribe((route: NavigationStart) => {
			if (route.navigationTrigger == "imperative") {
				if (route.url == '/reset-password') {
					setTimeout(() => {
						if (this.passwordInput) {
							this.passwordInput.setFocus();
						}
					}, 1000);
				}
			} else if (route.navigationTrigger == "popstate") {
				this.router.navigate(['/forgot-password']);
			}
		})
	}

	ngOnInit() {
		this.uuid = localStorage.getItem("uuid");
		this.mobile_no = localStorage.getItem("mobile_no");
		const decrypted2FaData = localStorage.getItem("twoFaData");
		this.twoFaDetails = this.commonService.decryptData(decrypted2FaData);
		this.twoFaDetails = JSON.parse(this.twoFaDetails);
		this.resetPassFormGroup = this.initializeresetPassFormGroup();

		setTimeout(() => {
			if (this.passwordInput) {
				this.passwordInput.setFocus();
			}
		}, 1000);
	}

	send() {
		for (const i in this.resetPassFormGroup.controls) {
			this.resetPassFormGroup.controls[i].markAsDirty();
			this.resetPassFormGroup.controls[i].updateValueAndValidity();
			this.resetPassFormGroup.controls[i].markAsTouched();
		}

		if (this.resetPassFormGroup.invalid) {
			return
		}

		this.resetPassword();
	}

	resetPassword() {
		const params = {
			"user_name": this.mobile_no,
			"uuid": this.uuid,
			"new_password": this.resetPassFormGroup.controls.password.value,
			"otp": this.twoFaDetails['otp'],
			"purpose_type": "FORGOT_PASSWORD",
		}

		this.isLoading = true;
		this.subscription.add(
			this.dataService.put(URLS.resetPassword, params).subscribe(data => {
				if (data['code'] == 200) {
					this.resetPassFormGroup.reset();
					localStorage.clear();
					this.router.navigate(['/login'], { replaceUrl: true }).then(() => {
						window.location.reload();
					});
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		)
	}

	//move to relevant page
	goToPage(page) {
		this.router.navigate([page]);
	}

	initializeresetPassFormGroup() {
		return this.formBuilder.group({
			password: new FormControl(null, {
				validators: Validators.compose([Validators.required,
				CustomValidator.passwordPatternValidator]),
			}),
			confirm_password: new FormControl(null, {
				validators: Validators.compose([Validators.required,
				CustomValidator.confirmPassword]),
			})
		})
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}

}
