import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { ModalService } from '../../_services/modal.service';

@Component({
  selector: 'app-approval-waiting',
  templateUrl: './approval-waiting.component.html',
  styleUrls: ['./approval-waiting.component.scss'],
})
export class ApprovalWaitingComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private authService: AuthenticationService,
    private modalService: ModalService,
  ) { }

  ngOnInit() { }

  async close() {

    await this.modalCtrl.dismiss().then(val => {
      this.logout();
    })
  }

  logout() {
    this.authService.logout().subscribe(data => {
      localStorage.clear();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    }, error => {
      localStorage.clear();
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
      });
    });
  }

}
