import { Component, Input, OnInit } from '@angular/core';
import { GetPlatformService } from 'src/app/_services/get-platform.service';
import { CommonService } from '../../_services/common.service';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {

    @Input() cardData;

    @Input() productDetailLabel;

    constructor(public commonService: CommonService,
        public getPlatformService: GetPlatformService) { }

    ngOnInit() { }

    favProductClick(event) {
        this.cardData['product_fav'] = false;
        this.cardData['product_liked'] = true;
        event.stopPropagation();
        event.preventDefault();
    }

    favProductClicked(event) {
        this.cardData['product_fav'] = true;
        this.cardData['product_liked'] = false;
        event.stopPropagation();
        event.preventDefault();
    }
}
