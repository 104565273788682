import { Component, OnInit, Input, Inject } from '@angular/core';
import { TranslateConfigService } from '../../_services/translate-config.service';
import { CommonService } from '../../_services/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-add-new-product',
  templateUrl: './add-new-product.page.html',
  styleUrls: ['./add-new-product.page.scss'],
})
export class AddNewProductPage implements OnInit {

  public isSelect = 0;
  public stepCount: any = 0;
  public isSecondStep: boolean = false;
  isPageEdit = false;
  isStep2 = false;
  isStep3 = false;
  constructor(
    private translateConfigService: TranslateConfigService,
    public commonService: CommonService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.translateConfigService.getDefaultLanguage();

    this.commonService.eventObservable.subscribe((obj) => {
      if (obj['event'] === 'productStep') {
        this.stepCount = obj['data'].isStep;
        this.isSecondStep = obj['data'].isSecondStep;
      }
    });
  }

  ngOnInit() {
    const element = this.document.querySelector('#scrollId');
		if (element) {
		  element.scrollIntoView();
		}
    this.router.events.subscribe(val => {
      this.isStep2 = false;
      this.isStep3 = false;
      if (this.router.url) {
        if (this.router.url.includes("/product-configuration")) {
          this.isStep2 = true;
        }
        if (this.router.url.includes("/product-preview")) {
          this.isStep3 = true;
        }
      }
    })
    if (this.router.url.includes('/product/edit')) {
      this.isPageEdit = true;
    }

  }

  ngDoCheck() {
    if (window.location.href.indexOf("product-detail") > -1) {
      this.isSelect = 0;
    }
    else if (window.location.href.indexOf("product-configuration") > -1) {
      this.isSelect = 1;
    }
    else if (window.location.href.indexOf("product-preview") > -1) {
      this.isSelect = 2;
    } else {
      this.isSelect = 0;
      this.isSecondStep = false;
      this.stepCount = 0;
    }
  }

}
