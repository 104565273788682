import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import 'lodash.product';
import { Subscription } from 'rxjs';
import { GetPlatformService } from 'src/app/_services/get-platform.service';
import { CustomValidator } from '../../_common/customvalidators';
import { URLS } from '../../_config/api.config';
import { FormComponent } from '../../_guards/can-deactivate-guard';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { EditorService } from '../../_services/editor.service';
import { ProductService } from '../../_services/product.service';
import { ToastService } from '../../_services/toast.service';

@Component({
	selector: 'app-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit, FormComponent {
	@ViewChildren('itemDiv') itemDivs!: QueryList<ElementRef>;
	notFoundText: string;
	step = 1;
	public productForm: FormGroup;
	public subscription = new Subscription();
	stockAvailDate: any;
	returnDate: any;
	selectedAttr: any;
	selectedPanelSize: any;
	selectedColor: any;
	validation_messages = {};

	enableEdit = false;
	editData: any;
	editIndex = null;
	warehouseError = false;
	quantityError = false;
	showWarehouse = false;
	isAddingProduct = false;
	baseMobileImageError = false;
	baseWebImageError = false;
	mobileImageError = false;
	webImageError = false;
	showConversion = false;
	showNoData = false;
	public productName: any = [
		{ id: 1, title: 'productDetail.sameSKUName', select: false },
		{ id: 2, title: 'productDetail.uniqueSKUName', select: true },
	];
	public productImage: any = [
		{ id: 1, title: 'productDetail.commonImage', select: false },
		{ id: 2, title: 'productDetail.uniqueImage', select: true },
	];
	public productPrice: any = [
		{ id: 1, title: 'productDetail.samePrice', select: false },
		{ id: 2, title: 'productDetail.uniquePrice', select: true },
	];
	public productqty: any = [
		{ id: 1, title: 'productDetail.sameQuantity', select: false },
		{ id: 2, title: 'productDetail.uniqueQuantity', select: true },
	];
	public productWeight: any = [
		{ id: 1, title: 'productDetail.sameWeight', select: false },
		{ id: 2, title: 'productDetail.uniqueWeight', select: true },
	];
	public productSampleProduct: any = [
		{ id: 1, title: 'yes', select: false },
		{ id: 2, title: 'no', select: true },
	];
	public configSampleProduct: any = [
		{ id: 1, title: 'productDetail.bothFree', select: true },
		{ id: 2, title: 'productDetail.shipmentCharged', select: false },
		{ id: 3, title: 'productDetail.bothCharged', select: false },
	];
	public vat: any = [
		{ id: 1, title: 'productDetail.withVat', select: true },
		{ id: 2, title: 'productDetail.withoutVat', select: false },
	];
	backupWarehouseList = [
		{ id: 1, name: 'Warehouse 1' },
		{ id: 2, name: 'Warehouse 2' },
		{ id: 3, name: 'Warehouse 3' },
		{ id: 4, name: 'Warehouse 4' },
		{ id: 5, name: 'Warehouse 5' },
		{ id: 6, name: 'Warehouse 6' },
		{ id: 7, name: 'Warehouse 7' },
		{ id: 8, name: 'Warehouse 8' },
		{ id: 9, name: 'Warehouse 9' },
		{ id: 10, name: 'Warehouse 10' },
		{ id: 11, name: 'Warehouse 11' },
	];
	attributesArray = [];
	interfaceOption = { cssClass: 'warehouse-dropdown custom-select-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	selectTreeInterface = { cssClass: 'custom-select-dd tree-dd' };
	warehouse: any;
	selectedWarehouses = [];
	dataSaved = false;
	quantity: any;
	minDate: any;
	showPS = false;
	public message: any;
	public fileToUpload: any;
	public files: any;
	selectedSubclass: any;
	showColorsDesign = false;
	isDocUpdating = false;
	todaysDate = new Date();
	isEditPage = false;
	isDraftPage = false;
	documentType: any;
	documentDescription: any;
	documentDescriptionAr: any;
	documentNameAr: any;
	documentName: any;
	// ck editor configuration
	ckeConfig: any;
	validateForm = new FormGroup({});
	productId: any;
	productChanged: boolean = false;
	relatedProductisOpen = false;
	categorySelect;
	categoriesList: any = [];
	categoriSelect = '';
	searchRelatedProductText = '';
	selectedRelatedProducts = [];
	relatedProduct: any[] = []
	selectedProducts = [];
	showLoader = false;
	isAddRelatedProduct = false;

	tagList = [];
	tag = null;
	tagChanged = false;
	isEditSaveRelated = false;
	relatedLoader = false;
	isrelatedProductupdated = false;
	isShowSaveRelatedtn = false;
	isEditTagLoader = false;
	isEditMetaTags = false;
	isWarehouseSelect = false;
	isCountrySelect = false;
	arrayLength: number = 10;
	items: any;
	tinyConfig: any;
	tinyConfigAr: any;

	constructor(
		public commonService: CommonService,
		private alertController: AlertController,
		public productService: ProductService,
		private datePipe: DatePipe,
		private toastService: ToastService,
		private router: Router,
		private activeRoute: ActivatedRoute,
		private dataService: ApiService,
		private translate: TranslateService,
		private config: NgSelectConfig,
		private loadingCtrl: LoadingController,
		public getPlatformService: GetPlatformService,
		public editor: EditorService

	) {
		this.notFoundText = this.translate.instant('noItemFound');
		this.config.notFoundText = this.notFoundText;
	}

	ngOnInit() {
		this.updateItems();
		this.initializeEditor();
		this.productService.allDataLoaded = false;
		this.productService.isAddingProduct = false;
		this.getCategoriesList();
		this.config.notFoundText = this.notFoundText;
		this.productId = this.activeRoute.snapshot.paramMap.get('id');
		this.productService.resetFlags(true);
		this.validation_messages = this.productService.validation_messages;
		this.productService.selectedAttributeList = [];
		if (this.router.url.includes('/product/edit')) {
			this.isEditPage = true;
			this.productChanged = false;
		} else if (this.router.url.includes('/product/edit')) {
			this.isEditPage = true;
			this.productChanged = false;
		} else {
			this.isEditPage = false;
		}
		this.productService.setFormControls();
		this.productForm = this.productService.productForm;
		if (this.isEditPage) {
			this.productService.showLoading();
			this.productService.getAllData().then(
				(data) => {
					this.getDetails(this.productId);
				},
				(error) => {
					this.getDetails(this.productId);
				}
			);
		} else {
			if (
				(!this.productService.savedProduct ||
					!this.productService.savedProduct.product_id) &&
				!this.productId
			) {
				this.productService.setFormControls();
				this.productForm = this.productService.productForm;
				this.validation_messages = this.productService.validation_messages;
				this.validateForm = this.productForm;
				this.minDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
				this.subscription = new Subscription();
				localStorage.removeItem('product');
				if (!this.productService.allDataLoaded) {
					this.productService.getAllData();
				}
			} else {
				this.subscription = new Subscription();
				if (this.productService.savedProduct) {
					this.productService.showLoading();
					this.productService.getAllData().then(
						(data) => {
							this.getDetails(this.productService.savedProduct.product_id);
						},
						(error) => {
							this.getDetails(this.productService.savedProduct.product_id);
						}
					);
				} else {
					this.productService.showLoading();
					this.productService.getAllData().then(
						(data) => {
							this.getDetails(this.productId);
						},
						(error) => {
							this.getDetails(this.productId);
						}
					);
				}
			}
		}
	}


	updateItems(): void {
		this.items = Array.from({ length: this.productService.documentTypeList.length }, (_, i) => `Item ${i + 1}`);
	}

	scrollToDiv(index: number): void {
		const itemDivArray = this.itemDivs.toArray();
		if (index >= 0 && index < itemDivArray.length) {
			itemDivArray[index].nativeElement.scrollIntoView({ behavior: 'smooth' });
		}
	}

	ionViewWillEnter() {
		this.initializeEditor();
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}

	changeDetected() {
		this.ratioValidation();
		this.productChanged = true;
	}

	getDetails(id) {
		if (!id) {
			return;
		}
		this.productService.getProductDetails(id).then(
			(product: any) => {
				if (product) {
					this.tag = '';
					this.isShowSaveRelatedtn = false;
					if (product.product_tags && product.product_tags.length > 0) { this.tagList = product.product_tags }
					if (product.related_products && product.related_products.length > 0) {
						this.isShowSaveRelatedtn = true;
						this.selectedRelatedProducts = product.related_products;
						this.selectedRelatedProducts.forEach(element => {
							element['web_image_url'] = element.product_images_web
						});

					};
					this.productService.setFormControls(product);
					this.productForm = this.productService.productForm;
					this.validateForm = this.productForm;
					this.isEditSaveRelated = false;
					this.isEditMetaTags = false;
					for (const i in this.f) {
						this.f[i].markAsDirty();
						this.f[i].updateValueAndValidity();
						this.f[i].markAsTouched();
					}
					this.productForm.markAllAsTouched();
					this.productForm.markAsDirty();
					this.productForm.updateValueAndValidity();
					this.productService.showCustomOptions = true;
					this.selectedAttribute('subclass' + product.sub_class_id);
					this.chooseSampleProduct(product.is_sample_unit_available ? 0 : 1);
					if (product.is_sample_item_and_shipment_free) {
						this.chooseConfigSampleProduct(0);
					}
					if (product.is_sample_item_free_and_shipment_charged) {
						this.chooseConfigSampleProduct(1);
					}
					if (product.is_sample_item_and_shipment_charged) {
						this.chooseConfigSampleProduct(2);
					}
					this.chooseImage(product.is_common_images_to_all_sku ? 0 : 1);
					this.choosePrice(product.is_common_price_to_all_sku ? 0 : 1);
					this.chooseQty(product.is_common_quantity_to_all_sku ? 0 : 1);
					this.chooseName(product.is_common_name_to_all_sku ? 0 : 1);
					this.chooseWeight(product.is_common_weight_to_all_sku ? 0 : 1);
					this.chooseVat(product.is_tax_free_product ? 1 : 0);
					if (product.is_common_quantity_to_all_sku) {
						this.productForm.controls.commonQuantity.setValue(
							product.skus[0]['inventory'][0]['available_quantity']
						);
					}
					// if (product.is_common_images_to_all_sku) { //might need later
					if (product.skus && product.skus.length) {
						let mobileImage =
							product.skus[0].product_images_mobile &&
								product.skus[0].product_images_mobile.length
								? product.skus[0].product_images_mobile
								: [];
						let webImage =
							product.skus[0].product_images_web &&
								product.skus[0].product_images_web.length
								? product.skus[0].product_images_web
								: [];
						mobileImage.forEach((images, i) => {
							if (i < 5) {
								let obj = {
									is_cover_image: images.is_cover_image,
									image_url: images.image_url,
									image_id: images.image_id,
								};
								this.productService.mobilePreviewImages.push(obj);
							}
						});
						webImage.forEach((images, i) => {
							if (i < 5) {
								let obj = {
									is_cover_image: images.is_cover_image,
									image_url: images.image_url,
									image_id: images.image_id,
								};
								this.productService.webPreviewImages.push(obj);
							}
						});
					}
					// }
					if (product.document_types && product.document_types.length) {
						product.document_types.forEach((docTypes) => {
							this.productService.documentTypeList.forEach((el) => {
								if (docTypes.document_type_id == el.id) {
									let documents = [];
									if (docTypes.documents && docTypes.documents.length) {
										docTypes.documents.forEach((savedDoc) => {
											let obj = {
												name: savedDoc.document_name_en
													? savedDoc.document_name_en
													: '',
												name_ar: savedDoc.document_name_ar
													? savedDoc.document_name_ar
													: '',
												description_ar: savedDoc.document_description_ar
													? savedDoc.document_description_ar
													: '',
												description: savedDoc.document_description_en
													? savedDoc.document_description_en
													: '',
												file: null,
												url: savedDoc.document_url
													? savedDoc.document_url
													: null,
												filename: '',
												document_type_id: el.id,
												product_document_id: savedDoc.product_document_id,
											};
											documents.push(obj);
										});
									}
									el.document_list = documents;
								}
							});
						});
					}
					this.productService.selectedWarehouse = [];
					if (product.skus && product.skus.length) {
						let warehouseList = [];
						product.skus.map((item, index) => {
							if (item.inventory && item.inventory.length) {
								item.inventory.map((invItem) => {
									item[invItem.warehouse_id] = invItem.available_quantity
										? invItem.available_quantity
										: 0;
									if (index == 0) {
										if (invItem.available_quantity != null) {
											this.productService.selectedWarehouse.push({
												id: invItem.warehouse_id,
												name: invItem.warehouse_title_en,
												value: null,
											});
										}
										if (invItem.available_quantity != null) {
											if (!warehouseList.includes(invItem.warehouse_id)) {
												warehouseList.push(invItem.warehouse_id);
											}
										}
									}
								});
							}
						});
						this.productForm.controls.warehouseList.setValue(warehouseList);
						let countryLength = this.productForm.value.country;
						if (warehouseList.length == this.productService ?.warehouseList ?.length) this.isWarehouseSelect = true;
						if (this.productService.manufacturingCountryList.length == countryLength.length) this.isCountrySelect = true;

						let selectedWarehouse = localStorage.getItem('selectedWarehouse');
						if (selectedWarehouse) {
							this.productService.selectedWarehouseList =
								JSON.parse(selectedWarehouse);
							this.productForm.controls.warehouseList.setValue(
								this.productService.selectedWarehouseList
							);
						}
					}
				}
				this.ratioValidation();
				this.productService.hideLoading();
			},
			(error) => {
				this.productService.hideLoading();
			}
		);
	}
	initializeEditor() {
		this.tinyConfig = this.editor.tinyConfig;
		this.tinyConfigAr = this.editor.tinyConfigAr;
	}

	onChange(event, attr, key) {
		let values = this.f[key].value;
		let selectedValue = [];
		let selectedValueId = [];
		if (attr && attr.valueOptions && attr.valueOptions.length) {
			if (attr.isMainConfig) {
				attr.valueOptions.forEach((el) => {
					if (values && values.length) {
						values.forEach((element) => {
							if (el.id == element) {
								selectedValue.push(el.label);
								selectedValueId.push(el);
							}
						});
					}
				});
			} else {
				let obj = attr.valueOptions.find((el) => el.id == values);
				if (obj) {
					selectedValue.push(obj.label);
					selectedValueId.push(obj);
				}
			}
		}
		attr.selectedAttr = selectedValue;
		attr.selectedAttrId = selectedValueId;
		if (attr.selectedAttrId.length == attr.valueOptions.length) {
			attr.isSelectAllSupplier = true;
		}
		else {
			attr.isSelectAllSupplier = false;
		}
	}

	documentUpload(files, document) {
		files.preventDefault();
		files.stopPropagation();
		this.files = files['target']['files'][0].name;
		if (
			files &&
			files['target'] &&
			files['target']['files'] &&
			files['target']['files'].length > 0
		) {
			const max_size = 15728640;
			const min_size = 1048576;
			for (let i = 0; i < files['target']['files'].length; i++) {
				if (files['target']['files'][i].size > max_size) {
					this.message =
						this.translate.instant('error.maxImage') +
						max_size / 1048576 +
						'MB';
					this.files = null;
					this.fileToUpload = null;
					files.target.value = null;
					this.toastService.displayToast(this.message, null, 'warning');
					return false;
				}

				document.document = files['target']['files'][i];
				document.document_filename = files['target']['files'][i].name;
			}
		}
		files.target.value = '';
	}

	upload(files, input?) {
		files.preventDefault();
		files.stopPropagation();
		this.files = files['target']['files'][0].name;
		// if (this.files.match(/\./g).length == 1) {
		if (
			files &&
			files['target'] &&
			files['target']['files'] &&
			files['target']['files'].length > 0
		) {
			const max_size = 5242880;
			for (let i = 0; i < files['target']['files'].length; i++) {
				if (files['target']['files'][i].size > max_size) {
					this.message =
						this.translate.instant('error.maxImage') +
						max_size / 1048576 +
						'MB';
					this.files = null;
					this.fileToUpload = null;
					files.target.value = null;
					this.toastService.displayToast(this.message, null, 'warning');
					return false;
				}
				if (
					files['target']['files'][i].type.includes('.image') ||
					files['target']['files'][i].type.includes('image/png') ||
					files['target']['files'][i].type.includes('image/gif') ||
					files['target']['files'][i].type.includes('image/jpeg') ||
					files['target']['files'][i].type.includes('image')
				) {
					this.message = null;
					const reader = new FileReader();
					if (input && input == 'web') {
						if (
							i < 5 &&
							this.productService.webImagesFile.length < 5 &&
							this.productService.webPreviewImages.length < 5
						) {
							let obj = {
								is_cover_image: false,
								image_url: files['target']['files'][i],
								image_id: null,
							};
							this.productService.webImagesFile.unshift(obj);
						} else {
							this.toastService.displayToast('imageLimitMsg', null, 'warning');
							return;
						}
					}
					if (input && input == 'mobile') {
						if (
							i < 5 &&
							this.productService.mobileImagesFile.length < 5 &&
							this.productService.mobilePreviewImages.length < 5
						) {
							let obj = {
								is_cover_image: false,
								image_url: files['target']['files'][i],
								image_id: null,
							};
							this.productService.mobileImagesFile.unshift(obj);
						} else {
							this.toastService.displayToast('imageLimitMsg', null, 'warning');
							return;
						}
					}
					if (input && input == 'baseMobile') {
						if (
							i < 1 &&
							this.productService.baseMobileImageFile.length < 1 &&
							this.productService.baseMobileImageFile.length < 1
						) {
							this.productService.baseMobileImageFile = [];
							let obj = {
								is_cover_image: false,
								image_url: files['target']['files'][i],
								image_id: null,
							};
							this.productService.baseMobileImageFile.unshift(obj);
						}
					}
					if (input && input == 'baseWeb') {
						if (
							i < 1 &&
							this.productService.baseWebImageFile.length < 1 &&
							this.productService.baseWebImageFile.length < 1
						) {
							this.productService.baseWebImageFile = [];
							let obj = {
								is_cover_image: false,
								image_url: files['target']['files'][i],
								image_id: null,
							};
							this.productService.baseWebImageFile.unshift(obj);
						}
					}

					reader.readAsDataURL(files['target']['files'][i]);
					reader.onload = (event: any) => {
						if (input && input == 'web') {
							if (i < 5 && this.productService.webPreviewImages.length < 5) {
								let obj = {
									is_cover_image: false,
									image_url: event.target.result,
									image_id: null,
								};
								this.productService.webPreviewImages.unshift(obj);
							} else {
								this.toastService.displayToast(
									'imageLimitMsg',
									null,
									'warning'
								);
								return;
							}
						} else if (input && input == 'mobile') {
							if (i < 5 && this.productService.mobilePreviewImages.length < 5) {
								let obj = {
									is_cover_image: false,
									image_url: event.target.result,
									image_id: null,
								};
								this.productService.mobilePreviewImages.unshift(obj);
							} else {
								this.toastService.displayToast(
									'imageLimitMsg',
									null,
									'warning'
								);
								return;
							}
						} else if (input && input == 'baseMobile') {
							if (
								i < 1 &&
								this.productService.baseMobileImagePreview.length < 1
							) {
								this.productService.baseMobileImagePreview = [];
								let obj = {
									is_cover_image: false,
									image_url: event.target.result,
									image_id: null,
								};
								this.productService.baseMobileImagePreview.unshift(obj);
							}
						} else {
							if (i < 1 && this.productService.baseWebImagePreview.length < 1) {
								this.productService.baseWebImagePreview = [];
								let obj = {
									is_cover_image: false,
									image_url: event.target.result,
									image_id: null,
								};
								this.productService.baseWebImagePreview.unshift(obj);
							}
						}
					};
					const obj = {
						fileUploaded: true,
					};
				} else {
					this.message = 'Only images are allowed';
					this.fileToUpload = null;
					files.target.value = null;
					this.files = null;
					this.toastService.displayToast(this.message, null, 'warning');
					return false;
				}
			}
		}
		if (this.productService.baseMobileImageFile.length) {
			this.baseMobileImageError = false;
		}
		if (this.productService.baseWebImageFile.length) {
			this.baseWebImageError = false;
		}
		if (this.productService.showCommonImage) {
			if (this.productService.webImagesFile.length) {
				this.webImageError = false;
			}
			if (this.productService.mobileImagesFile.length) {
				this.mobileImageError = false;
			}
		}
		// }
		files.target.value = '';
	}

	async presentAlertImage(index, input, id, url?) {
		const alert = await this.alertController.create({
			header: this.translate.instant('areYouSure'),
			message: this.translate.instant('deleteImgMsg'),
			buttons: [
				{
					text: this.translate.instant('Cancel'),
					role: 'cancel',
					handler: () => { },
				},
				{
					text: this.translate.instant('yes'),
					role: 'confirm',
					handler: () => {
						this.productChanged = true;
						this.removeImage(index, input, id, url);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	async removeImage(index, input, id, url?) {
		if (input && input == 'web') {
			if (id) {
				let imgIds = [id];
				let filename: any = url.split('/');
				filename = filename[filename.length - 1];
				this.productService.savedProduct.skus.forEach((el) => {
					if (el.product_images_web && el.product_images_web.length) {
						el.product_images_web.forEach((variationImage) => {
							if (variationImage.image_url.includes(filename)) {
								if (!imgIds.includes(variationImage.image_id)) {
									imgIds.push(variationImage.image_id);
								}
							}
						});
					}
				});
				await this.productService.imageDelete(imgIds).then((data) => {
					this.productService.webPreviewImages.splice(index, 1);
				});
			} else {
				this.productService.webPreviewImages.splice(index, 1);
				this.productService.webImagesFile.splice(index, 1);
			}
		}
		if (input && input == 'mobile') {
			if (id) {
				let imgIds = [id];
				let filename: any = url.split('/');
				filename = filename[filename.length - 1];
				this.productService.savedProduct.skus.forEach((el) => {
					if (el.product_images_mobile && el.product_images_mobile.length) {
						el.product_images_mobile.forEach((variationImage) => {
							if (variationImage.image_url.includes(filename)) {
								if (!imgIds.includes(variationImage.image_id)) {
									imgIds.push(variationImage.image_id);
								}
							}
						});
					}
				});
				await this.productService.imageDelete(imgIds).then((data) => {
					this.productService.mobilePreviewImages.splice(index, 1);
				});
			} else {
				this.productService.mobileImagesFile.splice(index, 1);
				this.productService.mobilePreviewImages.splice(index, 1);
			}
		}
		if (input && input == 'baseMobile') {
			if (id) {
				this.productService.imageDelete([id]).then((data) => {
					this.productService.baseMobileImagePreview = [];
					this.productService.savedProduct.default_mobile_images = [];
				});
			} else {
				this.productService.baseMobileImageFile = [];
				this.productService.baseMobileImagePreview = [];
				// this.baseMobileImageFile.splice(index, 1);
				// this.baseMobileImagePreview.splice(index, 1);
			}
		}
		if (input && input == 'baseWeb') {
			if (id) {
				this.productService.imageDelete([id]).then((data) => {
					this.productService.baseWebImagePreview = [];
					this.productService.savedProduct.default_web_images = [];
				});
			} else {
				this.productService.baseWebImageFile = [];
				this.productService.baseWebImagePreview = [];
				// this.baseWebImageFile.splice(index, 1);
				// this.baseWebImagePreview.splice(index, 1);
			}
		}
	}

	chooseName(i) {
		this.productName.forEach((element, index) => {
			if (index == i) {
				if (!element.select) {
					element.select = true;
					if (element.id == 1) {
						this.f['sameSKUName'].setValidators([Validators.required]);
						this.f['sameSKUNameAr'].setValidators([
							Validators.required,
							CustomValidator.arabicNameValidator,
						]);
						this.productService.showSameName = true;
					} else {
						this.f.sameSKUName.reset();
						this.productService.showSameName = false;
						this.f['sameSKUName'].clearValidators();
						this.f['sameSKUNameAr'].clearValidators();
					}
					this.f['sameSKUName'].updateValueAndValidity();
					this.f['sameSKUNameAr'].updateValueAndValidity();
				}
			} else element.select = false;
		});
	}
	chooseImage(i) {
		this.productImage.forEach((element, index) => {
			if (index == i) {
				if (!element.select) {
					element.select = true;
					if (element.id == 1) {
						this.productService.showCommonImage = true;
						this.productService.mobileImagesFile = [];
						this.productService.webImagesFile = [];
					} else {
						this.productService.showCommonImage = false;
					}
				}
			} else element.select = false;
		});
	}
	choosePrice(i) {
		this.productPrice.forEach((element, index) => {
			if (index == i) {
				if (!element.select) {
					element.select = true;
					if (element.id == 1) {
						this.productService.showSinglePrice = true;
						this.f['price'].setValidators([
							Validators.required,
							Validators.min(1),
						]);
					} else {
						this.f['price'].clearValidators();
						this.f.price.reset();
						this.productService.showSinglePrice = false;
					}
					this.f['price'].updateValueAndValidity();
				}
			} else element.select = false;
		});
	}
	chooseQty(i) {
		this.productqty.forEach((element, index) => {
			if (index == i) {
				if (!element.select) {
					element.select = true;
					if (element.id == 1) {
						this.f['commonQuantity'].setValidators([
							Validators.required,
							Validators.min(1),
						]);
						this.productService.showCommonQuantity = true;
						// this.showWarehouse = true;
					} else {
						this.f.commonQuantity.reset();
						this.f['commonQuantity'].clearValidators();
						this.productService.showCommonQuantity = false;
						// this.showWarehouse = false;
					}
					this.f['commonQuantity'].updateValueAndValidity();
				}
			} else element.select = false;
		});
	}
	chooseWeight(i) {
		this.productWeight.forEach((element, index) => {
			if (index == i) {
				if (!element.select) {
					element.select = true;
					if (element.id == 1) {
						this.f['weight'].setValidators([Validators.required]);
						this.productService.showCommonWeight = true;
						// this.showWarehouse = true;
					} else {
						this.f.weight.reset();
						this.f['weight'].clearValidators();
						this.productService.showCommonWeight = false;
						// this.showWarehouse = false;
					}
					this.f['weight'].updateValueAndValidity();
				}
			} else element.select = false;
		});
	}
	chooseSampleProduct(i) {
		this.productSampleProduct.forEach((element, index) => {
			if (index == i) {
				if (!element.select) {
					element.select = true;
					if (element.id == 1) {
						this.f['sampleMaxUnit'].setValidators([
							Validators.required,
							Validators.min(1),
						]);
						this.f['sampleSize'].setValidators([Validators.required]);
						this.f['sampleUnit'].setValidators([Validators.required]);
						this.productService.showSampleProduct = true;
					} else {
						this.f.samplePrice.reset();
						this.f.sampleMaxUnit.reset();
						this.f.sampleSize.reset();
						this.f.sampleUnit.reset();
						this.f['samplePrice'].clearValidators();
						this.f['sampleMaxUnit'].clearValidators();
						this.f['sampleSize'].clearValidators();
						this.f['sampleUnit'].clearValidators();
						this.productService.showSampleProduct = false;
					}

					this.f['samplePrice'].updateValueAndValidity();
					this.f['sampleMaxUnit'].updateValueAndValidity();
					this.f['sampleSize'].updateValueAndValidity();
					this.f['sampleUnit'].updateValueAndValidity();
					this.chooseConfigSampleProduct(0);
				}
			} else element.select = false;
		});
	}

	chooseConfigSampleProduct(i) {
		this.configSampleProduct.forEach((element, index) => {
			if (index == i) {
				element.select = true;
				if (i == 2) {
					this.f['samplePrice'].setValidators([Validators.required]);
					this.productService.bothCharged = true;
					this.productService.shipmentCharged = false;
				} else if (i == 1) {
					this.f['samplePrice'].clearValidators();
					this.productService.bothCharged = false;
					this.productService.shipmentCharged = true;
				} else {
					this.f['samplePrice'].clearValidators();
					this.productService.shipmentCharged = false;
					this.productService.bothCharged = false;
				}
			} else {
				this.f['samplePrice'].clearValidators();
				this.productService.bothCharged = false;
				element.select = false;
			}
		});
		this.f['samplePrice'].updateValueAndValidity();
	}

	chooseVat(i) {
		this.vat.forEach((element, index) => {
			if (index == i) {
				element.select = true;
				if (i == 1) {
					this.productService.withVat = false;
				} else {
					this.productService.withVat = true;
				}
			} else {
				element.select = false;
			}
		});
	}

	onDateChange(datetime) {
		datetime.confirm(true);
	}

	addEditWarehouse() {
		if (!this.warehouse && !this.quantity) {
			if (!this.warehouse) {
				this.warehouseError = true;
			}
			if (!this.quantity) {
				this.quantityError = true;
			}
			return;
		}
		if (this.enableEdit) {
			let obj = this.productService.warehouseList.find(
				(el) => el.id === this.warehouse
			);
			this.selectedWarehouses.forEach((element, index) => {
				if (this.editData.id === element.id) {
					this.selectedWarehouses.splice(index, 1);
				}
			});
			this.selectedWarehouses.push({
				id: obj.id,
				name: obj.name,
				quantity: this.quantity,
			});
			this.warehouse = null;
			this.quantity = null;
			this.editData = null;
			this.enableEdit = false;
			if (obj) {
				this.productService.warehouseList.forEach((element, index) => {
					if (obj.id === element.id) {
						this.productService.warehouseList.splice(index, 1);
					}
				});
			}
		} else {
			let obj = this.productService.warehouseList.find(
				(el) => el.id === this.warehouse
			);
			if (obj) {
				this.selectedWarehouses.push({
					id: obj.id,
					name: obj.name,
					quantity: this.quantity,
				});
				this.productService.warehouseList.forEach((element, index) => {
					if (obj.id === element.id) {
						this.productService.warehouseList.splice(index, 1);
					}
				});
				this.warehouse = null;
				this.quantity = null;
			}
		}
	}

	onEdit(data) {
		this.enableEdit = true;
		this.editData = JSON.parse(JSON.stringify(data));
		this.warehouse = data.id;
		this.quantity = data.quantity;
		let obj = this.backupWarehouseList.find((el) => el.id === data.id);
		if (obj) {
			this.productService.warehouseList.push(obj);
			this.productService.warehouseList.sort((a, b) => a.id - b.id);
		}
	}

	onDelete(data) {
		if (this.enableEdit) {
			return;
		}
		let obj = this.backupWarehouseList.find((el) => el.id === data.id);
		if (obj) {
			this.productService.warehouseList.push(obj);
			this.selectedWarehouses.forEach((element, index) => {
				if (data.id === element.id) {
					this.selectedWarehouses.splice(index, 1);
				}
			});
			this.productService.warehouseList.sort((a, b) => a.id - b.id);
		}
	}

	cancel() {
		this.enableEdit = false;
		this.warehouse = null;
		this.quantity = null;
		let obj = this.productService.warehouseList.find(
			(el) => el.id === this.editData.id
		);
		let index = this.productService.warehouseList.indexOf(obj);
		if (obj) {
			this.productService.warehouseList.splice(index, 1);
			this.productService.warehouseList.sort((a, b) => a.id - b.id);
		}
		this.editData = null;
	}

	getAttr(data, value) {
		if (data.children && data.children.length) {
			data.children.forEach((child) => {
				if (
					child.children &&
					child.children.length &&
					child.type != 'Sub Classes'
				) {
					this.getAttr(child, value);
				}
				if (child.type == 'Sub Classes' && child.name == value) {
					this.attributesArray = child.attribute;
				}
			});
		}
	}
	// when selected multiple attributes
	selectedAttribute(event) {
		this.attributesArray = [];
		let value = event; //event.detail.value;
		let obj = this.productService.subclassOnlyList.find((el) => el.id == value);
		if (this.productService.selectedAttributeList.length) {
			this.productService.selectedAttributeList.forEach((el) => {
				this.productForm.removeControl(el.key);
				delete this.validation_messages[el.key];
			});
		}
		if (obj) {
			this.productService.showCustomOptions = true;
			this.productService.getSubclassDetails(obj.id.replace(/subclass/g, ''));
		}
		this.productService.selectedSubclass = obj;
	}

	onCountryChange(event) {
		let params = {};
		if (this.productForm.value.country.length) {
			params = {
				countries: this.productForm.value.country.toString(),
			};
		}
		return;
		let selectedRegion: any;
		this.productService.getRegions(params).then(
			(regions) => {
				if (
					this.productForm.value.country &&
					this.productForm.value.country.length
				) {
					selectedRegion = this.productForm.value.region
						? this.productForm.value.region
						: [];
					if (selectedRegion.length) {
						selectedRegion.forEach((region, index) => {
							let regionObj = this.productService.regionList.find(
								(el) => el.region_id == region
							);
							if (regionObj) {
								if (
									!this.productForm.value.country.includes(regionObj.country_id)
								) {
									selectedRegion.splice(index, 1);
								}
							}
						});
					}
					if (selectedRegion && selectedRegion.length) {
						this.productForm.controls.region.setValue(selectedRegion);
					} else {
						this.productForm.controls.region.reset();
					}
				} else {
					this.productForm.controls.region.reset();
				}
				this.productService.checkForMissingData();
			},
			(err) => {
				this.productForm.controls.region.reset();
				this.productService.checkForMissingData();
			}
		);
	}

	get f() {
		return this.productService.productForm.controls;
	}

	async saveProduct() {
		if (this.isEditPage == true && !this.isEditSaveRelated) this.saveRelatedProduct();
		if (this.isEditPage == true && !this.isEditMetaTags) this.addMetaTag();

		if (this.isEditPage == true && this.productChanged != true && !this.tagChanged) {
			this.commonService.localStorageSet('firstStepEditPage', false);
			this.productService.editNext(this.productService.savedProduct.product_id);
			this.productService.resetFlags();
			return;
		}
		this.commonService.localStorageSet('firstStepEditPage', true);

		if (this.productService.missingData) {
			return;
		}
		let selectedOption = []; // Array of all the selected attributes value -> [[{},{}],[{},{}],...]
		let config = []; // all the Attributes along its value
		let ddAttr = this.productService.selectedAttributeList.find(
			(el) => el.type == 'dd'
		);
		let mainAttrEmpty = false;
		let configChanged = false; // flag to identify if configuration changed or not
		this.productService.selectedAttributeList.forEach((element) => {
			let obj = {
				configuration_id: element.id,
			};
			if (element.type == 'dd') {
				obj['configuration_value_id'] = this.productForm.value[element.key]
					? [this.productForm.value[element.key]]
					: null;
				if (
					!configChanged &&
					element.isMainConfig &&
					this.productForm.value[element.key] &&
					this.productService.savedProduct
				) {
					if (
						element.previouslySelectedAttr.join(',') !=
						this.productForm.value[element.key].join(',')
					) {
						configChanged = true;
					}
				}
				obj['configuration_value_id'] = this.productForm.value[element.key]
					? [this.productForm.value[element.key]]
					: null;
			} else {
				obj['configuration_value'] = this.productForm.value[element.key];
			}
			if (obj['configuration_value_id'] || obj['configuration_value']) {
				if (!element.isMainConfig) {
					config.push(obj);
				}
			}
			// selectedAttrId array of selected option from attribute
			if (
				element.selectedAttrId &&
				element.selectedAttrId.length &&
				element.isMainConfig
			) {
				element.selectedAttrId.sort((a, b) => a.id - b.id);
				selectedOption.push(element.selectedAttrId);
			}
			if (!mainAttrEmpty) {
				if (
					element.isMainConfig &&
					element.selectedAttrId &&
					!element.selectedAttrId.length
				) {
					mainAttrEmpty = true;
				}
			}
		});
		let docFieldDirty = false;
		let mainAttrAvailable: any = this.productService.selectedAttributeList.find(
			(el) => el.isMainConfig
		);
		if (!mainAttrAvailable) {
			this.toastService.displayToast('error.noMainAttr', null, 'danger');
			return;
		}

		this.productService.documentTypeList.forEach((data) => {
			if (!docFieldDirty) {
				if (
					data.document_name ||
					data.document_name_ar ||
					data.document_description_ar ||
					data.document_description ||
					data.document
				) {
					docFieldDirty = true;
				}
			}
		});
		let mobileImgIds = [];
		let webImgIds = [];
		if (
			this.productService.showCommonImage &&
			this.productService.savedProduct &&
			false
		) {
			if (configChanged) {
				if (this.productService.webPreviewImages.length) {
					this.productService.webPreviewImages.forEach((image, idx) => {
						if (image.image_id) {
							webImgIds.push(image.image_id);
							let filename: any = image.image_url.split('/');
							filename = filename[filename.length - 1];
							this.productService.savedProduct.skus.forEach((el) => {
								if (el.product_images_web && el.product_images_web.length) {
									el.product_images_web.forEach((variationImage) => {
										if (variationImage.image_url.includes(filename)) {
											if (!webImgIds.includes(variationImage.image_id)) {
												webImgIds.push(variationImage.image_id);
											}
										}
									});
								}
								this.productService.webPreviewImages.splice(idx, 1);
							});
						}
					});
				}
				if (this.productService.mobilePreviewImages.length) {
					this.productService.mobilePreviewImages.forEach((image, idx) => {
						if (image.image_id) {
							mobileImgIds.push(image.image_id);
							let filename: any = image.image_url.split('/');
							filename = filename[filename.length - 1];
							this.productService.savedProduct.skus.forEach((el) => {
								if (
									el.product_images_mobile &&
									el.product_images_mobile.length
								) {
									el.product_images_mobile.forEach((variationImage) => {
										if (variationImage.image_url.includes(filename)) {
											if (!mobileImgIds.includes(variationImage.image_id)) {
												mobileImgIds.push(variationImage.image_id);
											}
										}
									});
								}
								this.productService.mobilePreviewImages.splice(idx, 1);
							});
						}
					});
				}
				let idArr = [...webImgIds, ...mobileImgIds];
				if (idArr.length) {
					await this.productService.imageDelete([
						...webImgIds,
						...mobileImgIds,
					]);
				}
			}
		}
		if (!this.isEditPage) {
			if (
				!this.productService.baseMobileImageFile.length &&
				!this.productService.baseMobileImagePreview.length
			) {
				this.baseMobileImageError = true;
			}
			if (
				!this.productService.baseWebImageFile.length &&
				!this.productService.baseWebImagePreview.length
			) {
				this.baseWebImageError = true;
			}
			if (
				this.productService.showCommonImage &&
				!this.productService.savedProduct
			) {
				if (
					!this.productService.webImagesFile.length &&
					!this.productService.webPreviewImages.length
				) {
					this.webImageError = true;
				}
				if (
					!this.productService.mobileImagesFile.length &&
					!this.productService.mobilePreviewImages.length
				) {
					this.mobileImageError = true;
				}
			}
		}
		if (!configChanged && mainAttrEmpty) {
			this.toastService.displayToast(
				'error.allMainAttrRequired',
				null,
				'danger'
			);
			return;
		}
		if (this.enableEdit || docFieldDirty) {
			this.toastService.displayToast('error.saveDocument', null, 'danger');
			return;
		}
		let skuConfigurations =
			this.productService.generateProducts(selectedOption);
		for (const i in this.f) {
			this.f[i].markAsPristine();
			this.f[i].markAsDirty();
			this.f[i].updateValueAndValidity();
			this.f[i].markAsTouched();
		}
		let promiseArr = [];
		if (this.isEditPage) {
			if (
				!this.productService.savedProduct.default_mobile_images &&
				!this.productService.baseMobileImageFile.length
			) {
				this.baseMobileImageError = true;
			}
			if (
				!this.productService.savedProduct.default_web_images &&
				!this.productService.baseWebImageFile.length
			) {
				this.baseWebImageError = true;
			}
			if (!this.baseMobileImageError) {
				this.baseMobileImageError =
					!this.productService.baseMobileImageFile.length &&
						this.productService.savedProduct.default_mobile_images &&
						!this.productService.savedProduct.default_mobile_images.length
						? true
						: false;
			}
			if (!this.baseWebImageError) {
				this.baseWebImageError =
					!this.productService.baseWebImageFile.length &&
						this.productService.savedProduct.default_web_images &&
						!this.productService.savedProduct.default_web_images.length
						? true
						: false;
			}

			if (
				this.productService.showCommonImage &&
				this.productService.savedProduct
			) {
				// Promise.all([]).then(() => {
				if (
					!this.productService.webImagesFile.length &&
					!this.productService.webPreviewImages.length
				) {
					this.webImageError = true;
				}
				if (
					!this.productService.mobileImagesFile.length &&
					!this.productService.mobilePreviewImages.length
				) {
					this.mobileImageError = true;
				}
				// })
			}
		}
		if (this.productForm.invalid) {
			this.toastService.displayToast('error.completeForm', null, 'danger');
			return;
		}

		if (this.baseMobileImageError || this.baseWebImageError) {
			return;
		}
		if (
			this.productService.showCommonImage &&
			(this.mobileImageError || this.webImageError)
		) {
			return;
		}
		if (!this.isEditPage && !this.productService.savedProduct) {
			if (!selectedOption.length && ddAttr) {
				this.toastService.displayToast(
					'error.allMainAttrRequired',
					null,
					'danger'
				);
				return;
			}
		}
		let sampleUnitObj = this.productService.unitsList.find(
			(unit) => unit.id == this.productForm.value.sampleUnit
		);
		let params = {
			product_name_en: this.productForm.value.productName,
			product_name_ar: this.productForm.value.productNameArabic,
			product_desc_en: this.productForm.value.productDesc,
			product_desc_ar: this.productForm.value.productDescArabic,
			product_sku: this.productForm.value.sku,
			total_quantity: 0, //this.productForm.value.quantity,
			price_per_unit: this.productForm.value.basePrice,
			// "product_regions": this.productForm.value.region,
			brand_id: this.productForm.value.brand,
			product_countries: this.productForm.value.country,
			unit_id: this.productForm.value.productUnit, // size unit
			secondary_unit_id: this.productForm.value.productSecondaryUnits, //secondary unit
			sub_class_id: this.productForm.value.subClass
				? +this.productService.selectedSubclass.id.replace(/subclass/g, '')
				: null,
			availability_id: this.productForm.value.stockAvailability,
			is_common_name_to_all_sku: this.productService.showSameName
				? this.productService.showSameName
				: false,
			is_common_images_to_all_sku: this.productService.showCommonImage
				? this.productService.showCommonImage
				: false,
			is_common_price_to_all_sku: this.productService.showSinglePrice
				? this.productService.showSinglePrice
				: false,
			is_common_quantity_to_all_sku: this.productService.showCommonQuantity
				? this.productService.showCommonQuantity
				: false,
			is_sample_unit_available: this.productService.showSampleProduct
				? this.productService.showSampleProduct
				: false,
			is_common_weight_to_all_sku: this.productService.showCommonWeight
				? this.productService.showCommonWeight
				: false,
			product_weight: this.productForm.value.weight
				? this.productForm.value.weight
				: 0.0,
			is_tax_free_product: this.productService.withVat ? false : true,
			SKUs: skuConfigurations, // Product variations
			configurations: config,
			min_quantity: +this.productForm.value.minQuantity,
			conversation_ratio: this.productForm.value.conversionRatio ? +this.productForm.value.conversionRatio : null,
		};
		let editParams = {
			is_common_name_to_all_sku: this.productService.showSameName
				? this.productService.showSameName
				: false,
			is_common_images_to_all_sku: this.productService.showCommonImage
				? this.productService.showCommonImage
				: false,
			is_common_price_to_all_sku: this.productService.showSinglePrice
				? this.productService.showSinglePrice
				: false,
			is_common_quantity_to_all_sku: this.productService.showCommonQuantity
				? this.productService.showCommonQuantity
				: false,
			is_sample_unit_available: this.productService.showSampleProduct
				? this.productService.showSampleProduct
				: false,
			is_common_weight_to_all_sku: this.productService.showCommonWeight
				? this.productService.showCommonWeight
				: false,
			is_tax_free_product: this.productService.withVat ? false : true,
			configurations: config,
			min_quantity: +this.productForm.value.minQuantity,
			conversation_ratio: this.productForm.value.conversionRatio ? +this.productForm.value.conversionRatio : null,
		};
		let updateParams = {
			is_tax_free_product: this.productService.withVat ? false : true,
			is_common_name_to_all_sku: this.productService.showSameName
				? this.productService.showSameName
				: false,
			is_common_images_to_all_sku: this.productService.showCommonImage
				? this.productService.showCommonImage
				: false,
			is_common_price_to_all_sku: this.productService.showSinglePrice
				? this.productService.showSinglePrice
				: false,
			is_common_quantity_to_all_sku: this.productService.showCommonQuantity
				? this.productService.showCommonQuantity
				: false,
			is_sample_unit_available: this.productService.showSampleProduct
				? this.productService.showSampleProduct
				: false,
			is_common_weight_to_all_sku: this.productService.showCommonWeight
				? this.productService.showCommonWeight
				: false,
			configurations: config,
			min_quantity: +this.productForm.value.minQuantity,
			conversation_ratio: this.productForm.value.conversionRatio ? +this.productForm.value.conversionRatio : null,
		};

		if (this.productService.bothCharged) {
			params['is_sample_item_and_shipment_charged'] = true;
			params['is_sample_item_free_and_shipment_charged'] = false;
			params['is_sample_item_and_shipment_free'] = false;
			updateParams['is_sample_item_and_shipment_charged'] = true;
			updateParams['is_sample_item_free_and_shipment_charged'] = false;
			updateParams['is_sample_item_and_shipment_free'] = false;
			editParams['is_sample_item_and_shipment_charged'] = true;
			editParams['is_sample_item_free_and_shipment_charged'] = false;
			editParams['is_sample_item_and_shipment_free'] = false;
		}
		if (
			this.productService.shipmentCharged &&
			!this.productService.bothCharged
		) {
			params['is_sample_item_and_shipment_charged'] = false;
			params['is_sample_item_free_and_shipment_charged'] = true;
			params['is_sample_item_and_shipment_free'] = false;
			updateParams['is_sample_item_and_shipment_charged'] = false;
			updateParams['is_sample_item_free_and_shipment_charged'] = true;
			updateParams['is_sample_item_and_shipment_free'] = false;
			editParams['is_sample_item_and_shipment_charged'] = false;
			editParams['is_sample_item_free_and_shipment_charged'] = true;
			editParams['is_sample_item_and_shipment_free'] = false;
		}
		if (
			!this.productService.shipmentCharged &&
			!this.productService.bothCharged
		) {
			params['is_sample_item_and_shipment_charged'] = false;
			params['is_sample_item_free_and_shipment_charged'] = false;
			params['is_sample_item_and_shipment_free'] = true;
			updateParams['is_sample_item_and_shipment_charged'] = false;
			updateParams['is_sample_item_free_and_shipment_charged'] = false;
			updateParams['is_sample_item_and_shipment_free'] = true;
			editParams['is_sample_item_and_shipment_charged'] = false;
			editParams['is_sample_item_free_and_shipment_charged'] = false;
			editParams['is_sample_item_and_shipment_free'] = true;
		}
		//Additional columns for selected columns
		if (
			this.productForm.value.warehouseList &&
			this.productForm.value.warehouseList.length
		) {
			this.productService.selectedWarehouse = [];
			this.productService.selectedWarehouseList = JSON.parse(
				JSON.stringify(this.productForm.value.warehouseList)
			);
			localStorage.setItem(
				'selectedWarehouse',
				JSON.stringify(this.productForm.value.warehouseList)
			);
			this.productForm.value.warehouseList.forEach((id) => {
				let obj = this.productService.warehouseList.find((el) => el.id == id);
				if (obj) {
					this.productService.selectedWarehouse.push({
						id: id,
						name: obj.name,
						value: null,
					});
				}
			});
		}

		if (this.productService.showSampleProduct) {
			params['sample_unit_value'] = sampleUnitObj
				? this.productForm.value.sampleSize
				: '';
			params['sample_unit_id'] = this.productForm.value.sampleUnit;
			params['max_sample_unit_provided'] = this.productForm.value.sampleMaxUnit;
			editParams['sample_unit_value'] = sampleUnitObj
				? this.productForm.value.sampleSize
				: '';
			editParams['sample_unit_id'] = this.productForm.value.sampleUnit;
			editParams['max_sample_unit_provided'] =
				this.productForm.value.sampleMaxUnit;
			updateParams['sample_unit_value'] = sampleUnitObj
				? this.productForm.value.sampleSize
				: '';
			updateParams['sample_unit_id'] = this.productForm.value.sampleUnit;
			updateParams['max_sample_unit_provided'] =
				this.productForm.value.sampleMaxUnit;
		} else {
			params['sample_unit_value'] = '';
			params['sample_unit_id'] = null;
			params['max_sample_unit_provided'] = 0;
			editParams['sample_unit_value'] = '';
			editParams['sample_unit_id'] = null;
			editParams['max_sample_unit_provided'] = 0;
			updateParams['sample_unit_value'] = '';
			updateParams['sample_unit_id'] = null;
			updateParams['max_sample_unit_provided'] = 0;
		}
		if (this.productService.bothCharged) {
			updateParams['sample_price_per_unit'] =
				this.productForm.value.samplePrice;
			editParams['sample_price_per_unit'] = this.productForm.value.samplePrice;
			params['sample_price_per_unit'] = this.productForm.value.samplePrice;
		} else {
			updateParams['sample_price_per_unit'] = 0;
			editParams['sample_price_per_unit'] = 0;
			params['sample_price_per_unit'] = 0;
		}
		if (this.isEditPage) {
			if (configChanged) {
				// Promise.all(promiseArr).then(() => {
				this.productService.isAddingProduct = true;
				this.replaceOldVariations(
					this.productId,
					skuConfigurations,
					editParams
				).then(
					(data) => {
						this.editFlowUpdateProduct(editParams);
					},
					(error) => {
						this.productService.isAddingProduct = false;
					}
				);

				// })
			} else {
				this.editFlowUpdateProduct(editParams);
			}
		} else {
			if (this.productId) {
				if (configChanged) {
					// Promise.all(promiseArr).then(() => {
					this.productService.isAddingProduct = true;
					this.replaceOldVariations(
						this.productId,
						skuConfigurations,
						updateParams
					).then(
						(data) => {
							this.addFlowUpdateProduct(updateParams);
						},
						(error) => {
							this.productService.isAddingProduct = false;
						}
					);

					// })
				} else {
					this.addFlowUpdateProduct(updateParams);
				}
			} else {
				this.productService.addProduct(params).then(
					(data) => {
						this.dataSaved = true;
						if (this.selectedRelatedProducts.length > 0) this.saveRelatedProduct();
						if (this.tagList.length > 0) this.addMetaTag();

						if (this.productService.showCommonImage) {
							this.productService.updateProductVariations(true, true).then(
								(data) => {
									this.productService
										.addInventory(this.productService.warehouseQuantity)
										.then(
											(data) => {
												this.resetAndNext(
													this.productService.savedProduct.product_id
												);
											},
											(error) => {
												this.resetAndNext(
													this.productService.savedProduct.product_id
												);
											}
										);
								},
								(err) => {
									this.productService
										.addInventory(this.productService.warehouseQuantity)
										.then(
											(data) => {
												this.resetAndNext(
													this.productService.savedProduct.product_id
												);
											},
											(error) => {
												this.resetAndNext(
													this.productService.savedProduct.product_id
												);
											}
										);
									this.resetAndNext(
										this.productService.savedProduct.product_id
									);
									this.productService.isAddingProduct = false;
								}
							);
						} else {
							this.productService
								.addInventory(this.productService.warehouseQuantity)
								.then(
									(data) => {
										this.resetAndNext(
											this.productService.savedProduct.product_id
										);
									},
									(error) => {
										this.resetAndNext(
											this.productService.savedProduct.product_id
										);
									}
								);
						}
					},
					(err) => {
						if (err) {
							this.toastService.displayToast(
								'error.uploadImageFail',
								null,
								'danger'
							);
							if (this.productService.showCommonQuantity) {
								this.productService
									.addInventory(this.productService.warehouseQuantity)
									.then(
										(data) => {
											this.resetAndNext(
												this.productService.savedProduct.product_id
											);
										},
										(error) => {
											this.resetAndNext(
												this.productService.savedProduct.product_id
											);
										}
									);
							} else {
								this.resetAndNext(this.productService.savedProduct.product_id);
							}
						}
					}
				);
			}
		}
	}

	editFlowUpdateProduct(editParams) {
		let promiseArray = [];
		this.productService.isAddingProduct = true;
		this.productService.documentTypeList.forEach((doc) => {
			if (doc.document_list && doc.document_list.length) {
				doc.document_list.forEach((element) => {
					if (element.file) {
						promiseArray.push(
							this.productService.addDocument(
								this.productService.savedProduct.product_id,
								element
							)
						);
						this.productService.isAddingProduct = true;
					}
				});
			}
		});
		if (
			this.productService.baseMobileImageFile.length ||
			this.productService.baseWebImageFile.length
		) {
			promiseArray.push(
				this.productService.imageUpload(
					this.productService.savedProduct.product_id,
					'1',
					'base'
				)
			);
		}

		if (this.productService.mobileImagesFile.length) {
			this.productService.mobileImagesFile.forEach((file) => {
				promiseArray.push(
					this.productService.imageUpload(
						this.productService.savedProduct.product_id,
						'1',
						'mobile',
						file.image_url
					)
				);
			});
		}
		if (this.productService.webImagesFile.length) {
			this.productService.webImagesFile.forEach((file) => {
				promiseArray.push(
					this.productService.imageUpload(
						this.productService.savedProduct.product_id,
						'1',
						'web',
						file.image_url
					)
				);
			});
		}

		Promise.all(promiseArray).then(
			(images) => {
				this.productService.mobileImagesFile = [];
				this.productService.webImagesFile = [];

				if (this.isEditPage) {
					this.productService.savedProduct['tags'] = this.tagList;
					this.productService
						.updateProductVariations(true, true, this.isEditPage, editParams)
						.then(
							(data) => {
								this.productService
									.addInventory(this.productService.warehouseQuantity)
									.then(
										(data) => {
											this.dataSaved = true;
											this.productService.editNext(
												this.productService.savedProduct.product_id
											);
											this.productService.resetFlags();
										},
										(error) => {
											this.dataSaved = true;
											this.productService.editNext(
												this.productService.savedProduct.product_id
											);
											this.productService.resetFlags();
										}
									);
							},
							(err) => {
								this.productService.isAddingProduct = false;
							}
						);
				}
			},
			(err) => {
				this.productService.isAddingProduct = false;
			}
		);
	}

	addFlowUpdateProduct(updateParams) {
		let promiseArray = [];
		if (
			this.productService.baseMobileImageFile.length ||
			this.productService.baseWebImageFile.length
		) {
			promiseArray.push(
				this.productService.imageUpload(
					this.productService.savedProduct.product_id,
					'1',
					'base'
				)
			);
		}

		if (this.productService.mobileImagesFile.length) {
			this.productService.mobileImagesFile.forEach((file) => {
				promiseArray.push(
					this.productService.imageUpload(
						this.productService.savedProduct.product_id,
						'1',
						'mobile',
						file.image_url
					)
				);
			});
		}
		if (this.productService.webImagesFile.length) {
			this.productService.webImagesFile.forEach((file) => {
				promiseArray.push(
					this.productService.imageUpload(
						this.productService.savedProduct.product_id,
						'1',
						'web',
						file.image_url
					)
				);
			});
		}
		this.productService.isAddingProduct = true;
		Promise.all(promiseArray).then((images) => {
			this.productService.mobileImagesFile = [];
			this.productService.webImagesFile = [];
			this.productService
				.updateProductVariations(true, true, false, updateParams)
				.then(
					(data) => {
						this.saveRelatedProduct();
						this.addMetaTag();
						this.productService
							.addInventory(this.productService.warehouseQuantity)
							.then(
								(data) => {
									this.dataSaved = true;
									this.resetAndNext(
										this.productService.savedProduct.product_id
									);
								},
								(error) => {
									this.dataSaved = true;
									this.resetAndNext(
										this.productService.savedProduct.product_id
									);
								}
							);
					},
					(err) => {
						this.productService.isAddingProduct = false;
					}
				);
		});
	}

	resetAndNext(id) {
		this.productService.next(id);
		this.productService.resetFlags();
	}

	numberOnlyValidation(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);

		if (!pattern.test(inputChar)) {
			// invalid character, prevent input
			event.preventDefault();
		}
	}

	fileDescriptionChange(document, input, lang?) {
		const NAME_REGEX =
			/^[\u0600-\u06FF\u0750-\u077F0-9/(/)A-Z. !@#$%^&*()_+\-=\[\]{};'`’:"\\|,.<>\/?]+$/;
		if (lang == 'ar') {
			if (input == 'desc') {
				if (
					!NAME_REGEX.test(document.document_description_ar.trim()) &&
					document.document_description_ar.trim()
				) {
					document.document_description_ar_error = true;
				} else {
					document.document_description_ar_error = false;
				}
			}
			if (input == 'name') {
				if (
					!NAME_REGEX.test(document.document_name_ar.trim()) &&
					document.document_name_ar.trim()
				) {
					document.document_name_ar_error = true;
				} else {
					document.document_name_ar_error = false;
				}
			}
		}
		if (lang == 'en') {
			const englishDescRegex =
				/^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':`’"\\|,.<>\/?]*$/;
			if (input == 'desc') {
				if (
					!englishDescRegex.test(document.document_description.trim()) &&
					document.document_description.trim()
				) {
					document.document_description_en_error = true;
				} else {
					document.document_description_en_error = false;
				}
			}
			const englishNameRegex = /^[a-zA-Z0-9 ]*$/;
			if (input == 'name' && false) {
				if (
					!englishNameRegex.test(document.document_name.trim()) &&
					document.document_name.trim()
				) {
					document.document_name_en_error = true;
				} else {
					document.document_name_en_error = false;
				}
			}
		}
	}

	addDocument(data) {
		if (data) {
			if (
				!this.enableEdit &&
				(!data.document_name ||
					!data.document_name_ar ||
					!data.document_description_ar ||
					!data.document_description ||
					!data.document ||
					!data.document_filename)
			) {
				if (!data.document_name) {
					this.toastService.displayToast('error.docNameReq', null, 'danger');
					return;
				}
				if (!data.document_name_ar) {
					this.toastService.displayToast('error.docNameArReq', null, 'danger');
					return;
				}
				if (!data.document_description_ar) {
					this.toastService.displayToast('error.docDescArReq', null, 'danger');
					return;
				}
				if (!data.document_description) {
					this.toastService.displayToast('error.docDescReq', null, 'danger');
					return;
				}
				if (!data.document) {
					this.toastService.displayToast('error.docFileReq', null, 'danger');
					return;
				}
			}
			if (
				this.enableEdit &&
				(!data.document_name ||
					!data.document_name_ar ||
					!data.document_description_ar ||
					!data.document_description)
			) {
				if (!data.document_name) {
					this.toastService.displayToast('error.docNameReq', null, 'danger');
					return;
				}
				if (!data.document_name_ar) {
					this.toastService.displayToast('error.docNameArReq', null, 'danger');
					return;
				}
				if (!data.document_description_ar) {
					this.toastService.displayToast('error.docDescArReq', null, 'danger');
					return;
				}
				if (!data.document_description) {
					this.toastService.displayToast('error.docDescReq', null, 'danger');
					return;
				}
				return;
			}
			if (
				data.document_name_en_error ||
				data.document_description_en_error ||
				data.document_name_ar_error ||
				data.document_description_ar_error
			) {
				return;
			}
			let obj = {
				name: data.document_name ? data.document_name : '',
				name_ar: data.document_name_ar ? data.document_name_ar : '',
				description_ar: data.document_description_ar
					? data.document_description_ar
					: '',
				description: data.document_description ? data.document_description : '',
				file: data.document ? data.document : null,
				filename: data.document_filename ? data.document_filename : '',
				document_type_id: data.id,
			};

			if (this.enableEdit) {
				if (
					data.document_list[this.editIndex] &&
					data.document_list[this.editIndex].product_document_id
				) {
					obj['product_document_id'] =
						data.document_list[this.editIndex].product_document_id;
				}
				if (data.document_list[this.editIndex].product_document_id) {
					this.isDocUpdating = true;
					this.productService
						.updateDocument(this.productService.savedProduct.product_id, obj)
						.then(
							(res) => {
								data.document_list.splice(this.editIndex, 1);
								data.document_list.splice(this.editIndex, 0, obj);
								this.isDocUpdating = false;
								this.enableEdit = false;
								this.editIndex = null;
							},
							(err) => {
								this.isDocUpdating = false;
								this.enableEdit = false;
								this.editIndex = null;
							}
						);
				} else {
					data.document_list.splice(this.editIndex, 1);
					data.document_list.splice(this.editIndex, 0, obj);
					this.enableEdit = false;
					this.editIndex = null;
				}
			} else {
				if (data.document_list.length < data.max_doc_allowed) {
					data.document_list.push(obj);
				} else {
					this.toastService.displayToast('error.docMaxedOut', null, 'danger');
				}
			}
			data.document_name = '';
			data.document_name_ar = '';
			data.document_description_ar = '';
			data.document_description = '';
			data.document = null;
			data.document_filename = '';
		}
	}

	editDocument(data, index, document) {
		if (this.enableEdit) {
			return;
		}
		this.enableEdit = true;
		this.editIndex = index;
		document.document_name = data.name;
		document.document_name_ar = data.name_ar;
		document.document_description_ar = data.description_ar;
		document.document_description = data.description;
		document.document = data.file;
		document.document_filename = data.filename;
	}

	docEditCancel(data) {
		if (this.isDocUpdating) {
			return;
		}
		data.document_name = '';
		data.document_name_ar = '';
		data.document_description_ar = '';
		data.document_description = '';
		data.document = null;
		data.document_filename = '';
		this.enableEdit = false;
		this.editIndex = null;
	}

	deleteDocument(data, index, document) {
		if (this.enableEdit) {
			return;
		}
		if (data.product_document_id) {
			this.productService
				.updateDocument(
					this.productService.savedProduct.product_id,
					document.document_list[index],
					true
				)
				.then((res) => {
					document.document_list.splice(index, 1);
				});
		} else {
			document.document_list.splice(index, 1);
		}
	}

	accordionGroupChange(event) {
		const selectedValue = event.detail.value;
		this.productService.documentTypeList.forEach((element) => {
			if (selectedValue.includes(element.id.toString())) {
				element.isOpen = true;
			} else {
				if (Array.isArray(selectedValue)) element.isOpen = false;
			}
		});
	}

	async presentAlert(data, index, document) {
		if (this.enableEdit) {
			return;
		}
		const alert = await this.alertController.create({
			header: this.translate.instant('areYouSure'),
			message: this.translate.instant('deleteDocMsg'),
			buttons: [
				{
					text: this.translate.instant('Cancel'),
					role: 'cancel',
					handler: () => { },
				},
				{
					text: this.translate.instant('yes'),
					role: 'confirm',
					handler: () => {
						this.deleteDocument(data, index, document);
					},
				},
			],
		});
		await alert.present();
		const { role } = await alert.onDidDismiss();
	}

	skipStep2() {
		if (this.isEditPage) {
			this.router.navigate(['account-details/product/edit/product-preview']);
		} else {
			this.router.navigate(['account-details/add-new-product/product-preview']);
		}
	}

	setCoverImage(index, input) {
		if (input == 'mobile') {
			this.productService.mobilePreviewImages.forEach((element, idx) => {
				if (idx != index) {
					element.is_cover_image = false;
				} else {
					element.is_cover_image = true;
				}
			});
			this.productService.mobileImagesFile.forEach((element, idx) => {
				if (idx != index) {
					element.is_cover_image = false;
				} else {
					element.is_cover_image = true;
				}
			});
		}
		if (input == 'web') {
			this.productService.webPreviewImages.forEach((element, idx) => {
				if (idx != index) {
					element.is_cover_image = false;
				} else {
					element.is_cover_image = true;
				}
			});
			this.productService.webImagesFile.forEach((element, idx) => {
				if (idx != index) {
					element.is_cover_image = false;
				} else {
					element.is_cover_image = true;
				}
			});
		}
	}

	remove(data) {
		data.document = null;
		data.document_filename = '';
		this.productChanged = true;
	}

	replaceOldVariations(id, newVariations, mainParams) {
		let mainProductParams = JSON.parse(JSON.stringify(mainParams));
		newVariations.map((product) => {
			product['price_per_unit'] = product['price'];
			delete product['price'];
			delete product['Quantity'];
		});
		delete mainProductParams['configurations'];
		const params = {
			product_id: +id,
			SKUs: newVariations,
			product_desc_en: this.productForm
				? this.productForm.value.productDesc
				: this.productService.savedProduct.product_desc_en,
			product_desc_ar: this.productForm
				? this.productForm.value.productDescArabic
				: this.productService.savedProduct.product_desc_ar,
			product_sku: this.productService.savedProduct.product_sku, //this.productForm ? this.productForm.value.sku : this.productService.savedProduct.product_sku,
			brand_id: this.productForm
				? this.productForm.value.brand
				: this.productService.savedProduct.brand_id,
			unit_id: this.productForm
				? this.productForm.value.productUnit
				: this.productService.savedProduct.unit_id,
			secondary_unit_id: this.productForm
				? this.productForm.value.secondary_unit_id
				: this.productService.savedProduct.productSecondaryUnits,
			sub_class_id: this.productService.savedProduct.sub_class_id, //this.productForm.value.subClass ? +(this.productService.selectedSubclass.id.replace(/subclass/g, '')) : null,
			total_quantity: 0,
			availability_id: this.productForm
				? this.productForm.value.stockAvailability
				: this.productService.savedProduct.availability_id,
			...mainProductParams,
		};
		return new Promise((resolve, reject) => {
			this.subscription.add(
				this.dataService.post(URLS.replaceProduct, params).subscribe(
					(data) => {
						if (data && data['code'] == 200) {
							this.productService.savedProduct['skus'] = data['data']['skus'];
							if (this.productService.showCommonImage) {
								if (
									this.productService.savedProduct['skus'] &&
									this.productService.savedProduct['skus'].length
								) {
									this.productService.savedProduct['skus'].forEach(
										(subProduct) => {
											if (
												!subProduct.product_images_mobile ||
												(subProduct.product_images_mobile &&
													!subProduct.product_images_mobile.length)
											) {
												if (this.productService.mobilePreviewImages.length) {
													this.productService.mobilePreviewImages.forEach(
														(mobileImage) => {
															if (mobileImage.image_id) {
																let url = mobileImage.image_url;
																let urlAr = url.split('/products/');
																if (urlAr && urlAr.length > 1) {
																	let imageName = 'products/' + urlAr[1];
																	if (subProduct.images_mobile) {
																		subProduct.images_mobile.push(imageName);
																	} else {
																		subProduct.images_mobile = [];
																		subProduct.images_mobile.push(imageName);
																	}
																}
															}
														}
													);
												}
											}
											if (
												!subProduct.product_images_web ||
												(subProduct.product_images_web &&
													!subProduct.product_images_web.length)
											) {
												if (this.productService.webPreviewImages.length) {
													this.productService.webPreviewImages.forEach(
														(webImage) => {
															if (webImage.image_id) {
																let url = webImage.image_url;
																let urlAr = url.split('/products/');
																if (urlAr && urlAr.length > 1) {
																	let imageName = 'products/' + urlAr[1];
																	if (subProduct.images_web) {
																		subProduct.images_web.push(imageName);
																	} else {
																		subProduct.images_web = [];
																		subProduct.images_web.push(imageName);
																	}
																}
															}
														}
													);
												}
											}
										}
									);
								}
							}
							resolve(data);
						} else {
							this.toastService.displayToast(data['message'], null, 'danger');
							reject();
						}
					},
					(error) => {
						this.toastService.displayToast(error['message'], null, 'danger');
						reject();
					}
				)
			);
		});
	}
	getValidationsStatus(data) {
		if (
			data.document_name ||
			data.document_name_ar ||
			data.document_description_ar ||
			data.document_description ||
			data.document
		) {
			return true;
		}
		return false;
	}

	decimalCheck(event, oneDecimal?) {
		var charCode = event.which ? event.which : event.keyCode;
		if (
			charCode > 31 &&
			(charCode < 48 || charCode > 57) &&
			!(charCode == 46 || charCode == 8)
		) {
			return false;
		}
		if (event.key == '.' && event.target.value.includes(event.key)) {
			return false;
		}
		var dotPos = event.target.value.indexOf('.');
		if (
			oneDecimal &&
			event.target.value.includes('.') &&
			dotPos == event.target.value.length - 2
		) {
			return false;
		}
		if (
			event.target.value.includes('.') &&
			dotPos == event.target.value.length - 3
		) {
			return false;
		}
		return true;
	}

	ratioValidation() {
		if (this.productForm.value.productUnit && this.productForm.value.productSecondaryUnits && this.productForm.value.productSecondaryUnits === this.productForm.value.productUnit) {
			this.productForm.controls.conversionRatio.clearValidators();
			this.productForm.controls.conversionRatio.reset()
			this.productForm.controls.conversionRatio.updateValueAndValidity();
			this.showConversion = false;
		} else {
			this.productForm.controls.conversionRatio.setValidators([Validators.required, CustomValidator.invalidConversionRatio]);
			this.productForm.controls.conversionRatio.updateValueAndValidity();
			this.showConversion = true;
		}
	}
	selectCheckbox(event: any, data, id) {
		this.relatedProductisOpen = false;
		if (event.detail.checked) {
			let ids = [];
			if (this.selectedRelatedProducts.length > 0) ids = this.selectedRelatedProducts.map(product => product.product_id)
			if (!ids.includes(data.product_id)) {
				this.selectedRelatedProducts.push(data);
				this.isrelatedProductupdated = true;
				this.isShowSaveRelatedtn = true;
			}
		} else {
			var index = this.selectedRelatedProducts.findIndex(ele => ele.product_id == id);
			if (index >= 0) {
				this.selectedRelatedProducts.splice(index, 1);
				this.isrelatedProductupdated = true;

			}
		}
	}
	productaccordionGroupChange(i) {
		this.relatedProductisOpen = !this.relatedProductisOpen;
	}

	getCategoriesList() {
		this.subscription.add(
			this.dataService.get(URLS.categories).subscribe(
				(data) => {
					if (data && data['code'] == 200) {
						this.categoriesList = data['data'];

					} else {
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				},
				(error) => {
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	categoryChange(event) {
		this.categorySelect = event;
		this.showNoData = true;
		this.relatedProductisOpen = true;
		this.getRelatedProduct();
	}

	getRelatedProduct() {
		const params = {
			dropdown: true,
			product_id: this.productId ? this.productId : 0,
		};
		if (this.searchRelatedProductText) params['search'] = this.searchRelatedProductText;
		if (this.categorySelect) params['category_id'] = this.categorySelect

		this.relatedProduct = [];
		this.showLoader = true;
		this.subscription.add(
			this.dataService.get(URLS.relatedProductList, params).subscribe(
				(data) => {
					if (data && data['code'] == 200) {
						this.showLoader = false;
						this.relatedProduct = data['data']['result'];
						if (this.selectedRelatedProducts.length > 0) {
							let relatedIds = [];
							relatedIds = this.selectedRelatedProducts.map(obj => obj.product_id)
							let commonElements = [];
							commonElements = this.relatedProduct.filter(item1 => {
								if (relatedIds.includes(item1.product_id)) {
									item1['isChecked'] = true;
								}
							});
						}
					} else {
						this.showLoader = false;
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				},
				(error) => {
					this.showLoader = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	deleteProduct(data) {
		var index = this.selectedRelatedProducts.findIndex(ele => ele.product_id == data.product_id);
		var relatedindex = this.relatedProduct.findIndex(ele => ele.product_id == data.product_id);
		if (relatedindex >= 0) {
			this.relatedProduct[relatedindex]['isChecked'] = false;
		}
		if (index >= 0) {
			this.selectedRelatedProducts.splice(index, 1);
			this.isrelatedProductupdated = true;
			this.isShowSaveRelatedtn = true;

		}
	}

	search() {
		this.showNoData = true;
		this.getRelatedProduct();
	}

	clearSearch() {
		this.searchRelatedProductText = null;
		this.categorySelect = null;
		this.relatedProduct = [];
		this.showNoData = false;
	}

	saveRelatedProduct(showSuccess?) {
		if (!this.isrelatedProductupdated) return;
		const param = {
			"product_id": this.productService.savedProduct.product_id,
			"related_products": this.selectedRelatedProducts.length > 0 ? this.selectedRelatedProducts.map(obj => obj.product_id) : []
		}
		this.subscription.add(
			this.dataService.post(URLS.addRelatedProduct, param).subscribe(
				(data) => {
					if (data && data['code'] == 200) {
						this.showLoader = false;
						this.isrelatedProductupdated = false;
						this.isEditSaveRelated = false;
						this.isEditPage = false;
						this.productForm.markAsPristine();
						if (this.selectedRelatedProducts.length < 1) {
							this.isShowSaveRelatedtn = false;
						}

						if (showSuccess) {
							this.toastService.displayToast(data['message'], null, 'success');
						}
					} else {
						this.showLoader = false;
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				},
				(error) => {
					this.showLoader = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);

	}

	addTag() {
		if (this.tagList.length >= 15) {
			this.toastService.displayToast(this.translate.instant('relatedProducts.error.addTag'), null, 'danger')
			return;
		}
		if (this.tag != null && this.tag.trim() != '') {
			if (this.tagList.length > 0 && this.tagList.includes(this.tag)) {
				this.toastService.displayToast(this.translate.instant('relatedProducts.error.sameTagError'), null, 'danger')
				return;
			}
			if (this.productService.savedProduct && this.productService.savedProduct.product_id) { this.tagChanged = true; }
			this.tagList.push(this.tag);
			this.tag = '';
		}
	}

	removeTag(data, i) {
		if (this.productService.savedProduct && this.productService.savedProduct.product_id) { this.tagChanged = true; }
		this.tagList.splice(i, 1)
	}

	saveEditedRelatedProducts() {
		if (!this.isrelatedProductupdated) {
			this.toastService.displayToast(this.translate.instant('relatedProducts.error.noChangeRelated'), null, 'danger')
			return;
		}
		this.isEditSaveRelated = true;
		this.saveRelatedProduct(true);
	}

	updateTag() {
		if (!this.tagChanged) {
			this.toastService.displayToast("No changes made in meta tags", null, 'danger');
			return;
		}
		this.isEditMetaTags = true;
		this.addMetaTag(true);
	}

	addMetaTag(showSuccess?) {
		if (!this.tagChanged && this.productId) {
			return;
		}
		let param = {
			"product_id": this.productService.savedProduct.product_id,
			"product_tags": this.tagList
		}
		this.isEditTagLoader = true;
		this.subscription.add(
			this.dataService.post(URLS.updateMetaTag, param).subscribe(
				(data) => {
					if (data && data['code'] == 200) {
						this.isEditTagLoader = false;
						this.isEditMetaTags = false;
						this.tagChanged = false;
						if (showSuccess) {
							this.toastService.displayToast(data['message'], null, 'success');
						}
					} else {
						this.isEditTagLoader = false;
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				},
				(error) => {
					this.isEditTagLoader = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}


	public onSelectAll(data, array) {
		let arrayId = [];
		let dest_details = [];
		array.forEach((element) => {
			arrayId = element.configuration_value_id;
			dest_details.push(arrayId);
		});
		if (data.isSelectAllSupplier) {
			this.f[data.key].setValue(dest_details);
			let values = this.f[data.key].value;

			let selectedValue = [];
			let selectedValueId = [];
			if (data && data.valueOptions && data.valueOptions.length) {
				if (data.isMainConfig) {
					data.valueOptions.forEach((el) => {
						if (values && values.length) {
							values.forEach((element) => {
								if (el.id == element) {
									selectedValue.push(el.label);
									selectedValueId.push(el);
								}
							});
						}
					});
				} else {
					let obj = data.valueOptions.find((el) => el.id == values);
					if (obj) {
						selectedValue.push(obj.label);
						selectedValueId.push(obj);
					}
				}
			}

			data.selectedAttr = selectedValue;
			data.selectedAttrId = selectedValueId;
			data.isSelectAllSupplier = false;

		}
		else {
			this.f[data.key].setValue([]);
			data.selectedAttr = [];
			data.selectedAttrId = [];
			data.isSelectAllSupplier = true;

		}
		data.isSelectAllSupplier = !data.isSelectAllSupplier;

	}

	onSelectAllWarehouse() {
		if (this.isWarehouseSelect) {
			const selected = this.productService.warehouseList.map(item => item.id);
			this.productForm.controls.warehouseList.setValue(selected);
			this.isWarehouseSelect = false;
		}
		else {
			this.productForm.controls.warehouseList.setValue([]);
			this.isWarehouseSelect = true;
		}
		this.isWarehouseSelect = !this.isWarehouseSelect;
	}

	onSelectAllCountry() {
		if (this.isCountrySelect) {
			const selected = this.productService.manufacturingCountryList.map(item => item.id);
			this.productForm.controls.country.setValue(selected);
			this.isCountrySelect = false;
		}
		else {
			this.productForm.controls.country.setValue([]);
			this.isCountrySelect = true;
		}
		this.isCountrySelect = !this.isCountrySelect;
	}

	onChangeWarehouse() {
		let war = this.productForm.value.warehouseList;
		if (this.productService.warehouseList.length == war.length) {
			this.isWarehouseSelect = true;
		}
		else {
			this.isWarehouseSelect = false;
		}
	}

	onChangeCountry() {
		let country = this.productForm.value.country;
		if (this.productService.manufacturingCountryList.length == country.length) {
			this.isCountrySelect = true;
		}
		else {
			this.isCountrySelect = false;
		}
	}

}

