import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ToastService } from '../../_services/toast.service';

@Component({
    selector: 'app-return-cancel-status',
    templateUrl: './return-cancel-status.component.html',
    styleUrls: ['./return-cancel-status.component.scss'],
})
export class ChangeReturnCancelStatusComponent implements OnInit {
    public subscription = new Subscription();
    isEdit = false;
    editData: any;
    page: boolean;
    currencySymbol: any = '';
    selectInterface = { cssClass: 'custom-select-dd' };
    selectedOptionNotRequested: boolean;
    selectedOptionRejected: boolean;
    selectedOptionApproved: boolean;
    selectedOptionPending: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private dataService: ApiService,
        public commonService: CommonService,
        private modalService: ModalService,
        private modalCtrl: ModalController,
        private toastService: ToastService,
        private datePipe: DatePipe
    ) { }

    ngOnInit() {
        if (this.editData.product_status == "APPROVED") {
            this.selectedOptionApproved = true
        }
        if (this.editData.product_status == "REJECTED") {
            this.selectedOptionRejected = true
        }
        if (this.editData.product_status == "PENDING") {
            this.selectedOptionPending = true
        }
    }

    changeStatus(flag) {
        if (flag == "APPROVED") {
            this.selectedOptionApproved = true;
            this.selectedOptionRejected = false;
            this.selectedOptionPending = false;
        }
        if (flag == "REJECTED") {
            this.selectedOptionApproved = false;
            this.selectedOptionRejected = true;
            this.selectedOptionPending = false;
        }
        if (flag == "PENDING") {
            this.selectedOptionApproved = false;
            this.selectedOptionRejected = false;
            this.selectedOptionPending = true;
        }
    }
    ionViewWillEnter() {
        this.subscription = new Subscription();
    }


    onSubmit() {

        let status: any;
        if (this.selectedOptionApproved == true) {
            status = "APPROVED";
        }
        if (this.selectedOptionRejected == true) {
            status = "REJECTED";
        }
        if (this.selectedOptionPending == true) {
            status = "PENDING";
        }
        const params = {
            "invoice_item_id": this.editData.product_id,
            "return_approval_status": status,
            "is_return_request": this.page
        }
        this.subscription.add(
            this.dataService.post(URLS.orderStatusChange, params).subscribe(data => {
                if (data && data['code'] == 200) {
                    this.close(true);
                    this.toastService.displayToast(data['message'], null, 'success');
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    prev() {
        // this.addProductForm.reset();
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe();
    }



    close(updated?) {
        this.modalCtrl.dismiss(updated);
    }





}
