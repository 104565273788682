import { DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { ToastService } from 'src/app/_services/toast.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { ChangeReturnCancelStatusComponent } from '../return-cancel-status/return-cancel-status.component';

@Component({
    selector: 'app-return-orders-management',
    templateUrl: './return-orders-management.component.html',
    styleUrls: ['./return-orders-management.component.scss'],
})
export class ReturnOrdersManagementComponent implements OnInit {
    currencySymbol: string = "$";
    showSearch = false;
    showFilter = false;
    fromDate: any;
    toDate: any;
    perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
    selectInterface = { cssClass: 'custom-select-dd' };
    public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

    public count = 0;

    public itemsPerPage = 5;

    public currentPage = 1;

    columnHeaders = {
        order_id: 'Order #',
        date: 'Date',
        ship_to: 'Ship to',
        order_total: 'Order total',
        status: 'Status',
    }
    productList = [];
    @Input() showHeader: boolean;
    dateRange: FormGroup;
    page = 1;
    pageSize = 10;
    filterparams: any = {};
    sortType: string = "ASC";
    statusList = [];
    maxFromDate: any;
    minToDate: any;
    todaysDate = new Date();
    maxDate: any;
    statusFilter = 'all';
    searchText: any;
    showLoader: boolean = false;

    private subscription: Subscription = new Subscription();
    totalListCount: any;
    searchObj: any = {};
    status = null;

    constructor(
        private modalService: ModalService,
        public commonService: CommonService,
        private fb: FormBuilder,
        private router: Router,
        private alertController: AlertController,
        private targetElem: ElementRef,
        private datePipe: DatePipe,
        public toastService: ToastService,
        private dataService: ApiService,
        private translate: TranslateService,
        private platform: Platform,
        private modalCtrl: ModalController,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    ngOnInit() {
        const element = this.document.querySelector('#scrollId');
        if (element) {
            element.scrollIntoView();
        }
        this.dateRange = this.fb.group({
            fromDate: new FormControl(null),
            toDate: new FormControl(null),
        })
        let date = new Date();
        this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
        this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
        this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

        this.statusList = [
            { value: '', label: this.commonService.domDirectionLTR ? 'All' : 'الجميع' },
            { value: 'APPROVED', label: this.commonService.domDirectionLTR ? 'Approved' : 'وافق' },
            { value: 'REJECTED', label: this.commonService.domDirectionLTR ? 'Rejected' : 'مرفوض' },
            { value: 'PENDING', label: this.commonService.domDirectionLTR ? 'Pending' : 'قيد الانتظار' }
        ]
        this.filterparams = {
            page: this.page,
            size: this.pageSize
        }
        this.getPageList();
    }

    ionViewWillEnter() {
      this.getPageList();
    }

    onDateChange(datetime) {
        datetime.cancel(true);
    }


    onChange(event): void {
        this.currentPage = event;
    }

    async presentAlert(event) {
        const alert = await this.alertController.create({
            header: 'Are you sure want to delete this?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    },
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        this.deleteOrder(event);
                    },
                },
            ],
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();

    }

    deleteOrder(event): void {

    }

    key: string = "id";
    reverse: boolean = false;
    onSort(event, key: any) {
        this.key = key;

        const element = document.querySelectorAll('.sorting-icon');
        element.forEach(img => {
            img.setAttribute("src", "assets/icon/sort.svg")
        });

        let eventValue;
        if (event.target.className.includes("column-heading")) {
            eventValue = event.target.children[0];
        } else {
            eventValue = event.srcElement;
        }
        let order = eventValue.getAttribute("data-order")
        if (this.filterparams['sort_by'] != key) {
            order = "asc"
        }
        if (order === "asc") {
            this.sortType = "ASC";
            eventValue.setAttribute("data-order", "desc");
            eventValue.src = "assets/icon/sorting_asc.svg";
        } else if (order === "desc") {
            this.sortType = "DESC";
            eventValue.setAttribute("data-order", "");
            eventValue.src = "assets/icon/sorting_desc.svg";
        } else {
            delete this.filterparams['sort_by'];
            delete this.filterparams['sort_type'];
            eventValue.setAttribute("data-order", "asc");
            eventValue.src = "assets/icon/sort.svg";

        }
        if (order) {
            this.filterparams['sort_by'] = key;
            this.filterparams['sort_type'] = this.sortType;
        }
        this.page = 1;
        this.getPageList();
    }
    applyBtn() {
        this.searchObj = {}
        if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
            if (!this.fromDate) {
                this.toastService.displayToast(this.translate.instant('error.selectFromDate'), null, 'danger');
            }
            if (!this.toDate) {
                this.toastService.displayToast(this.translate.instant('error.selectToDate'), null, 'danger');
            }
            return
        }
        if (this.fromDate && this.toDate) {
            this.searchObj['start_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
            this.searchObj['end_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
        }
        if (this.searchObj['start_date'] && this.searchObj['end_date']) {
            if (this.searchObj['start_date'] == this.searchObj['end_date']) {
                this.toastService.displayToast("'From Date' and 'To Date' cannot be same.", null, 'danger');
                return
            }
        }

        if (this.statusFilter != 'all') {
            this.searchObj['return_approval_status'] = this.statusFilter;
        }

        this.searchObj['page'] = this.page;
        this.searchObj['size'] = this.pageSize;
        setTimeout(() => {
            this.getPageList(this.searchObj);
        }, 300);

    }

    openFilters() {
        this.showFilter = !this.showFilter;
    }

    clearSearch() {
        let searchData = {}
        this.searchText = null;
        searchData['search'] = this.searchText;
        this.getPageList();
    }

    // search text parameter in api
    search() {
        let searchObj = {}
        if (this.searchText && this.searchText.trim() == '') {
            this.searchText = null;
            return
        }
        if (this.searchText) {
            searchObj['search'] = this.searchText.trim();
            this.page = 1;
            this.currentPage = 1
            this.getPageList(searchObj);
        }
    }

    clearFilter() {
        this.statusFilter = 'all';
        this.fromDate = null;
        this.toDate = null;
    }

    getPageList(searchData?) {
        this.showLoader = true;
        let params = {
            page: this.page,
            size: this.pageSize
        }
        if (searchData) {
            params = {
                ...params,
                ...searchData
            }

        }
        if (this.filterparams['sort_by']) {
            params['sort_by'] = this.filterparams['sort_by'];
            params['sort_type'] = this.sortType;
        }

        this.subscription.add(
            this.dataService.get(URLS.returnOrderList, params).subscribe(data => {
                this.showLoader = false;
                const element = this.document.querySelector('#scrollId');
                if (element) {
                    element.scrollIntoView();
                }
                if (data['code'] == 200) {
                    this.totalListCount = parseInt(data['data']['total_count'])
                    this.productList = [];
                    if (data['data'] && data['data']['result'].length) {
                        let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
                        list.forEach((element) => {
                            let obj = {
                                product_id: element.invoice_item_id ? element.invoice_item_id : 0,
                                image: this.platform.is('desktop') ? element.web_image_url : element.mobile_image_url,
                                name: this.commonService.domDirectionLTR ? element.product_name_en : element.product_name_ar,
                                customer: element.customer_name ? element.customer_name : null,
                                email_id: element.customer_email ? element.customer_email : null,
                                bill_to: element.billing_address + ", " + element.billing_address_city + ", " +
                                    element.billing_address_region + ", " + element.billing_address_country + " - " +
                                    element.billing_address_zipcode,
                                ship_to: element.shipping_address + ", " + element.shipping_address_city + ", " +
                                    element.shipping_address_region + ", " + element.shipping_address_country + " - " +
                                    element.shipping_address_zipcode,
                                date: element.order_date ? element.order_date : null,
                                reason_of_cancel: element.reason_description,
                                qty: element.total_quantity_per_product ? element.total_quantity_per_product : 0,
                                order_total: element.total_order_amount ? element.total_order_amount : 0,
                                amount_refunded: element.refund_amount ? element.refund_amount : 0,
                                product_status: element.return_approval_status ? element.return_approval_status : null,//canceled
                            };
                            this.productList.push(obj);
                        });
                    }
                    this.showLoader = false;
                } else {
                    this.showLoader = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.showLoader = false;
                this.productList = [];
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    async editStatusDetails(product: any,bool) {
        const modal = await this.modalCtrl.create({
            component: ChangeReturnCancelStatusComponent,
            cssClass: 'edit-featured-product-modal',
            componentProps: {
                isEdit: true,
                editData: product,
                page:bool
            }
        });
        modal.present();
        modal.onDidDismiss().then((data) => {
            if (data && data.data) {
                this.getPageList();
            }
        })
    }


}
