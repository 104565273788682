import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ToastService } from '../../_services/toast.service';

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
	public subscription = new Subscription();
	isLoading = false;
	orderId: any;
	orderData: any;
	showLoader: boolean = false;

	constructor(
		private formBuilder: FormBuilder,
		private dataService: ApiService,
		private toastService: ToastService,
		public commonService: CommonService,
		private router: Router,
		private navCtrl: NavController,
		private activeRoute: ActivatedRoute
	) {
		this.orderId = this.activeRoute.snapshot.paramMap.get('id');
	}

	ngOnInit() {
		if (this.orderId) {
			this.getOrderData(this.orderId);
		}
	}


	getOrderData(id: any) {
		this.showLoader = true;
		const params = {
			invoice_id: id,
		};
		this.subscription.add(
			this.dataService.get(URLS.orderDetails, params).subscribe(
				(data) => {
					if (data['code'] == 200) {
						if (data['data']) {
							this.orderData = {
								shipping_address: data['data']['shipping_address'] ? data['data']['shipping_address'] : '-',
								billing_address: data['data']['billing_address'] ? data['data']['billing_address'] : '-',
								billing_address_city: data['data']['billing_address_city'] ? data['data']['billing_address_city'] : '',
								billing_address_country: data['data']['billing_address_country'] ? data['data']['billing_address_country'] : '',
								billing_address_region: data['data']['billing_address_region'] ? data['data']['billing_address_region'] : '',
								billing_address_zipcode: data['data']['billing_address_zipcode'] ? data['data']['billing_address_zipcode'] : '',
								shipping_address_city: data['data']['shipping_address_city'] ? data['data']['shipping_address_city'] : '',
								shipping_address_country: data['data']['shipping_address_country'] ? data['data']['shipping_address_country'] : '',
								shipping_address_region: data['data']['shipping_address_region'] ? data['data']['shipping_address_region'] : '',
								shipping_address_zipcode: data['data']['shipping_address_zipcode'] ? data['data']['shipping_address_zipcode'] : '',
								customer_email: data['data']['customer_email'] ? data['data']['customer_email'] : '-',
								customer_name: data['data']['customer_name'] ? data['data']['customer_name'] : '-',
								quotation_no: data['data']['quotation_no'],
								mobile_no: data['data']['mobile_no'] ? data['data']['mobile_no'] : '-',
								discount_applied: data['data']['grand_total'] && data['data']['grand_total']['total_discount'] ? data['data']['grand_total']['total_discount'] : '0.00',
								coupon_code: data['data']['grand_total'] && data['data']['grand_total']['coupon_code'] ? data['data']['grand_total']['coupon_code'] : '',
								order_date: data['data']['order_date'] ? data['data']['order_date'] : '',
								order_id: data['data']['order_no'] ? data['data']['order_no'] : '-',
								paid_order_amount: data['data']['paid_order_amount'] ? data['data']['paid_order_amount'] : '-',
								payment_type: data['data']['payment_method'] ? data['data']['payment_method'] : '-',
								shipping_charge: data['data']['grand_total'] && data['data']['grand_total']['shipping_charges'] ? data['data']['grand_total']['shipping_charges'] : '0.00',
								total_order_amount: data['data']['grand_total'] && data['data']['grand_total']['total_price'] ? data['data']['grand_total']['total_price'] : '0.00',
								vat_amount: data['data']['grand_total'] && data['data']['grand_total']['vat'] ? data['data']['grand_total']['vat'] : '0.00',
								vat_percent: data['data']['grand_total'] && data['data']['grand_total']['vat_percentage'] ? data['data']['grand_total']['vat_percentage'] : '',
								total_cal: data['data']['total_commission'] ? data['data']['total_commission'] : '',
								supplier_name: this.commonService.domDirectionLTR ? data['data']['supplier_name_en'] : data['data']['supplier_name_ar'],
								order_details: data['data']['order_details'],
								admin_comm: data['data']['admin_comm'],
								invoice_id: data['data']['invoice_id'],
								waybill_no: data['data']['waybill_no'],
								shipping_status: data['data']['shipping_status'] ? data['data']['shipping_status'] : 'CONFIRMED',
								item_subtotal: data['data']['grand_total'] && data['data']['grand_total']['items_sub_total'] ? data['data']['grand_total']['items_sub_total'] : '-',
								grand_total: data['data']['grand_total'] ? data['data']['grand_total'] : null,
							}
							if (this.orderData.grand_total && this.orderData.grand_total.length) {
								this.orderData.grand_total.sort((a, b) => a.sequence - b.sequence);
							}
						}
						this.showLoader = false;
					} else {
						this.showLoader = false;
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
						this.navCtrl.navigateBack(['/account-details/orders-management']);
					}
				},
				(error) => {
					this.navCtrl.navigateBack(['/account-details/orders-management']);
					this.showLoader = false;
					this.toastService.displayToast(error['message'], null, 'danger');
				}
			)
		);
	}

	ionViewWillLeave() {
		this.subscription.unsubscribe();
	}

	shippingStatus(orderData, productLevel?) {
		let status = orderData.shipping_status;
		switch (status) {
			case 'NOT_DELIVERED':
			case 'NOT_YET_DELIVERED':
				return 'btn-grey product-status-btn'
			case 'DISPATCHED':
			case 'CONFIRMED':
				return 'btn-green product-status-btn'
			case 'DELIVERED':
				return 'btn-black product-status-btn'
			case 'CANCELLED':
				return 'btn-red product-status-btn'
			case 'RETURN_REQUESTED':
			case 'CANCELLATION_REQUESTED':
			case 'RETURNED':
				return 'btn-orange return-request-btn'
			default:
				return 'btn-green product-status-btn'
		}
	}
	shippingStatusLabel(orderData) {
		let status = orderData.shipping_status;
		switch (status) {
			case 'NOT_YET_DELIVERED':
			case 'NOT_DELIVERED':
				return 'notYetDelivered';
			case 'DISPATCHED':
				return 'dispatched';
			case 'CONFIRMED':
				return 'confirmed';
			case 'DELIVERED':
				return 'delivered';
			case 'CANCELLED':
				return 'canceled';
			case 'RETURN_REQUESTED':
				return 'returnRequested'
			case 'CANCELLATION_REQUESTED':
				return 'cancellationRequested'
			case 'RETURNED':
				return 'returned'
			default:
				return '-'
		}
	}
}
