import { Component, OnInit, Input, ElementRef, Inject } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../_services/common.service';
import { AlertController } from '@ionic/angular';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from '../../_services/api-data.service';
import { ToastService } from '../../_services/toast.service';
import { URLS } from '../../_config/api.config';
import { Router } from '@angular/router';
import { DatePipe, DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-orders-management',
  templateUrl: './orders-management.component.html',
  styleUrls: ['./orders-management.component.scss'],
})
export class OrdersManagementComponent implements OnInit {
  public subscription = new Subscription();
  dateRange: FormGroup;
  currencySymbol: string = "$";
  showSearch = false;
  showFilter = false;
  fromDate: any;
  toDate: any;
  statusSelect: any = "all";
  isLoading: boolean = false;
  searchText: any;
  showLoader: boolean = false;
  pageno = 1;
  pagesize = 10;
  filterparams: any;
  totalOrderCount: number = 0;
  orderListData: any[] = [];
  statusList = [];
  perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
  selectInterface = { cssClass: 'custom-select-dd' };
  public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

  public count = 0;

  public itemsPerPage = 10;

  public currentPage = 1;
  searchObj: any;
  maxFromDate: any;
  todayDate: any;

  maxDate: any;
  minToDate: any;
  todaysDate = new Date();

  columnHeaders = {
    order_id: 'Order #',
    date: 'Date',
    ship_to: 'Ship to',
    order_total: 'Order total',
    status: 'Status',
  }

  @Input() showHeader: boolean;
  constructor(
    private modalService: ModalService,
    public commonService: CommonService,
    private router: Router,
    private targetElem: ElementRef,
    private alertController: AlertController,
    private fb: FormBuilder,
    private dataService: ApiService,
    private toastService: ToastService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    const element = this.document.querySelector('#scrollId');
    if (element) {
      element.scrollIntoView();
    }
    let date = new Date();
    this.todayDate = date.setDate(this.todaysDate.getDate());
    this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
    this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
    this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

    this.dateRange = this.fb.group({
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
    })

    this.searchText = null;
    this.filterparams = {
      page: this.pageno,
      size: this.pagesize
    }
    this.statusList = [
      { value: 'all', label: this.commonService.domDirectionLTR ? 'All' : 'الجميع' },
      { value: 'DISPATCHED', label: this.commonService.domDirectionLTR ? 'Dispatched' : 'مُرسَل' },
      { value: 'CONFIRMED', label: this.commonService.domDirectionLTR ? 'Confirmed' : 'مؤكد' },
      { value: 'DELIVERED', label: this.commonService.domDirectionLTR ? 'Delivered' : 'تم التوصيل' },
      { value: 'CANCELLED', label: this.commonService.domDirectionLTR ? 'Canceled' : 'ألغيت' },
    ]

    this.getOrderList(this.filterparams, false);
  }

  ionViewWillEnter() {
  }

  openFilters() {
    this.showFilter = !this.showFilter;
  }

  search() {
    this.filterparams = {
      page: this.pageno,
      size: this.pagesize
    }
    if (this.searchText && this.searchText.trim() == '') {
      this.searchText = null;
      return
    }
    if (this.searchText) {
      this.filterparams['search'] = this.searchText.trim();
      this.getOrderList(this.filterparams, false);
    }
  }

  // select data per page
  selectPageLength(event) {
    let selectPageSize = event.detail.value;
    this.itemsPerPage = event.detail.value
    this.pagesize = selectPageSize;
    this.currentPage = 1;
    this.filterparams['page'] = 1;
    this.filterparams['size'] = selectPageSize;
    this.getOrderList(this.filterparams, false)
  }

  onPageChange(event): void {
    this.currentPage = event;
    this.filterparams['page'] = this.currentPage;
    this.filterparams['size'] = this.itemsPerPage;
    this.getOrderList(this.filterparams, false)
  }

  clearSearch() {
    this.searchText = null;
    let params = {
      page: 1,
      size: this.pagesize
    }
    delete this.filterparams['search']
    this.getOrderList(params, false);
  }

  clearFilter() {
    this.fromDate = null;
    this.toDate = null;
    this.statusSelect = 'all';
    this.searchObj = {
      page: 1,
      size: this.pagesize
    };
    this.dateRange.reset();
    setTimeout(() => {
      this.getOrderList(this.searchObj, true);
    }, 300);
  }

  onDateChange(datetime) {
    datetime.cancel(true);
  }

  applyBtn() {
    this.searchObj = {}
    if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
      if (!this.fromDate) {
        this.toastService.displayToast(this.translate.instant('error.selectFromDate'), null, 'danger');
    }
    if (!this.toDate) {
        this.toastService.displayToast(this.translate.instant('error.selectToDate'), null, 'danger');
    }
      return
    }
    if (this.fromDate && this.toDate) {
      this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
    }

    // Required code
    // if (this.searchObj['from_date'] && this.searchObj['to_date']) {
    //   if (this.searchObj['from_date'] == this.searchObj['to_date']) {
    //     this.toastService.displayToast("'From Date' and 'To Date' cannot be same.", null, 'danger');
    //     return
    //   }
    // }

    if (this.statusSelect != "all") {
      this.searchObj['shipping_status'] = this.statusSelect;
    }

    this.searchObj['page'] = this.pageno;
    this.searchObj['size'] = this.pagesize;
    setTimeout(() => {
      this.getOrderList(this.searchObj, false);
    }, 300);

  }

  gotoOrderDetails(event, order_id) {
    event.stopPropagation();
    event.preventDefault();
    this.router.navigate(['/account-details/orders-management/order-details', order_id]);
  }

  //////////// Get Order List //////////////
  getOrderList(params?, clearFilter?: boolean) {
    this.showLoader = true;
    this.orderListData = [];
    if (this.searchObj) {
      params = { ...this.searchObj, ...params }
    }
    if (clearFilter) {
      params = {};
      params['page'] = this.pageno;
      params['size'] = this.pagesize;
    }
    this.subscription.add(
      this.dataService.get(URLS.orderList, params).subscribe(data => {
        if (data['code'] == 200) {
          this.totalOrderCount = parseInt(data['data']['total_count'])
          this.orderListData = [];
          if (data['data'] && data['data']['result'].length) {
            let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
            list.forEach((order) => {
              let address = order;
              let obj = {
                order_id: order.order_no ? order.order_no : '',
                quotation_no: order.quotation_no ? order.quotation_no : '--',
                invoice_id: order.invoice_id ? order.invoice_id : '',
                invoice_url: order.invoice_url ? order.invoice_url : '',
                customer_name: order.customer_name ? order.customer_name : '',
                customer_email: order.customer_email ? order.customer_email : '',
                mobile_no: order.mobile_no ? order.mobile_no : '',
                address_title: order.address_title ? order.address_title : '',
                shipping_address: order.shipping_address ? order.shipping_address : '',
                shipping_address_country: order.shipping_address_country ? order.shipping_address_country : '',
                shipping_address_city: order.shipping_address_city ? order.shipping_address_city : '',
                shipping_address_region: order.shipping_address_region ? order.shipping_address_region : '',
                shipping_address_zipcode: order.shipping_address_zipcode ? order.shipping_address_zipcode : '',
                cancellation_status: order.cancellation_status ? order.cancellation_status : '',
                return_status: order.return_status ? order.return_status : '',
                city_en: order.city_en ? order.city_en : '',
                city_ar: order.city_ar ? order.city_ar : '',
                region_ar: order.region_ar ? order.region_ar : '',
                region_en: order.region_en ? order.region_en : '',
                country_en: order.country_en ? order.country_en : '',
                country_ar: order.country_ar ? order.country_ar : '',
                zipcode: order.zipcode ? order.zipcode : '',
                order_date: order.order_date ? order.order_date : '',
                total_order_amount: order.total_order_amount ? order.total_order_amount : '',
                items_count: order.pro_count ? order.pro_count : '-',
                discount_amount: order.discount_amount ? order.discount_amount : '',
                coupon_code: order.coupon_code ? order.coupon_code : '',
                coupon_value: order.coupon_value ? order.coupon_value : '',
                payment_type: order.payment_type ? order.payment_type : '',
                pro_count: order.pro_count ? order.pro_count : '',
                vat_amount: order.vat_amount ? order.vat_amount : '',
                ship_amount: order.ship_amount ? order.ship_amount : '',
                commission_amount: order.commission_rate ? order.commission_rate : '',
                commission_value: order.total_commission_amount ? order.total_commission_amount : '0.00',
                shipping_status: order.shipping_status ? order.shipping_status : 'CONFIRMED',
              };
              this.orderListData.push(obj);
            });
          }
          this.showLoader = false;
        } else {
          this.showLoader = false;
          this.toastService.displayToast(data['message'], null, 'danger');
        }
      }, error => {
        this.showLoader = false;
        this.orderListData = [];
        this.toastService.displayToast(error['message'], null, 'danger');
      })
    )
  }

  key: string = "id";
  sortType: string = "ASC";

  onSort(event, key: any) {

    if (!this.orderListData.length) {
      return;
    }
    this.key = key;

    const element = document.querySelectorAll('.sorting-icon');
    element.forEach(img => {
      img.setAttribute("src", "assets/icon/sort.svg")
    });

    let eventValue;
    if (event.target.className.includes("column-heading")) {
      eventValue = event.target.children[0];
    } else {
      eventValue = event.srcElement;
    }
    let order = eventValue.getAttribute("data-order")
    if (this.filterparams['sort_by'] != key) {
      order = "asc"
    }
    if (order === "asc") {
      this.sortType = "ASC";
      eventValue.setAttribute("data-order", "desc");
      eventValue.src = "assets/icon/sorting_asc.svg";
    } else if (order === "desc") {
      this.sortType = "DESC";
      eventValue.setAttribute("data-order", "");
      eventValue.src = "assets/icon/sorting_desc.svg";
    } else {
      delete this.filterparams['sort_by'];
      delete this.filterparams['sort_type'];
      eventValue.setAttribute("data-order", "asc");
      eventValue.src = "assets/icon/sort.svg";

    }
    if (order) {
      this.filterparams['sort_by'] = key;
      this.filterparams['sort_type'] = this.sortType;
    }
    this.getOrderList(this.filterparams, false)
  }

  onChange(event): void {
    this.currentPage = event;
    this.pageno = event;
    let params = {
      page: event,
      size: this.pagesize
    }
    this.getOrderList(params, false);
  }

  orderStatus(order) {
    switch (order.shipping_status) {
      case 'NOT_YET_DELIVERED':
      case 'DISPATCHED':
      case 'CONFIRMED':
        return 'btn-green'
      case 'DELIVERED':
        return 'btn-black'
      case 'CANCELED':
        return 'btn-red'
      default:
        return 'btn-green'
    }
  }

  statusLabel(status) {
    switch (status) {
      case 'PARTIALLY_CANCELLED':
        return 'partiallyCancelled';
      case 'CANCELLED':
        return 'canceled';
      case 'PARTIALLY_RETURNED':
        return 'partiallyReturned'
      case 'RETURNED':
        return 'returned'
      default:
        return '-'
    }
  }
}
