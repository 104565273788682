import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SECRET_KEY, URLS } from '../_config/api.config';
// import * as crypto from 'crypto-js';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  // secret key to encrypt the login credentails
  private secretKey: string = SECRET_KEY;
  private token = false;
  // observable user details
  // public currentUserSubject: BehaviorSubject<any>;

  // onserveable logged in status
  public loggedInStatus;

  // logged in user tpe
  // public currentUser: Observable<any>;

  // login api url
  // private loginUrl = URLS.login;

  // logout api url
  // private logoutUrl = URLS.logout;
  constructor(
    private http: HttpClient,
  ) {
    // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.loggedInStatus = new BehaviorSubject<boolean>(this.hasToken());
    // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.storage.get('currentUser')));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  //   public get currentUserValue(): any {
  //     return this.currentUserSubject.value;
  // }


  /**
   * if we have token the user is loggedIn
   * @returns {boolean}
   */
  private hasToken(): boolean {

    // return this.token;
    return !!localStorage.getItem('token');
  }



  /**
  *
  * @returns {Observable<T>}
  */
  public isLoggedIn() {
    return this.loggedInStatus.asObservable();
  }

  /**
   * Login the user then tell all the subscribers about the new status
   */
  //   public login(loginData: any) {
  //     const cipherData = crypto.AES.encrypt(JSON.stringify(loginData), this.secretKey).toString();
  //     // const cipherPasswrd = crypto.AES.encrypt(password, this.secretKey).toString();
  //     return  this.http.post<any>(`${this.loginUrl}`, {data: cipherData})
  //       .pipe(map(user => {
  //         // login successful if there's a jwt token in the response
  //         if (user && user.code === 200) {
  //           this.storage.set('token', user.data.token);
  //           this.loggedInStatus.next(true);
  //             // store user details and jwt token in local storage to keep user logged in between page refreshes
  //           //  localStorage.setItem('currentUser', JSON.stringify(user));
  //           //  this.currentUserSubject.next(user);
  //           return user;
  //         } else {
  //           return user;
  //         }
  //       }));
  //   }

  public logout() {


    return this.http.get<any>(URLS.logout);

  }
}
