import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { URLS } from '../../_config/api.config';
import { ToastService } from '../../_services/toast.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-view-product-review',
    templateUrl: './view-product-review.component.html',
    styleUrls: ['./view-product-review.component.scss'],
})
export class ViewProductReviewComponent implements OnInit {
    dataId: any;
    public subscription = new Subscription();
    reviewDetailsData: any[] = [];
    isLoading: boolean = false;
    showLoader: boolean = true;
    isDesktop: boolean = false;

    public review = [
        {
            name: "Lorem ipsum dolor sit amet",
            verified: true,
            date: "Review by Michael D, 7/7/2021",
            rating: 4.4,
            comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id et arcu, dictum nisl. Duis suspendisse gravida in ipsum dictumst scelerisque rutrum interdum iaculis. Lacinia pulvinar mattis magna aliquet cursus urna est sed elit. Fames vel pretium neque, eget aliquam amet",
            review_status: 1
        }
    ];

    actionList = [];

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private dataService: ApiService,
        private toastService: ToastService,
        private platform: Platform,
        public commonService: CommonService,
        private translate: TranslateService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.dataId = this.activeRoute.snapshot.paramMap.get('id');
        this.isDesktop = this.platform.is('desktop')
    }

    ngOnInit(): void {
        const element = this.document.querySelector('#scrollId');
        if (element) {
            element.scrollIntoView();
        }
        this.actionList = [
            { value: 'APPROVED', name: this.translate.instant('approved'), disabled: false },
            { value: 'PENDING', name: this.translate.instant('pending'), disabled: true },
            { value: 'REJECTED', name: this.translate.instant('rejected'), disabled: false }
        ]
        this.getReviewDetails();
    }

    back() {
        this.router.navigate(['account-details/product-reviews']);
    }

    //////////// Get Product Review Details //////////////
    getReviewDetails() {
        this.showLoader = true;
        this.reviewDetailsData = [];
        let params = {};
        if (this.dataId) {
            params['review_id'] = this.dataId;
        }

        this.subscription.add(
            this.dataService.get(URLS.reviewDetails, params).subscribe(data => {
                this.showLoader = false;
                if (data['code'] == 200) {
                    if (data['data']) {
                        let reviewDetails = data['data'];
                        this.reviewDetailsData = [];
                        let obj = {
                            product_id: reviewDetails.product_id ? reviewDetails.product_id : '',
                            review_id: reviewDetails.review_id ? reviewDetails.review_id : '',
                            is_verified: reviewDetails.is_verified_customer,
                            customer_rating: reviewDetails.customer_rating ? reviewDetails.customer_rating : '-',
                            review_title: reviewDetails.review_title ? reviewDetails.review_title : '',
                            customer_review: reviewDetails.customer_review ? reviewDetails.customer_review : '-',
                            customer_name: reviewDetails.customer_name ? reviewDetails.customer_name : '',
                            created_at: reviewDetails.created_at ? reviewDetails.created_at : '',
                            approval_status: reviewDetails.approval_status ? (reviewDetails.approval_status).toUpperCase() : '-',
                            image_url: this.isDesktop ? reviewDetails.web_image_url : reviewDetails.mobile_image_url,
                            product_name: this.commonService.domDirectionLTR ? reviewDetails.product_name_en ? reviewDetails.product_name_en : '' : reviewDetails.product_name_ar ? reviewDetails.product_name_ar : ''
                        };
                        this.reviewDetailsData.push(obj);
                    }
                    const element = this.document.querySelector('#scrollId');
                    if (element) {
                        element.scrollIntoView();
                    }
                } else {
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.showLoader = false;
                this.reviewDetailsData = [];
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    onStatusChange(event: any, reviewId) {
        let params = {};
        if (reviewId) {
            params["review_id"] = Number(reviewId);
        }
        if (event.value) {
            params["approval_status"] = event.value;
        }
        if (reviewId && event.value)
            this.updateStatus(params);
    }

    /////////// Update Product Review Status /////////////
    updateStatus(params: any) {

        this.isLoading = true;
        this.subscription.add(
            this.dataService.put(URLS.reviewUpdate, params).subscribe(data => {
                if (data['code'] == 200 && data['data']) {
                    this.isLoading = false;
                } else {
                    this.isLoading = false;
                    this.toastService.displayToast(data['message'], null, 'danger');
                }
            }, error => {
                this.isLoading = false;
                this.toastService.displayToast(error['message'], null, 'danger');
            })
        )
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe();
    }

}
