import { Component, OnInit, Input, Inject } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../_services/common.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../_services/api-data.service';
import { ToastService } from '../../_services/toast.service';
import { URLS } from '../../_config/api.config';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DatePipe, DOCUMENT } from '@angular/common';
import { TwoFAToggleComponent } from '../two-fa-toggle/two-fa-toggle.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public subscription = new Subscription();
  currencySymbol: string = "$";
  showFilter: boolean = false;
  totalNotificationsCount: any;
  isLoading: boolean = false;
  showLoader: boolean = false;

  notificationsData = [];

  @Input() showHeader: boolean;
  constructor(
    private modalService: ModalService,
    public commonService: CommonService,
    private dataService: ApiService,
    private toastService: ToastService,
    private alertController: AlertController,
    private router: Router,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    let is2fa = localStorage.getItem('is_2fa_enabled');
    if (is2fa && is2fa == 'true') {
      this.commonService.twoFaEnabled = true;
    }
    const element = this.document.querySelector('#scrollId');
    if (element) {
      element.scrollIntoView();
    }
    this.showHeader = true;
    this.getNotificationsList();
  }

  ionViewWillEnter() {
    this.showHeader = true;
  }

  openFilters() {
    this.showFilter = !this.showFilter;
  }

  getNotificationsList() {
    const params = {
      dropdown: true,
    };
    this.showLoader = true;
    this.subscription.add(
      this.dataService.get(URLS.getNotifications, params).subscribe(
        (data) => {
          if (data['code'] == 200) {
            this.totalNotificationsCount = parseInt(data['data']['total_count'])
            if (data['data'] && data['data']['notificationDetails'].length) {
              let list = data['data'] && data['data']['notificationDetails'].length ? data['data']['notificationDetails'] : [];
              this.notificationsData = [];
              list.forEach((data) => {
                let obj = {
                  notification_type_id: data.notification_type_id ? data.notification_type_id : '',
                  notification_type: this.commonService.domDirectionLTR ? data.notification_type_en ? data.notification_type_en : '' : data.notification_type_ar ? data.notification_type_ar : '',
                  is_enabled: data.is_enabled,
                  img_url: data.concat ? data.concat : '',
                };
                this.notificationsData.push(obj);
              });
            }
            this.showLoader = false;
          } else {
            if (data['code'] != 204) {
              this.showLoader = false;
              this.toastService.displayToast(data['message'], null, 'danger');
            }
          }
        },
        (error) => {
          this.showLoader = false;
          this.toastService.displayToast(error['message'], null, 'danger');
        }
      )
    );
  }

  async presentAlert(notification) {
    notification.is_enabled = !notification.is_enabled;
    let deactivateMsg = this.commonService.domDirectionLTR ? "You want to deactivate this notification?" : "تريد إلغاء تنشيط هذ";
    let activateMsg = this.commonService.domDirectionLTR ? "You want to activate this notification?" : "تريد تفعيل هذا";
    const alert = await this.alertController.create({
      header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
      message: notification.is_enabled ? deactivateMsg : activateMsg,
      buttons: [
        {
          text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
          role: 'confirm',
          handler: () => {
            let isEnabled;
            if (notification.is_enabled) {
              isEnabled = false;
            } else {
              isEnabled = true;
            }
            let params = {
              is_enabled: isEnabled,
              notification_type_id: notification.notification_type_id,
            };
           // notification.is_enabled = !notification.is_enabled;
            this.updateNotification(params, notification);
          },
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
  }

   /////////// Update Warehouse data /////////////
   updateNotification(params: any, notification: any) {

    this.isLoading = true;
    this.subscription.add(
      this.dataService.put(URLS.updateNotifications, params).subscribe(data => {
        if (data['code'] == 200 && data['data']) {
          this.isLoading = false;
          notification.is_enabled = !notification.is_enabled;
        } else {
          this.isLoading = false;
          this.toastService.displayToast(data['message'], null, 'danger');
        }
      }, error => {
        this.isLoading = false;
        this.toastService.displayToast(error['message'], null, 'danger');
      })
    )
  }

  toggle2fa() {
    let options: any = {
      id: "twoFaModal",
      cssClass: 'two-fa-modal',
    };
    options['component'] = TwoFAToggleComponent;
    this.modalService.openModal(options)
  }
}
