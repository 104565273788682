import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-payoutHistoryDetails',
	templateUrl: './payoutHistoryDetails.component.html',
	styleUrls: ['./payoutHistoryDetails.component.scss'],
})
export class PayoutHistoryDetailsComponent implements OnInit {

	@Input() payoutHistoryData;
	constructor(private modalCtrl: ModalController) { }

	ngOnInit() { }

	dissmis() {
		this.modalCtrl.dismiss();
	}

}
