import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Platform, IonInput } from '@ionic/angular';
import { Validators, FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidator } from '../../_common/customvalidators';
import { Router, NavigationExtras, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommonService } from '../../_services/common.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../_services/api-data.service';
import { URLS } from '../../_config/api.config';
import { filter } from 'rxjs/operators'
import { ToastService } from '../../_services/toast.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('usernameInput', { static: false }) usernameInput!: IonInput;

  public desktopView: boolean = false;
  passwordType = 'password';
  rememberMe: boolean;

  isLoading = false;
  public validation_messages = {
    username: [
      { type: 'required', message: 'loginComponent.emailPhoneRequired' },
      { type: 'invalidEmailPhone', message: 'loginComponent.invalidEmailPhone' }
    ],
    password: [
      { type: 'required', message: 'loginComponent.passwordRequired' },
    ],
  }

  loginFromGroup: FormGroup;
  private subscription: Subscription = new Subscription();
  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private router: Router,
    public commonService: CommonService,
    private dataService: ApiService,
    private toastService: ToastService,
    public cookieService: CookieService,
  ) {
    this.desktopView = this.platform.is('desktop');
  }

  ngOnInit() {
    this.subscription = new Subscription();
    this.loginFromGroup = this.initializeLoginFromGroup();
    this.rememberMeCheck()
    setTimeout(() => {
      if (this.usernameInput) {
        this.usernameInput.setFocus();
      }
    }, 1000);
  }

  rememberMeCheck() {
    let remember = this.cookieService.get('rememberMe');
    if (remember && remember == 'yes') {
      let user = this.cookieService.get('info');
      this.rememberMe = true;
      if (user) {
        let decryptedData = this.commonService.decryptData(user)
        this.loginFromGroup = this.initializeLoginFromGroup(JSON.parse(decryptedData));
      }
    } else {
      this.rememberMe = false;
    }
  }

  ionViewWillEnter() {
    this.rememberMeCheck()
    this.isLoading = false;
    this.subscription = new Subscription();
    this.loginFromGroup = this.initializeLoginFromGroup();

  }
  get f1() { return this.loginFromGroup.controls; }

  login() {
    for (const i in this.f1) {
      this.f1[i].markAsDirty();
      this.f1[i].updateValueAndValidity();
      this.f1[i].markAsTouched();
    }

    if (this.loginFromGroup.invalid) {
      return
    }
    const userInfo = {
      user_name: this.f1.username.value,
      password: this.f1.password.value
    }
    const encryptedData = this.commonService.encryptData(JSON.stringify(userInfo));
    if (!encryptedData) {
      return;
    }
    const params = {
      data: encryptedData
    }
    localStorage.setItem('userInfo', encryptedData);
    const navigationExtras: NavigationExtras = {
      queryParams: {
        from: 'login',
      },
    };
    this.isLoading = true;
    this.subscription.add(
      this.dataService.post(URLS.login, params).subscribe(data => {
        if (data['code'] == 200) {
          if (this.rememberMe) {
            this.cookieService.set('rememberMe', 'yes', null, null, null, null, 'Lax');
            this.cookieService.set('info', encryptedData, null, null, null, null, 'Lax');
          } else {
            this.cookieService.set('rememberMe', 'no', null, null, null, null, 'Lax');
            this.cookieService.delete('info');

          }
          if (data['data']) {
            if (data['data']['isCompanyData']) {
              localStorage.setItem('companyData', 'true');
            } else {
              localStorage.setItem('companyData', 'false');
            }
            if (data['data']['isApproved']) {
              localStorage.setItem('isApproved', 'true');
            } else {
              localStorage.setItem('isApproved', 'false');
            }
            if (data['data']['session_token']) {
              this.commonService.accessToken = data['data']['session_token'];
            }
            if (data['data']['uuid']) {
              localStorage.setItem('uuid', data['data']['uuid']);
            }
            if (data['data']['mobile_no']) {
              localStorage.setItem('mobile_no', data['data']['mobile_no']);
            }
            if (!data['data']['is_2fa_enabled']) {
              localStorage.setItem('is_2fa_enabled', data['data']['is_2fa_enabled'] ? 'true' : 'false');
              this.commonService.twoFaEnabled = false;
              localStorage.setItem('accessToken', data['data']['session_token'])
              this.commonService.getProfileData();
              localStorage.setItem('OTP_Verified', 'true');
            }
            if (this.f1.username.value) {
              localStorage.setItem('user_name', this.f1.username.value);
            }
          }
          this.isLoading = false;
          if (!this.rememberMe) {
            this.loginFromGroup.reset();
          }
          if (data['data'] && data['data']['is_2fa_enabled']) {
            localStorage.setItem('is_2fa_enabled', data['data']['is_2fa_enabled'] ? 'true' : 'false');
            this.commonService.twoFaEnabled = true;
            this.router.navigate(['/two-factor-authentication'], navigationExtras);
          } else {
            this.router.navigate(['/account-details/marketplace-dashboard']);
          }
        } else {
          this.toastService.displayToast(data['message'], null, 'danger');
          this.isLoading = false;
        }
      }, error => {
        this.toastService.displayToast(error['message'], null, 'danger');
        this.isLoading = false;
      })
    )
  }

  //move to relevant page
  goToPage(page) {
    this.router.navigate([page]);
  }

  getOtp() {
    const params = {
      "user_name": this.f1.username.value,
      "purpose_type": "2FA",
    }
    const navigationExtras: NavigationExtras = {
      queryParams: {
        from: 'login',
      },
    };
    this.isLoading = true;
    this.subscription.add(
      this.dataService.post(URLS.generateOtp, params).subscribe(data => {
        if (data['code'] == 200) {
          if (data['data']) {
            if (data['data']['uuid']) {
              localStorage.setItem('uuid', data['data']['uuid']);
            }
          }
          this.loginFromGroup.reset();
          this.router.navigate(['/two-factor-authentication'], navigationExtras);
        } else {
          this.toastService.displayToast(data['message'], null, 'danger');
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.toastService.displayToast(error['message'], null, 'danger');
      })
    )
  }

  initializeLoginFromGroup(data?) {
    return this.formBuilder.group({
      username: new FormControl(data && data.user_name ? data.user_name : null, {
        validators: Validators.compose([Validators.required, CustomValidator.emailPhoneValidator]),
      }),
      password: new FormControl(data && data.password ? data.password : null, {
        validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator]),
      })
    })
  }

}
