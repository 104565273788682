import { Component, OnInit, Inject } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from '../../_services/api-data.service';
import { ToastService } from '../../_services/toast.service';
import { URLS } from '../../_config/api.config';
import { Router } from '@angular/router';
import { DatePipe, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-warehouse-management',
  templateUrl: './warehouse-management.component.html',
  styleUrls: ['./warehouse-management.component.scss'],
})
export class WarehouseManagementComponent implements OnInit {
  public subscription = new Subscription();
  dateRange: FormGroup;
  perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
  selectInterface = { cssClass: 'custom-select-dd' };
  fromDate: any;
  toDate: any;
  regionSelect: any;
  citySelect: any;
  statusSelect: any = "all";
  isLoading: boolean = false;
  showFilter: boolean = false;
  showSearch: boolean = false;
  searchText: any;
  warehouseListData: any[] = [];
  showLoader: boolean = false;
  totalWarehouseCount: number = 0;
  pageno = 1;
  pagesize = 10;
  filterparams: any;

  statusList = [];

  regionList = [];
  cityList = [];

  public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

  public count = 0;

  public itemsPerPage = 10;

  public currentPage = 1;
  searchObj: any;
  maxFromDate: any;
  maxDate: any;
  minToDate: any;
  todaysDate = new Date();
  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private alertController: AlertController,
    private dataService: ApiService,
    private toastService: ToastService,
    private router: Router,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    const element = this.document.querySelector('#scrollId');
    if (element) {
      element.scrollIntoView();
    }
    this.initializeDateForm();
    let date = new Date();
    this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
    this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
    this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');

    this.getRegions();
    this.getCityList();

    this.dateRange = this.fb.group({
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
    })

    this.searchText = null;
    this.filterparams = {
      page: this.pageno,
      size: this.pagesize
    }
    this.statusList = [
      {
        value: "all",
        name: this.commonService.domDirectionLTR ? 'All' : 'الجميع',
      },
      {
        value: "true",
        name: this.commonService.domDirectionLTR ? 'Active' : 'نشيط',
      },
      {
        value: "false",
        name: this.commonService.domDirectionLTR ? 'Inactive' : 'غير نشط'
      }
    ];
    this.getWarehouseList(this.filterparams, false);

  }

  initializeDateForm() {
    this.dateRange = this.fb.group({
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
    })
  }

  ionViewWillEnter() {
    this.initializeDateForm();
  }

  openFilters() {
    this.showFilter = !this.showFilter;
  }

  search() {
    this.filterparams = {
      page: this.pageno,
      size: this.pagesize
    }
    if (this.searchText && this.searchText.trim() == '') {
      this.searchText = null;
      return
    }
    if (this.searchText) {
      this.filterparams['search'] = this.searchText.trim();
      this.getWarehouseList(this.filterparams, false);
    }
  }

  // select data per page
  selectPageLength(event) {
    let selectPageSize = event.detail.value;
    this.itemsPerPage = event.detail.value
    this.pagesize = selectPageSize;
    this.currentPage = 1;
    this.filterparams['page'] = 1;
    this.filterparams['size'] = selectPageSize;
    this.getWarehouseList(this.filterparams, false)
  }

  onPageChange(event): void {
    this.currentPage = event;
    this.filterparams['page'] = this.currentPage;
    this.filterparams['size'] = this.itemsPerPage;
    this.getWarehouseList(this.filterparams, false)
  }

  clearSearch() {
    this.searchText = null;
    let params = {
      page: 1,
      size: this.pagesize
    }
    this.getWarehouseList(params, false);
  }

  clearFilter() {
    this.fromDate = null;
    this.toDate = null;
    this.regionSelect = null;
    this.citySelect = null;
    this.statusSelect = 'all';
    this.dateRange.reset();
    setTimeout(() => {
      this.getWarehouseList(this.searchObj, true);
    }, 300);
  }

  onDateChange(datetime) {
    datetime.cancel(true);
  }

  async presentAlert(warehouse) {
    warehouse.isActive = !warehouse.isActive;
    let deactivateMsg = this.commonService.domDirectionLTR ? "You want to deactivate warehouse." : "تريد إلغاء تنشيط المستودع.";
    let activateMsg = this.commonService.domDirectionLTR ? "You want to activate warehouse" : "تريد تنشيط المستودع";
    const alert = await this.alertController.create({
      header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
      message: warehouse.isActive ? deactivateMsg : activateMsg,
      buttons: [
        {
          text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
          role: 'confirm',
          handler: () => {
            let isActive;
            if (warehouse.isActive) {
              isActive = false;
            } else {
              isActive = true;
            }
            let params = {
              is_active: isActive,
              warehouse_id: warehouse.id,
            };
            warehouse.isActive = !warehouse.isActive;
            this.updateWarehouse(params);
          },
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
  }

  getRegions() {
    const params = {
      dropdown: true,
    };
    this.subscription.add(
      this.dataService.get(URLS.regionsList, params).subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'] && data['data']['result'].length) {
              let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
              this.regionList = [];
              list.forEach((regions) => {
                let obj = {
                  id: regions.region_id ? regions.region_id : '',
                  name: this.commonService.domDirectionLTR ? regions.region_en ? regions.region_en : '' : regions.region_ar ? regions.region_ar : '',
                };
                this.regionList.push(obj);
              });
            }
          } else {
            if (data['code'] != 204) {
              this.toastService.displayToast(data['message'], null, 'danger');
            }
          }
        },
        (error) => {
          this.toastService.displayToast(error['message'], null, 'danger');
        }
      )
    );
  }

  getCityList(id?) {
    const params = {
      dropdown: true,
    };
    if (id) {
      params['regions'] = Number(id);
    }

    this.subscription.add(
      this.dataService.get(URLS.cityList, params).subscribe(
        (data) => {
          if (data['code'] == 200) {
            if (data['data'] && data['data']['result'].length) {
              let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
              this.cityList = [];
              list.forEach((city) => {
                let obj = {
                  id: city.city_id ? city.city_id : '',
                  name: this.commonService.domDirectionLTR ? city.city_en ? city.city_en : '' : city.city_ar ? city.city_ar : '',
                  region_id: city.region_id ? city.region_id : '',
                };
                this.cityList.push(obj);
              });
            }
          } else {
            if (data['code'] != 204) {
              this.toastService.displayToast(data['message'], null, 'danger');
            }
          }
        },
        (error) => {
          this.toastService.displayToast(error['message'], null, 'danger');
        }
      )
    );
  }

  //////////// Get Warehouse List //////////////
  getWarehouseList(params?, clearFilter?: boolean) {
    this.showLoader = true;
    this.warehouseListData = [];
    if (this.searchObj) {
      params = { ...this.searchObj, ...params }
    }
    if (clearFilter) {
      params = {};
      params['page'] = this.pageno;
      params['size'] = this.pagesize;
    }
    this.subscription.add(
      this.dataService.get(URLS.warehouseList, params).subscribe(data => {
        if (data['code'] == 200) {
          this.totalWarehouseCount = parseInt(data['data']['total_count'])
          this.warehouseListData = [];
          if (data['data'] && data['data']['result'].length) {
            let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
            list.forEach((warehouse) => {
              let obj = {
                id: warehouse.warehouse_id ? warehouse.warehouse_id : '',
                warehouse_title: this.commonService.domDirectionLTR ? warehouse.warehouse_title_en ? warehouse.warehouse_title_en : '-' : warehouse.warehouse_title_ar ? warehouse.warehouse_title_ar : '-',
                warehouse_address: this.commonService.domDirectionLTR ? warehouse.warehouse_address_en ? warehouse.warehouse_address_en : '-' : warehouse.warehouse_address_ar ? warehouse.warehouse_address_ar : '-',
                region: this.commonService.domDirectionLTR ? warehouse.region_en ? warehouse.region_en : '-' : warehouse.region_ar ? warehouse.region_ar : '-',
                zipcode:  warehouse.zipcode ? warehouse.zipcode : '-',
                city: this.commonService.domDirectionLTR ? warehouse.city_en ? warehouse.city_en : '-' : warehouse.city_ar ? warehouse.city_ar : '-',
                country: this.commonService.domDirectionLTR ? warehouse.country_en ? warehouse.country_en : '-' : warehouse.country_ar ? warehouse.country_ar : '-',
                isActive: warehouse.is_active,
              };
              this.warehouseListData.push(obj);
            });
          }
          this.showLoader = false;
        } else {
          this.showLoader = false;
          this.toastService.displayToast(data['message'], null, 'danger');
        }
      }, error => {
        this.showLoader = false;
        this.warehouseListData = [];
        this.toastService.displayToast(error['message'], null, 'danger');
      })
    )
  }

  addWarehouse() {
    this.router.navigate(['/account-details/warehouse-management/add'])
  }

  edit(id) {
    if (id) {
      this.router.navigate(['/account-details/warehouse-management/edit', id])
    }
  }

  applyBtn() {
    this.searchObj = {}
    if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
      if (!this.fromDate) {
        this.toastService.displayToast("error.selectFromDate", null, 'danger');
      }
      if (!this.toDate) {
        this.toastService.displayToast("error.selectToDate", null, 'danger');
      }
      return
    }
    if (this.fromDate && this.toDate) {
      this.searchObj['from_date'] = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      this.searchObj['to_date'] = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
    }
    if (this.regionSelect && this.regionSelect.length) {
      this.searchObj['region_id'] = this.regionSelect.toString();
    }

    if (this.citySelect && this.citySelect.length) {
      this.searchObj['city_id'] = this.citySelect.toString();
    }

    if (this.statusSelect && this.statusSelect !== 'all') {
      this.searchObj['status'] = this.statusSelect;
    }

    this.searchObj['page'] = this.pageno;
    this.searchObj['size'] = this.pagesize;
    setTimeout(() => {
      this.getWarehouseList(this.searchObj, false);
    }, 300);

  }

  /////////// Update Warehouse data /////////////
  updateWarehouse(params: any) {

    this.isLoading = true;
    this.subscription.add(
      this.dataService.put(URLS.warehouseUpdate, params).subscribe(data => {
        if (data['code'] == 200 && data['data']) {
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.toastService.displayToast(data['message'], null, 'danger');
        }
      }, error => {
        this.isLoading = false;
        this.toastService.displayToast(error['message'], null, 'danger');
      })
    )
  }

  key: string = "id";
  sortType: string = "ASC";
  onSort(event, key: any) {
    this.key = key;

    const element = document.querySelectorAll('.sorting-icon');
    element.forEach(img => {
      img.setAttribute("src", "assets/icon/sort.svg")
    });

    let eventValue;
    if (event.target.className.includes("column-heading")) {
      eventValue = event.target.children[0];
    } else {
      eventValue = event.srcElement;
    }
    let order = eventValue.getAttribute("data-order")
    if (this.filterparams['sort_by'] != key) {
      order = "asc"
    }
    if (order === "asc") {
      this.sortType = "ASC";
      eventValue.setAttribute("data-order", "desc");
      eventValue.src = "assets/icon/sorting_asc.svg";
    } else if (order === "desc") {
      this.sortType = "DESC";
      eventValue.setAttribute("data-order", "");
      eventValue.src = "assets/icon/sorting_desc.svg";
    } else {
      delete this.filterparams['sort_by'];
      delete this.filterparams['sort_type'];
      eventValue.setAttribute("data-order", "asc");
      eventValue.src = "assets/icon/sort.svg";

    }
    if (order) {
      this.filterparams['sort_by'] = key;
      this.filterparams['sort_type'] = this.sortType;
    }
    this.getWarehouseList(this.filterparams, false)
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

}
