import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateConfigService } from './../../_services/translate-config.service';
// import { Geolocation } from '@capacitor/geolocation';
import { Toast } from '@capacitor/toast';
// import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Geolocation } from '@capacitor/geolocation';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { TranslateService } from '@ngx-translate/core';
import { GetPlatformService } from 'src/app/_services/get-platform.service';
import { CommonService } from '../../_services/common.service';
import { ModalService } from '../../_services/modal.service';
import { MenusComponent } from '../menus/menus.component';
import { AlertController } from '@ionic/angular';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { filter, pluck, buffer, map } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/authentication.service';
import { ToastService } from '../../_services/toast.service';
import { environment } from '../../../environments/environment';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { Subscription } from 'rxjs';

declare var google;
declare var navigator;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    currentLocation: any = "New york,NY";
    breadCrumbTitle: any;
    breadCrumbPath: any;
    isMenuVisible = false;
    isOrderDetails = false;
    isLoggedIn: boolean = false;
    isInfoDropdownOpen: boolean = false;
    userName: any;
    profileData: any;
    public subscription = new Subscription();

    breadcrumbsArray = [];
    @ViewChild('child') private child: MenusComponent;
    selectedLanguage: any;
    languageArray: any = [
        { code: 'en', title: 'English', dir: 'ltr' },
        { code: 'ar', title: 'عربي', dir: 'rtl' }
    ]

    inerfaceOption = { cssClass: 'language-dropdown' };

    constructor(public getPlatformService: GetPlatformService,
        private nativeGeocoder: NativeGeocoder,
        private modalService: ModalService,
        private translateConfigService: TranslateConfigService,
        public translate: TranslateService,
        public commonService: CommonService,
        private authService: AuthenticationService,
        private alertController: AlertController,
        private toastService: ToastService,
        private router: Router,
        private dataService: ApiService,
    ) {
        this.breadCrumb();
        let selectedLanguage = this.translateConfigService.getDefaultLanguage();
        for (let i = 0; i < this.languageArray.length; i++) {
            if (selectedLanguage == this.languageArray[i].code) {
                this.selectedLanguage = this.languageArray[i];
            }
        }
    }

    ngOnInit() {
        this.subscription = new Subscription();
        this.commonService.eventObservable.subscribe(data => {
            this.profileData = this.commonService.profileData;
        })
        this.getProfileData();
    }

    async languageChanged(event) {
        await this.translateConfigService.setLanguage(this.selectedLanguage.code);
        await this.translateConfigService.setDir(this.selectedLanguage.dir);
        localStorage.setItem('locale', this.selectedLanguage.code);
        window.location.reload()
    }

    openModal(e) {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            e.preventDefault();
            e.stopPropagation();
            this.isLoggedIn = true;
            let userInfo = this.commonService.decryptData(localStorage.getItem('userInfo'));
            userInfo = JSON.parse(userInfo);
            this.userName = userInfo['user_name'];
            if (this.isInfoDropdownOpen) {
                this.isInfoDropdownOpen = false;
            } else {
                this.isInfoDropdownOpen = true;
            }
        }
    }

    outSideDropdown() {
        this.isInfoDropdownOpen = false;
    }

    /////// Code for Breadcrumb Title and Nevigation Path //////////////
    breadCrumb() {
        this.router.navigated
        const navigationEnd = this.router.events.pipe(filter(val => { if (val instanceof NavigationEnd) return true; }));
        this.router.events.pipe(filter(val => {
            if (val instanceof ActivationEnd) return true;
        }),
            pluck("snapshot"),
            pluck("data"),
            buffer(navigationEnd),
            map((data: any[]) => {
                return data.reverse();
            })).subscribe((val) => {
                let breadcrumbs = [];
                this.breadcrumbsArray = [];
                let data = val;
                if (this.router.getCurrentNavigation().extras.state) {
                    data.push(this.router.getCurrentNavigation().extras.state)
                    localStorage.setItem('breadcrumb', JSON.stringify(this.router.getCurrentNavigation().extras.state))
                } else {
                    if (localStorage.getItem('breadcrumb')) {
                        let breadcrumbData = JSON.parse(localStorage.getItem('breadcrumb'));
                        if (breadcrumbData.url == this.router.url) {
                            data.push(breadcrumbData)
                        }
                    }
                }
                data.map(el => {
                    if (el && el['breadcrumb']) {
                        if (!breadcrumbs.includes(el['breadcrumb'])) {
                            breadcrumbs.push(el['breadcrumb'])
                            this.breadcrumbsArray.push(el)
                        }
                    }
                })
            });
    }

    openCartModal() {
        let options: any = {
            id: "cartModal"
        };
        options['cssClass'] = 'cart-Modal';
        options['showBackdrop'] = false;
        this.modalService.openModal(options)
    }

    async myLocation() {
        const coordinates: any = await Geolocation.getCurrentPosition();
        let latitude = coordinates.coords.latitude;
        let longitude = coordinates.coords.longitude;

        let options: NativeGeocoderOptions = {
            useLocale: true,
            maxResults: 5
        };

        this.nativeGeocoder.reverseGeocode(latitude, longitude, options)
            .then((result: NativeGeocoderResult[]) => {
                if (result[0]) {
                    let results: any = result[0]
                    let sublocality = results.subLocality ? results.subLocality + "," : '';
                    let locality = results.locality ? results.locality : '';
                    this.currentLocation = sublocality + locality;
                    Toast.show({
                        text: this.currentLocation
                    })
                }
            }
            )
            .catch((error: any) => {
                this.getGeoLocation(latitude, longitude, 'reverseGeocode')
                console.log(error)
            });

    }

    // toggle menu visibility
    toggleMenu() {
        this.isMenuVisible = !this.isMenuVisible;
        this.child.notifyMe();
    }

    async getGeoLocation(lat: number, lng: number, type?) {
        if (navigator.geolocation) {
            let geocoder = await new google.maps.Geocoder();
            let latlng = await new google.maps.LatLng(lat, lng);

            let request = { latLng: latlng };
            await geocoder.geocode(request, (results, status) => {
                if (results && results[0]) {
                    if (results[0]['address_components'] && results[0]['address_components'].length) {
                        let location = results[0]['address_components'];
                        let area: any;
                        let city: any
                        location.map((addr) => {
                            if (addr['types'].includes("locality")) {
                                city = addr['short_name']
                            }
                            if (addr['types'].includes("sublocality") && addr['types'].includes("sublocality_level_1")) {
                                area = addr['long_name']
                            }
                        })
                        this.currentLocation = area + ',' + city;
                    }
                }
            });
        }
    }

    getProfileData() {
        const token: any = localStorage.getItem('accessToken');
        if(token){
            this.subscription.add(
                this.dataService.get(URLS.supplierDetails).subscribe(
                    (data) => {
                        if (data['code'] == 200) {
                            if (data['data']) {
                                this.profileData = {
                                    name: this.commonService.domDirectionLTR ? data['data']['supplier_name_en'] ? data['data']['supplier_name_en'] : '' : data['data']['supplier_name_ar'] ? data['data']['supplier_name_ar'] : '',
                                    companyLogoUrl: data['data']['supplier_logo_url'],
                                    companyName: this.commonService.domDirectionLTR ? data['data']['supplier_legal_name_en'] ? data['data']['supplier_legal_name_en'] : '' : data['data']['supplier_legal_name_ar'] ? data['data']['supplier_legal_name_ar'] : '',
                                }
                                this.commonService.profileData = this.profileData
                            }
                        } else {
                            if (data['code'] != 204) {
                                this.toastService.displayToast(data['message'], null, 'danger');
                            }
                        }
                    },
                    (error) => {
                        this.toastService.displayToast(error['message'], null, 'danger');
                    }
                )
            );
        }
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: this.commonService.domDirectionLTR ? 'Are you sure?' : 'هل أنت متأكد؟',
            message: this.commonService.domDirectionLTR ? 'You want to logout?' : 'تريد الخروج؟',
            buttons: [
                {
                    text: this.commonService.domDirectionLTR ? 'Cancel' : 'يلغي',
                    role: 'cancel',
                    handler: () => {
                    },
                },
                {
                    text: this.commonService.domDirectionLTR ? 'Yes' : 'نعم',
                    role: 'confirm',
                    handler: () => {
                        this.logout();
                    },
                },
            ],
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
    }

    goToCustomerProfile() {
        window.open(environment.customerUrl);
    }

    logout() {
        this.authService.logout().subscribe(data => {
            localStorage.clear();
            this.router.navigate(['/login']).then(() => {
                window.location.reload();
            });
            if (data['code'] != 200 ) {
                this.toastService.displayToast(data['message'], null, 'danger');
            } 
        }, error => {
            this.toastService.displayToast(error['message'], null, 'danger');
            localStorage.clear();
            this.router.navigate(['/login']).then(() => {
                window.location.reload();
            });
        });
    }

    goToHome() {
        if (localStorage.getItem('accessToken')) {
            this.router.navigate(['/account-details/marketplace-dashboard']);
        }
    }

    toggleWebMenu(){
        this.commonService.isWebMenuOpen = !this.commonService.isWebMenuOpen;
    }
}
