import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../_services/common.service';
import { environment } from '../../../environments/environment';
import { Router, NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    @Input() showOnlyLowFooter?: boolean = false;
    customerUrl: any;

    constructor(
        public commonService: CommonService,
        private router: Router,
        private navCtrl: NavController,
        ) { }

    ngOnInit() {
        this.customerUrl = environment.customerUrl;
    }

    // Go to relevant page
    navigate(page) {
        this.router.navigate(['/account-details/' + page]);
    }

}
