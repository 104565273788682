import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
// import { FilterComponent } from '../filter/filter.component';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ChartConfiguration, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ToastService } from '../../_services/toast.service';

@Component({
	selector: 'app-marketplace-dashboard',
	templateUrl: './marketplace-dashboard.component.html',
	styleUrls: ['./marketplace-dashboard.component.scss'],
})
export class MarketplaceDashboardComponent implements OnInit {
	cards = [
		{
			label: "MarketplaceDashboardComponent.totalEarnings",
			value: "0",
			class: "bg-primary",
			apikey: "total_earning",
			page_url: "/account-details/my-transaction-list",
			img: './assets/icon/earnings.svg'
		},
		{
			label: "MarketplaceDashboardComponent.overallCommission",
			value: "0",
			class: "bg-light-orange",
			apikey: "total_commission",
			page_url: "/account-details/my-transaction-list",
			img: './assets/icon/dashboard-commision.svg'

		},
		{
			label: "MarketplaceDashboardComponent.shipmentDeductions",
			value: "0",
			class: "bg-light-green",
			apikey: "shipping_charges",
			page_url: "/account-details/return-orders-management",
			img: './assets/icon/dashboard-shipment.svg'

		},
		{
			label: "MarketplaceDashboardComponent.payableAmount",
			value: "0",
			class: "bg-yellow",
			apikey: "",
			page_url: "/account-details/my-transaction-list",
			img: './assets/icon/dashboard-amount.svg'

		},
	];

	notifications = [];
	orderList = []
	public lineChartData: ChartConfiguration<'line'>['data'] = {
		labels: [],
		datasets: [
			{
				data: [],
				label: 'Total Sale',
				fill: 'none',
				tension: 0.5,
				borderColor: '#FB772B',
				pointRadius: 5,
				pointBackgroundColor: '#FFFFFF',
				pointBorderColor: '#707070',
				pointBorderWidth: 2,
			}
		]
	};
	public lineChartType: ChartType = 'line';
	public lineChartOptions: ChartConfiguration['options'] = {
		responsive: true,
		aspectRatio: 3.5,
		maintainAspectRatio: true,
		scales: {
			// We use this empty structure as a placeholder for dynamic theming.
			x: {},
			y: {
				beginAtZero: true,
				ticks: {
					maxTicksLimit: 6,
					callback: function (val, index) {
						let value: any = val
						return 'SR ' + value;
					},
				}
			},
		},
	};
	@ViewChild(BaseChartDirective) chart?: BaseChartDirective;

	ddFilterData: any = []
	public lineChartLegend = false;
	public isModalOpen = false;
	filterDate: any = "2022-12-12";
	minDate: any;
	todaysDate = new Date();
	@Input() showHeader: boolean;
	inerfaceOption = { cssClass: 'filters-dropdown' };
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	public subscription = new Subscription();
	isLoading = false;
	totalFeaturedCount: any = 0;
	totalProductCount: any = 0;
	pageNumber: number = 1;
	vatHistory: any = [];
	totalHistory: any = 0;
	vatPercent: any;
	currentPage: number = 1;
	pagesize: any = 10;
	selectedYear: any;
	selectedCategory: any = [];
	dashboardCategryList: any = [];

	constructor(
		private modalService: ModalService,
		public commonService: CommonService,
		private dataService: ApiService,
		private datePipe: DatePipe,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
		private toastService: ToastService
	) {
		const currentYear = new Date().getFullYear();
		this.selectedYear = currentYear;
		this.ddFilterData = Array.from({ length: 5 }, (_, i) => currentYear - i);
		this.getDashboardCategoryList();

	}

	ngOnInit() {
		this.showHeader = true;
		this.minDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		const element = this.document.querySelector('#scrollId');
		if (element) {
			element.scrollIntoView();
		}
		this.isLoading = true;
		Promise.all([
			this.getMyVatPercent(),
			this.getDashboardGraph(),
			this.getMyVatHistory(),
			this.getCardValues(),
			this.getOrderList(),
			this.getFeaturedList(),
			this.getProductList()
		]).then(data => {
			this.isLoading = false;
		}, err => {
			this.isLoading = false;

		})
	}

	ionViewWillEnter() {
		this.showHeader = true;
	}

	onDateChange(datetime) {
		datetime.confirm(true);
	}

	getDashboardCategoryList() {
		this.subscription.add(
			this.dataService.get(URLS.dashboardCategry).subscribe(data => {
				if (data['code'] == 200) {
					this.dashboardCategryList = data['data'] ? data['data'] : [];
				}

			})
		)
	}
	getDashboardGraph(data?, is_cat?) {
		let categoryParam = Array.isArray(this.selectedCategory)
			? this.selectedCategory.join(',')
			: this.selectedCategory;


		let params = {
			year: this.selectedYear ? this.selectedYear : new Date().getFullYear()
		}

		if (categoryParam) params['category_id'] = categoryParam;

		this.subscription.add(
			this.dataService.get(URLS.dashboardGraph, params).subscribe(data => {
				if (data['code'] == 200) {


					this.lineChartData = {
						labels: [],
						datasets: [
							{
								data: [],
								label: 'Total Sale',
								fill: 'none',
								tension: 0.5,
								borderColor: '#FB772B',
								pointRadius: 5,
								pointBackgroundColor: '#FFFFFF',
								pointBorderColor: '#707070',
								pointBorderWidth: 2,
							}
						]
					};

					let linechartData = data['data'];

					if (linechartData && linechartData.length > 0) {
						this.lineChartData.labels = linechartData.map(item => this.formatDate(item.month));
						this.lineChartData.datasets[0].data = linechartData.map(item => parseFloat(item.total_earning));
					}
				}

			})
		)
	}

	formatDate(dateString: string): string {
		const date = new Date(dateString);
		const options = { month: 'short', year: '2-digit' } as const;

		// Format month and year
		const month = date.toLocaleString('en-US', { month: 'short' });
		const year = date.getFullYear().toString().slice(-2); // Last two digits of the year

		return `${month} ${year}`;
	}

	getCardValues() {
		return new Promise<any>((resolve, reject) => {
			this.subscription.add(this.dataService.get(URLS.getDashboardCount).subscribe(data => {
				if (data && data['code'] == 200) {
					let cardData = data['data'] ? data['data'] : null;
					this.cards.forEach(element => {
						if (cardData) {
							element.value = cardData[element.apikey] ? cardData[element.apikey] : '0';
						}
					});
					resolve(data['data'])
				} else {
					reject();
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				reject();

			}));
		})
	}

	getMyVatPercent() {
		return new Promise<any>((resolve, reject) => {
			this.subscription.add(this.dataService.get(URLS.getMyVat).subscribe(data => {
				if (data && data['code'] == 200) {
					this.vatPercent = data['data'] && data['data']['vat_percentage'] ? data['data']['vat_percentage'] : '';
					resolve(data['data'])
				} else {
					reject();
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				reject();

			}));
		})
	}
	getMyVatHistory(sortParams?) {
		let params = {
			page: this.pageNumber,
			limit: 10,
		}
		return new Promise<any>((resolve, reject) => {
			this.subscription.add(this.dataService.get(URLS.getMyVatHistory, params).subscribe(data => {
				if (data && data['code'] == 200) {
					this.vatHistory = data['data'] && data['data']['result'] && data['data']['result'].length ? data['data']['result'] : [];
					this.totalHistory = data['data'] && data['data']['total_count'] ? data['data']['total_count'] : 0;
					resolve(data['data'])
				} else {
					reject();
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				reject();

			}));
		})
	}

	getOrderList() {
		let params = {
			page: 1,
			size: 5,
			sort_by: 'order_date',
			sort_type: 'DESC'
		}
		return new Promise<any>((resolve, reject) => {
			this.subscription.add(
				this.dataService.get(URLS.orderList, params).subscribe(data => {
					if (data['code'] == 200) {
						this.orderList = [];
						if (data['data'] && data['data']['result'].length) {
							let list = data['data'] && data['data']['result'].length ? data['data']['result'] : [];
							list.forEach((order) => {
								let obj = {
									order_id: order.order_no ? order.order_no : '',
									invoice_id: order.invoice_id ? order.invoice_id : '',
									customer_name: order.customer_name ? order.customer_name : '',
									customer_email: order.customer_email ? order.customer_email : '',
									mobile_no: order.mobile_no ? order.mobile_no : '',
									address_title: order.address_title ? order.address_title : '',
									address: order.address ? order.address : '',
									order_date: order.order_date ? order.order_date : '',
									total_order_amount: order.total_order_amount ? order.total_order_amount : '',
									shipping_address: order.shipping_address ? order.shipping_address : '',
									shipping_address_country: order.shipping_address_country ? order.shipping_address_country : '',
									shipping_address_city: order.shipping_address_city ? order.shipping_address_city : '',
									shipping_address_region: order.shipping_address_region ? order.shipping_address_region : '',
									shipping_address_zipcode: order.shipping_address_zipcode ? order.shipping_address_zipcode : '',
									city_en: order.city_en ? order.city_en : '',
									city_ar: order.city_ar ? order.city_ar : '',
									region_ar: order.region_ar ? order.region_ar : '',
									region_en: order.region_en ? order.region_en : '',
									country_en: order.country_en ? order.country_en : '',
									country_ar: order.country_ar ? order.country_ar : '',
									zipcode: order.zipcode ? order.zipcode : '',
									discount_amount: order.discount_amount ? order.discount_amount : '',
									coupon_code: order.coupon_code ? order.coupon_code : '',
									coupon_value: order.coupon_value ? order.coupon_value : '',
									payment_type: order.payment_type ? order.payment_type : '',
									pro_count: order.pro_count ? order.pro_count : '',
									vat_amount: order.vat_amount ? order.vat_amount : '',
									ship_amount: order.ship_amount ? order.ship_amount : '',
									commission_amount: order.commission_amount ? order.commission_amount : '',
									shipping_status: order.shipping_status ? order.shipping_status : 'CONFIRMED',
									items_count: order.pro_count ? order.pro_count : '-',
								};
								this.orderList.push(obj);
							});
						}
						resolve(this.orderList)

					} else {
						reject()
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {
					reject();
				})
			)
		})
	}

	gotoOrderDetails(event, order_id) {
		event.stopPropagation();
		event.preventDefault();
		this.router.navigate(['/account-details/orders-management/order-details', order_id]);
	}

	goToOrderList() {
		this.router.navigate(['/account-details/orders-management']);
	}

	goToProductList() {
		this.router.navigate(['/account-details/products-management']);
	}

	goToFeatureList() {
		this.router.navigate(['/account-details/features-products-management']);
	}


	getFeaturedList() {
		let params = {
			'page': 1,
			'size': 5
		}
		return new Promise((resolve, reject) => {

			this.subscription.add(
				this.dataService.get(URLS.featuredList, params).subscribe(data => {
					if (data && data['code'] == 200) {
						this.totalFeaturedCount = data['data'] && data['data']['total_count'] ? +data['data']['total_count'] : 0
						resolve(data)
					} else {
						if (data['code'] != 204) {
							this.toastService.displayToast(data['message'], null, 'danger');
						}
						reject()
					}

				}, error => {
					reject()
				})
			);
		})
	}

	getProductList() {
		let params = {
			'page': 1,
			'size': 5
		}

		return new Promise((resolve, reject) => {

			this.subscription.add(
				this.dataService.get(URLS.productList, params).subscribe(data => {
					if (data['code'] == 200) {
						this.totalProductCount = parseInt(data['data']['total_count'])
						resolve(data)
					} else {
						reject()
						this.toastService.displayToast(data['message'], null, 'danger');
					}
				}, error => {
					reject()
				})
			)
		})
	}

	goToPage(page) {
		this.router.navigate([page]);
	}

	// select data per page
	selectPageLength(event) {
		let selectPageSize = event.detail.value;
		this.pagesize = selectPageSize;
		this.pageNumber = 1;
		this.getMyVatHistory();
	}

	onPageChange(event): void {
		this.pageNumber = event;
		this.getMyVatHistory();
	}
}
