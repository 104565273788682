import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ApiService } from './api-data.service';
import { Subscription } from 'rxjs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const EXCEL_TYPE_CSV = 'text/csv;charset=UTF-8';
const EXCEL_EXTENSION_CSV = '.csv';

@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {
  isLoading: boolean = false;
  private subscription: Subscription = new Subscription();
  constructor(public dataService: ApiService) {}

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    header = []
  ): void {
    let jsonData = [];
    json.forEach((element, index) => {
      jsonData.push(Object.assign({ 'sr.no': index + 1 }, element));
    });
    const worksheet: XLSX.WorkSheet = header.length
      ? XLSX.utils.json_to_sheet(jsonData, { header: header })
      : XLSX.utils.json_to_sheet(jsonData);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'csv',
      type: 'array',
    });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(header, excelFileName);
  }
  public saveAsXlsx(json: any[], excelFileName: string) {
    let jsonData = [];
    json.forEach((element, index) => {
      jsonData.push(Object.assign({ 'sr.no': index + 1 }, element));
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.xlsxFormat(excelBuffer, excelFileName);
  }

  public csvFile(json: any[], excelFileName: string) {
    const header = Object.keys(json[0]);
    let csv = json.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName])).join(',')
    );
    let csvArray = csv.join('\r\n');
    this.saveAsExcelFile(csvArray, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    var blob = new Blob(['\ufeff', buffer], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(
      blob,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION_CSV
    );
  }

  private xlsxFormat(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
