import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { URLS } from 'src/app/_config/api.config';
import { ApiService } from 'src/app/_services/api-data.service';
import { ProductService } from 'src/app/_services/product.service';
import { ModalService } from '../../_services/modal.service';
import * as _ from 'lodash';
import { ToastService } from '../../_services/toast.service';
import { CommonService } from '../../_services/common.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
  @Input() isCommonImage: any;
  @Input() imagesArray: any;
  @Input() imageType: any;
  @Input() product_id: any;
  @Input() productVariation: any;
  @Output() imageResponse: any = new EventEmitter<string>();
  title: string = '';
  isCoverChanged = false;
  localImagesArray = [];
  isUploading: any;
  fileData: any;
  backupArr = [];

  constructor(
    private modalService: ModalService,
    private dataService: ApiService,
    private productService: ProductService,
    private toastService: ToastService,
    public commonService: CommonService,
    private alertController: AlertController,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.backupArr = JSON.parse(JSON.stringify(this.imagesArray))
  }

  closeModal() {
    if (this.isUploading) return;
    this.productService.updateFromModal = false;
    if (this.imagesArray && this.imagesArray.length) {
      let imgAr = JSON.parse(JSON.stringify(this.imagesArray))
      imgAr.forEach((element, idx) => {
        if (!element.image_id) {
          let index = this.imagesArray.indexOf(element)
          this.imagesArray.splice(index, 1);
        }
        if (this.backupArr && this.backupArr.length) {
          let obj = this.backupArr.find(el => el.image_id == element.image_id);
          if (obj) {
            element.is_cover_image = obj.is_cover_image;
          }
        }
      });
    }

    this.modalService.dismissModal();
  }

  upload(files, type) {
    this.fileData = files['target']['files'][0];
    let promises = [];
    if (files && files['target'] && files['target']['files'] && files['target']['files'].length > 0) {
      const max_size = 5242880;
      for (let i = 0; i < files['target']['files'].length; i++) {
        if (files['target']['files'][i].size > max_size) {
          let message = this.commonService.domDirectionLTR ? 'Maximum size allowed is ' + max_size / 1048576 + 'MB' : 'الحجم الأقصى المسموح به هو' + max_size / 1048576 + 'MB';
          this.toastService.displayToast(message, null, 'warning');
          this.fileData = [];
          return false;
        }

        if ((files['target']['files'][i].type).includes(".image") || (files['target']['files'][i].type).includes("image/png") || (files['target']['files'][i].type).includes("image/gif") || (files['target']['files'][i].type).includes("image/jpeg") || (files['target']['files'][i].type).includes("image")) {
          if (i < 5 && this.localImagesArray.length < 5) {
            this.isUploading = true;
            promises.push(
              this.uploading(type, files['target']['files'][i])
            )
          } else {
            this.toastService.displayToast('imageLimitMsg', null, 'warning');
            break;
          }


        }
        else {
          this.toastService.displayToast('onlyImage', null, 'warning');
          this.fileData = [];
          return false;
        }
      }
    }
    Promise.all(promises).then(data => {
      this.isUploading = false;

    }, err => {
      this.isUploading = false;
    })
    files.target.value = '';

  }

  uploading(type, file) {
    return new Promise((resolve, reject) => {
      this.productService
        .imageUpload(this.product_id, 2, type, file)
        .then((res) => {
          if (!this.localImagesArray) {
            this.localImagesArray = [];
          }
          if (!this.imagesArray) {
            this.imagesArray = [];
          }
          let image_url = res.data.split('/products')[1];
          let imageObject = {
            image_url: 'products' + image_url,
            display_url: res.data,
            isDefault: false,
            is_cover_image: false
          };
          if ((this.imagesArray && this.imagesArray.length < 5)) {
            this.imagesArray.push(imageObject);
            this.localImagesArray.push(imageObject);
          } else {
            this.isUploading = false;
            this.toastService.displayToast('imageLimitMsg', null, 'warning');
            return
          }
          this.fileData = null;
          resolve(res)
          // this.isUploading = false;
        }, err => {
          reject()
        });
    })
  }
  save() {
    if ((this.imagesArray && !this.imagesArray.length) || !this.imagesArray) {
      this.toastService.displayToast('error.uploadImage', null, 'danger');
      return
    }
    if (this.imageType == 'web') {
      if (this.productVariation.product_images_web == null && !(this.productVariation.product_images_web && !this.productVariation.product_images_web.length)) {
        this.productVariation.product_images_web = [];
        this.productVariation.product_images_web = [...this.productVariation.product_images_web, ...this.imagesArray];
      }
    } else {
      if (this.productVariation.product_images_mobile == null && !(this.productVariation.product_images_mobile && !this.productVariation.product_images_mobile.length)) {
        this.productVariation.product_images_mobile = [];
        this.productVariation.product_images_mobile = [...this.productVariation.product_images_mobile, ...this.imagesArray];
      }
    }
    if (this.localImagesArray.length > 0 || this.isCoverChanged) {
      this.productService.modalSave = true;
    }
    this.modalService.dismissModal({
      update: this.localImagesArray.length > 0 ? true : this.isCoverChanged ? true : false,
    });
    // might need later
    // if (this.imageType == 'web') {
    //   this.productVariation['images_web'] = _.map(
    //     this.localImagesArray,
    //     'image_url'
    //   );
    // } else {
    //   this.productVariation['images_mobile'] = _.map(
    //     this.localImagesArray,
    //     'image_url'
    //   );
    // }
  }

  removeImage(event, i, id) {
    event.preventDefault();
    event.stopPropagation();
    this.presentAlert(i, id);

  }

  setCoverImage(index) {

    this.imagesArray.forEach((element, idx) => {
      if (idx == index) {
        element.is_cover_image = true;
      } else {
        element.is_cover_image = false
      }
    });
    this.isCoverChanged = true;
  }


  async presentAlert(index, id) {
    const alert = await this.alertController.create({
      header: this.translate.instant('areYouSure'),
      message: this.translate.instant('deleteImgMsg'),
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: this.translate.instant('yes'),
          role: 'confirm',
          handler: () => {
            if (id) {
              this.productService.imageDelete([id]).then(data => {
                this.localImagesArray.splice(index, 1);
                this.imagesArray.splice(index, 1)
              })
            } else {
              this.localImagesArray.splice(index, 1);
              this.imagesArray.splice(index, 1)
            }
          },
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
  }

}
