import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { ModalService } from '../../_services/modal.service';
import { CommonService } from '../../_services/common.service';
import { CustomValidator } from '../../_common/customvalidators';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../_services/api-data.service';
import { ToastService } from '../../_services/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordForm: FormGroup;
  public subscription = new Subscription();
  isLoading = false;
  oldPasswordType = 'password';
  newPasswordType = 'password';
	confirmPasswordType = 'password';

  public validation_messages = {
    old_password: [
      { type: 'required', message: 'MyProfileComponent.oldPasswordRequired' },
      { type: 'invalidPassword', message: 'MyProfileComponent.invalidPassword' },
    ],
    new_password: [
        { type: 'required', message: 'MyProfileComponent.newPasswordRequired' },
        { type: 'invalidPassword', message: 'MyProfileComponent.invalidPassword' },
    ],
    confirm_password: [
			{ type: 'required', message: 'MyProfileComponent.confirmPasswordRequired' },
			{ type: 'passMismatch', message: 'MyProfileComponent.passMismatch' },
		],
  }

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    public commonService: CommonService,
    private toastService: ToastService,
    private modalCtrl: ModalController,
    private dataService: ApiService,
  ) { }

  changePassForm(): FormGroup {
    return this.formBuilder.group({
      old_password: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
      }),
      new_password: new FormControl(null, {
        validators: Validators.compose([Validators.required, CustomValidator.passwordPatternValidator]),
      }),
      confirm_password: new FormControl(null, {
				validators: Validators.compose([Validators.required,
				CustomValidator.confirmPassword]),
			})
    });
  }

  ngOnInit() {
    this.subscription = new Subscription();
    this.changePasswordForm = this.changePassForm();
  }

  closeModal() {
    this.modalService.dismissModal();
  }

  onChangePassword() {
    for (const i in this.changePasswordForm.controls) {
      this.changePasswordForm.controls[i].markAsPristine();
      this.changePasswordForm.controls[i].markAsDirty();
      this.changePasswordForm.controls[i].markAsTouched();
      this.changePasswordForm.controls[i].updateValueAndValidity();
    }
    if (this.changePasswordForm.invalid) {
      return;
    }

    const params = {}
    if (this.changePasswordForm.controls.old_password.value && this.changePasswordForm.controls.new_password.value) {
      params['old_password'] = this.changePasswordForm.controls.old_password.value;
      params['new_password'] = this.changePasswordForm.controls.new_password.value;
    } else {
      return;
    }

    this.isLoading = true;
    this.subscription.add(
      this.dataService.put(URLS.changePassword, params).subscribe(data => {
        if (data['code'] == 200) {
          this.closeModal();
        } else {
          this.toastService.displayToast(data['message'], null, 'danger');
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.toastService.displayToast(error['message'], null, 'danger');
      })
    )
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

}
