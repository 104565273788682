import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../_services/common.service';

@Component({
	selector: 'app-refund-details',
	templateUrl: './refund-details.component.html',
	styleUrls: ['./refund-details.component.scss'],
})
export class RefundDetailsComponent implements OnInit {
	
	constructor() { }

	ngOnInit() { }
  
	public orderDetailData = [
	  {
		supplier_name: "Supplier name #10212",
		supplier_info: [
		  {
			product_img : "assets/icon/product_image1.svg",
			product_name: "Metal Ceiling CT1782",
			is_sample: true,
			product_color: "Sed bibendum",
			product_format: "Planks",
			product_price: "1 x $40",
			order_status: "Confirmed",
			cancel_product: true,
			subtotal: "$40",
			product_unit: "/sqm"
		  },
		  {
			product_img : "assets/icon/slider-image1.svg",
			product_name: "Lorem ipsum dolor sit",
			is_sample: false,
			product_color: "Sed bibendum",
			product_format: "Planks",
			product_price: "50 x $40",
			order_status: "Confirmed",
			cancel_product: true,
			subtotal: "$2,000",
			product_unit: "/sqm"
		  }
		],
		track_shippment: false,
		items_subtotal: "$2,040",
		shipping: "$100",
		vat: "$200",
		total_supplier_name: "$2,340"
	  },
	  {
		supplier_name: "Supplier name #10205",
		supplier_info: [
		  {
			product_img : "assets/icon/slider-image1.svg",
			product_name : "Lorem ipsum dolor sit",
			is_sample: false,
			product_color: "Sed bibendum",
			product_format: "Planks",
			product_price: "6 x $40",
			order_status: "Shipped",
			cancel_product: false,
			subtotal: "$240",
			product_unit: "/sqm"
		  },
		  {
			product_img : "assets/icon/slider-image3.svg",
			product_name: "Lorem ipsum dolor sit",
			is_sample: false,
			product_color: "Sed bibendum",
			product_format: "Planks",
			product_price: "6 x $40",
			order_status: "Shipping",
			cancel_product: false,
			subtotal: "$60",
			product_unit: "/sqm"
		  }
		],
		track_shippment: true,
		items_subtotal: "$300",
		shipping: "$100",
		vat: "$50",
		total_supplier_name: "$510"
	  },
	]
  
	public commissionDetailData = [
	  {
		supplier_name: "Supplier name #10212",
		supplier_info: [
		  {
			product_img : "assets/icon/product_image1.svg",
			product_name: "Metal Ceiling CT1782",
			is_sample: true,
			product_price: "$40",
			product_commission: "$8",
			feature_commission: "$2",
			order_status: "Confirmed",
			cancel_product: true,
			subtotal: "$10",
		  },
		  {
			product_img : "assets/icon/slider-image1.svg",
			product_name: "Consectetur do elit",
			is_sample: false,
			product_price: "$40",
			product_commission: "$8",
			feature_commission: "-",
			order_status: "Confirmed",
			cancel_product: true,
			subtotal: "$8",
		  }
		],
		total_commission: "$18"
	  }
	]
  
}
