import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../_services/modal.service';
import { URLS } from '../../_config/api.config';
import { Subscription } from 'rxjs';
import { ApiService } from '../../_services/api-data.service';
import { CommonService } from '../../_services/common.service';
import { ToastService } from '../../_services/toast.service';

@Component({
  selector: 'app-two-fa-toggle',
  templateUrl: './two-fa-toggle.component.html',
  styleUrls: ['./two-fa-toggle.component.scss'],
})
export class TwoFAToggleComponent implements OnInit {
  isLoading = false;
  isTwoFaEnabled = false;
  isConfirmed: boolean = false;
  public subscription: Subscription = new Subscription();
  constructor(
    private modalService: ModalService,
    private dataService: ApiService,
    public commonService: CommonService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    let tfaEnabled = localStorage.getItem('is_2fa_enabled');
    if (tfaEnabled && tfaEnabled == 'true') {
      this.isTwoFaEnabled = true;

    } else {
      this.isTwoFaEnabled = false;

    }
  }

  closeModal() {
    this.modalService.dismissModal();
  }

  twoFaToggle(event) {
    const params = {
      "is_2fa_enabled": !event.target.checked
    }
    this.isLoading = true;
    this.subscription.add(
      this.dataService.put(URLS.update2fa, params).subscribe(data => {
        if (data['data'] && data['code'] == 200) {
          this.isTwoFaEnabled = event.target.checked;
          localStorage.setItem("is_2fa_enabled", this.isTwoFaEnabled ? 'true' : 'false');
          this.commonService.twoFaEnabled = this.isTwoFaEnabled;
          if (this.isTwoFaEnabled) {
            this.isConfirmed = true;
          }
          this.isLoading = false;
        } else {
          setTimeout(() => {
            this.isTwoFaEnabled = !this.isTwoFaEnabled;
          });
          this.isLoading = false;
          this.toastService.displayToast(data['message'], null, 'danger');
        }
      }, error => {
        setTimeout(() => {
          this.isTwoFaEnabled = !this.isTwoFaEnabled;
        });
        this.isLoading = false;
        this.toastService.displayToast(error['message'], null, 'danger');
      })
    )
  }
}
